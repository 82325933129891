<template>
	<v-card
		class="pa-0 device-realtime width-100 overflow-x-hidden"
		:style="fullscreenDialog && displayLocation == 'dialog' ? 'max-height: calc(100vh - 100px)' : chartStyle"
		v-if="pollutants"
	>
		<v-row no-gutters>
			<v-col
				v-for="pollutant in pollutants"
				:key="pollutant.acronym"
				:value="pollutant.acronym"
				:title="pollutant.name"
				class="pa-0"
				xl="3"
				:lg="displayLocation === 'card' ? 4 : fullscreenDialog ? 3 : 4"
				:md="displayLocation === 'card' ? 6 : 4"
				:sm="displayLocation === 'card' ? 12 : 6"
				cols="12"
			>
				<v-card elevation="2" outlined class="ma-2 d-flex flex-wrap flex-column align-center justify-center">
					<v-tooltip
						v-if="pollutant.name.length > 22 && getErrors(pollutant.elasticacronym).length == 0"
						top
						max-width="500"
						color="var(--gris-secondary)"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-card-title
								:color="primaryColor"
								dark
								v-bind="attrs"
								v-on="on"
								class="d-flex justify-center align-center pa-0 pt-2"
								style="cursor: help; flex: 1 1 100%"
							>
								<span class="fw-semi fs-md">{{ pollutant.acronym }}</span>
							</v-card-title>
						</template>
						<span class="fs-regular">{{ pollutant.name }}</span>
					</v-tooltip>
					<v-tooltip
						v-else-if="pollutant.name.length <= 22 && getErrors(pollutant.elasticacronym).length > 0"
						top
						max-width="360"
						color="var(--gris-secondary)"
					>
						<template v-slot:activator="{ on, attrs }">
							<v-card-title
								:color="primaryColor"
								dark
								v-bind="attrs"
								v-on="on"
								class="d-flex justify-center align-center pa-0 pt-2"
								style="cursor: help; flex: 1 1 100%"
							>
								<span class="fw-semi fs-md"
									>{{ pollutant.name }}
									<i class="fak fa-solid-alert ps-1 fs-sm fw-regular" style="color: var(--rojo-100) !important"></i>
								</span>
							</v-card-title>
						</template>
						<ul v-if="getErrors(pollutant.elasticacronym).length > 1">
							<li v-for="(error, i) in getErrors(pollutant.elasticacronym)" :key="i" class="fw-500 fs-regular text-center">
								{{ error.message }}
							</li>
						</ul>
						<span v-else class="fs-regular">{{ getErrors(pollutant.elasticacronym)[0].message }}</span>
					</v-tooltip>
					<v-card-title
						v-else-if="pollutant.name.length > 22 && getErrors(pollutant.elasticacronym).length > 0"
						:color="primaryColor"
						dark
						class="d-flex justify-center align-center pa-0 pt-2"
						style="cursor: help; flex: 1 1 100%"
					>
						<v-tooltip top max-width="350" color="var(--gris-secondary)">
							<template v-slot:activator="{ on, attrs }">
								<span class="fw-semi fs-md" v-bind="attrs" v-on="on">{{ pollutant.acronym }}</span>
							</template>
							<span class="fs-regular">{{ pollutant.name }}</span>
						</v-tooltip>

						<v-tooltip top max-width="360" color="var(--gris-secondary)">
							<template v-slot:activator="{ on, attrs }">
								<i
									class="fak fa-solid-alert ps-2 fs-sm fw-regular"
									style="color: var(--rojo-100) !important"
									v-bind="attrs"
									v-on="on"
								></i>
							</template>
							<ul v-if="getErrors(pollutant.elasticacronym).length > 1">
								<li v-for="(error, i) in getErrors(pollutant.elasticacronym)" :key="i" class="fw-500 fs-regular text-center">
									{{ error.message }}
								</li>
							</ul>
							<span v-else class="fs-regular">{{ getErrors(pollutant.elasticacronym)[0].message }}</span>
						</v-tooltip>
					</v-card-title>
					<v-card-title v-else :color="primaryColor" dark class="d-flex justify-center align-center pa-0 pt-2" style="flex: 1 1 100%">
						<span class="fw-semi fs-md">{{ pollutant.name }}</span>
					</v-card-title>
					<gauge-real-time
						v-if="hasRealTimeChartData(pollutant.elasticacronym)"
						:feature="deviceObject"
						:pollutant="pollutant"
						:displayLocation="displayLocation"
						:lineWidth="15"
					></gauge-real-time>
					<div v-else class="switchoff d-flex flex-column align-center justify-center" style="height: 230px; min-width: 200px">
						<v-icon size="1.5rem"> fal fa-inbox </v-icon>
						<div class="switchoff-text">{{ $t('common.noRealTimeData') }}</div>
					</div>
					<v-card-text
						v-if="hasRealTimeChartData(pollutant.elasticacronym)"
						class="d-flex justify-center flex-wrap px-2 pt-0 pb-2"
						style="flex: 1 1 100%"
					>
						<span> {{ $t('common.updatedRealTime') }} </span>
						<span class="fw-bold ml-1 text-no-wrap"> {{ dateObserved[pollutant.elasticacronym] }}</span>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</v-card>
	<v-card v-else class="switchoff d-flex flex-column align-center justify-center">
		<v-icon size="1.5rem"> fal fa-inbox </v-icon>
		<div class="switchoff-text">{{ $t('common.noData') }}</div>
	</v-card>
</template>

<script>
import GaugeRealTime from '@/components/echart/GaugeRealTime.vue';
import { formatTimestamp } from '@/api/common';

import constants from '@/utils/constants';
export default {
	components: {
		GaugeRealTime
	},
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			dateObserved: [],
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	computed: {
		pollutants() {
			return this.$store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid);
		},
		realTimeElasticData() {
			return this.$store.getters.getRealTimeElasticData;
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		fullscreenDialog() {
			return this.$store.getters.getFullscreenDialog;
		}
	},
	watch: {
		/* fullscreenDialog(val) {
			if (val && this.displayLocation == 'dialog') {
				this.chartStyle = { maxHeight: 'calc(100vh - 80px)', width: '100%' };
			} else {
				this.chartStyle =
					this.displayLocation == 'card' ? { maxHeight: '384px', width: '100%' } : { maxHeight: 'calc(70vh - 48px)', width: '100%' };
			}
		} */
	},
	created() {
		this.chartStyle = this.displayLocation == 'card' ? { maxHeight: '384px', width: '100%' } : { maxHeight: 'calc(70vh - 48px)', width: '100%' };
	},
	mounted() {
		console.warn(this.deviceObject);
	},
	updated() {
		console.warn('real time updated');
	},
	methods: {
		hasRealTimeChartData(elasticacronym) {
			if (typeof this.realTimeElasticData[elasticacronym] !== 'undefined') {
				if (Object.hasOwn(this.realTimeElasticData[elasticacronym], elasticacronym)) {
					const timestamp = this.realTimeElasticData[elasticacronym][constants.ELASTIC_DATE_OBSERVED_TIMESTAMP];
					const timezone = this.realTimeElasticData[elasticacronym]['timezone'];
					this.dateObserved[elasticacronym] = formatTimestamp(timestamp, this.userProperties, false, timezone);
					return true;
				}
				return false;
			}
			return false;
		},
		getErrors(elasticacronym) {
			let error = false;
			if (this.realTimeElasticData[elasticacronym] && Object.hasOwn(this.realTimeElasticData[elasticacronym], 'validations')) {
				let parameterError = this.realTimeElasticData[elasticacronym].validations.filter(
					(validation) => validation.parameter == elasticacronym
				);
				if (parameterError) error = parameterError;
			}
			return error;
		}
	}
};
</script>

<style lang="postcss">
.device-realtime {
	overflow: scroll;
}

.switchoff {
	color: var(--gris-300);
	& .switchoff_icon {
		font-size: 2rem;
	}
	& .switchoff-text {
		font-size: 1rem;
	}
}
</style>

<template>
	<v-row no-gutters class="StatusFormFilterInput d-flex align-center justify-center pa-1 py-6" style="background-color: var(--N-10)">
		<!-- Name -->
		<v-col cols="3" class="height-equalizer d-flex flex-column justify-center" style="padding-bottom: 21px">
			<pui-text-field
				:label="$t('pmstatus.name')"
				v-model="filterRuleModel.name"
				:disabled="disabled"
				toplabel
				required
				hideDetails
				maxlength="150"
			></pui-text-field>
			<!-- @keypress="checkKey" -->
		</v-col>
		<v-col cols="5" lg="6" class="height-equalizer">
			<v-row no-gutters class="row-height-fit pb-3">
				<v-col cols="8" md="7" lg="6">
					<v-select
						append-icon="fa fa-angle-down"
						solo
						flat
						:items="rowsProp[0].op"
						:placeholder="$t('form.common.chooseOperator')"
						v-model="op0"
						:disabled="disabled"
						:hide-details="true"
						clearable
						required
					></v-select>
					<!-- @input="clearValues('value0')" -->
				</v-col>
				<v-col cols="4" md="5" lg="6">
					<pui-number-field
						v-model="value0"
						toplabel
						:disabled="!op0 || disabled"
						:required="hasOperator0"
						:min="lowerLimit"
						:max="upperLimit"
					></pui-number-field>
				</v-col>
			</v-row>
			<v-row no-gutters class="row-height-fit pt-3">
				<v-col cols="8" md="7" lg="6">
					<v-select
						append-icon="fa fa-angle-down"
						solo
						flat
						:items="rowsProp[1].op"
						:placeholder="$t('form.common.chooseOperator')"
						v-model="op1"
						:hide-details="true"
						:disabled="disabled"
						clearable
						required
					></v-select>
					<!-- @input="clearValues('value1')" -->
				</v-col>
				<v-col cols="4" md="5" lg="6" class="height-equalizer">
					<pui-number-field
						v-model="value1"
						toplabel
						:disabled="!op1 || disabled"
						:required="hasOperator1"
						:min="value0 || lowerLimit"
						:max="upperLimit"
					></pui-number-field>
				</v-col>
			</v-row>
		</v-col>
		<!-- Color -->
		<v-col cols="4" lg="3">
			<v-color-picker
				class=""
				dot-size="12"
				v-model="filterRuleModel.color"
				hide-mode-switch
				canvas-height="48"
				elevation="0"
				mode="hexa"
				heig
				swatches-max-height="100"
				:disabled="disabled"
			></v-color-picker>
		</v-col>
	</v-row>
</template>

<script>
export default {
	name: 'StatusFormFilterInput',
	props: {
		groupName: {
			type: String
		},
		ruleIndexProp: {
			type: Number
		},
		filterRuleProp: {
			type: Object
		},
		rowsProp: {
			type: Array,
			required: true
		},
		disabled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			filterRuleModel: this.getDefaultFilterRuleModel(),
			column: {
				type: '',
				name: '',
				title: ''
			},
			value0: null,
			op0: null,
			value1: null,
			op1: null,
			hasOperator0: false,
			hasOperator1: false,
			lowerLimit: null,
			upperLimit: null,
			filterOperators: [
				{
					text: this.$t('lessThan'),
					value: 'lt'
				},
				{
					text: this.$t('greaterEqualThan'),
					value: 'gte'
				},
				{
					text: this.$t('lessEqualThan'),
					value: 'lte'
				},
				{
					text: this.$t('greaterThan'),
					value: 'gt'
				}
			],
			emptyDisabled: false
		};
	},
	computed: {
		/* width() {
			let width = '300';
			switch (this.$vuetify.breakpoint.name) {
				case 'xs':
					width = '200';
					break;
				case 'sm':
					width = '250';
					break;
				case 'md':
					width = '300';
					break;
				case 'lg':
					width = '350';
					break;
				case 'xl':
					width = '400';
					break;
			}
			return width;
		} */
		statusFilters() {
			return this.$store.getters.getStatusFilters;
		}
	},
	watch: {
		filterRuleProp() {
			this.filterRuleModel = this.filterRuleProp;
			this.setValues();
		},

		value0: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				if (this.filterRuleModel.status.min) this.filterRuleModel.status.min.data = newValue;
			},
			deep: true
		},
		value1: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				if (this.filterRuleModel.status.max) this.filterRuleModel.status.max.data = newValue;
			},
			deep: true
		},
		op0: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				console.log('op0 watcher', oldValue, newValue, this.filterRuleModel);
				if (!Object.hasOwn(this.filterRuleModel.status, 'min')) this.filterRuleModel.status.min = { data: null, op: null };
				this.filterRuleModel.status.min.op = typeof newValue == 'string' || !newValue ? newValue : newValue.value;
				if (!newValue) {
					this.value0 = null;
					this.lowerLimit = -1;
					this.hasOperator0 = false;
				} else {
					this.hasOperator0 = true;
					this.lowerLimit = 0;
				}
			},
			deep: true
		},
		op1: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				console.log('op1 watcher', oldValue, newValue, this.filterRuleModel);
				if (!Object.hasOwn(this.filterRuleModel.status, 'max')) this.filterRuleModel.status.max = { data: null, op: null };
				this.filterRuleModel.status.max.op = typeof newValue == 'string' || !newValue ? newValue : newValue.value;
				if (!newValue) {
					this.value1 = null;
					this.lowerLimit = -1;
					this.hasOperator1 = false;
				} else {
					this.hasOperator1 = true;
					this.lowerLimit = this.value0;
				}
			},
			deep: true
		},
		filterRuleModel: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				console.info('filterRuleModel from', oldValue, 'to ', newValue);
				this.$emit('change', this.filterRuleModel);
			},
			deep: true
		},
		statusFilters(val, prev) {
			console.info('status store', prev, val, this.ruleIndexProp, val.length);
			if (this.ruleIndexProp - 1 >= 0 && val[this.ruleIndexProp - 1]) {
				console.log(
					'status store before: ',
					this.ruleIndexProp,
					val[this.ruleIndexProp - 1],
					val[this.ruleIndexProp - 1].min,
					val[this.ruleIndexProp - 1].max
				);
				this.lowerLimit = val[this.ruleIndexProp - 1].max ? val[this.ruleIndexProp - 1].max : null;
			}

			if (this.ruleIndexProp + 1 < val.length && val[this.ruleIndexProp + 1]) {
				console.log(
					'status store after: ',
					this.ruleIndexProp,
					val[this.ruleIndexProp + 1],
					val[this.ruleIndexProp + 1].min,
					val[this.ruleIndexProp + 1].max
				);
				this.upperLimit = val[this.ruleIndexProp + 1].min ? val[this.ruleIndexProp + 1].min : null;
			}
		}
	},
	created() {
		this.filterRuleModel = this.filterRuleProp;
		this.setValues();
		this.filterOperators.sort((a, b) => (a.text > b.text ? 1 : b.text > a.text ? -1 : 0));
	},
	mounted() {
		const parentFilter = this.$parent.$parent.filterProp;
		console.log('rows prop debug', parentFilter, parentFilter[this.ruleIndexProp]);
		/* if (parentFilter.length > 1 && this.ruleIndexProp - 1 >= 0) {
			console.log('parent filter', parentFilter[this.ruleIndexProp - 1], parentFilter[this.ruleIndexProp - 1].status);
			if (Object.hasOwn(parentFilter[this.ruleIndexProp - 1].status, 'min')) {
				console.log('parent filter min', parentFilter[this.ruleIndexProp - 1].status.min.data);
			}
			if (Object.hasOwn(parentFilter[this.ruleIndexProp - 1].status, 'max')) {
				console.log('parent filter max', parentFilter[this.ruleIndexProp - 1].status.max.data);
				this.lowerLimit = parentFilter[this.ruleIndexProp - 1].status.max.data;
			}
		} else this.lowerLimit = this.upperLimit = null; */
	},
	methods: {
		setValues() {
			console.log('debug set values', this.ruleIndexProp, this.filterRuleModel, this.filterRuleModel.status.min);
			if (
				this.filterRuleModel.status.min &&
				this.filterRuleModel.status.min.op !== undefined &&
				this.filterRuleModel.status.min.data !== undefined
			) {
				this.value0 = this.filterRuleModel.status.min.data;
				this.op0 =
					typeof this.filterRuleModel.status.min.op == 'string'
						? this.filterOperators.find((operator) => operator.value == this.filterRuleModel.status.min.op)
						: this.filterRuleModel.status.min.op;
			}
			if (
				this.filterRuleModel.status.max &&
				this.filterRuleModel.status.max.op !== undefined &&
				this.filterRuleModel.status.max.data !== undefined
			) {
				this.value1 = this.filterRuleModel.status.max.data;
				this.op1 =
					typeof this.filterRuleModel.status.max.op == 'string'
						? this.filterOperators.find((operator) => operator.value == this.filterRuleModel.status.max.op)
						: this.filterRuleModel.status.max.op;
			}
		},
		clearValues(key = 'value0') {
			this[key] = null;
		},
		setDefaultOp() {
			//this.filterRuleModel.op = 'eq';
			this.filterRuleModel.status.forEach((stat, i) => {
				stat[i].op = this.rowsProp[i].op[0];
			});
		},
		getDefaultFilterRuleModel() {
			return {
				name: null,
				status: {
					min: {
						op: null /* rowsProp[0].op[0] */,
						data: null
					},
					max: {
						op: null /* rowsProp[1].op[0] */,
						data: null
					}
				},
				color: '#FFFFFF'
			};
		},
		checkKey(e) {
			console.log('check key', e.key, e.which, e);
			if (e.which >= 48 && e.which <= 57) e.preventDefault();
		}
	}
};
</script>

<style lang="postcss">
.StatusFormFilterInput {
	background-color: var(--N-0);
	border-color: var(--N-100);
	border-style: solid;
	border-radius: 5px;
	border-width: 1px;
	& .height-equalizer {
		height: 107px;
	}
	& .row-height-fit {
		height: 54px !important;
		& .v-label--required {
			display: none;
		}
	}

	& .v-color-picker__controls {
		padding: 0px;
		padding-top: 4px;
		& .v-color-picker__preview {
			padding-bottom: 4px;
		}
		& .v-color-picker__dot {
			width: 18px;
			height: 18px;
		}
	}
	& .v-color-picker__edit {
		margin: 0px;
		& .v-color-picker__input {
			& input {
				margin-bottom: 0px;
			}
			& span {
				display: none;
			}
		}
	}
}
</style>

<template>
	<div :id="id" class="ol-popup pa-0">
		<v-card class="pa-0" v-if="feature">
			<div class="card-title-info pa-1">
				<v-card-title v-if="feature.code" class="card-title-text fw-bold d-flex justify-center align-center ma-0 pa-0">
					{{ feature.code }}
				</v-card-title>
			</div>
		</v-card>
	</div>
</template>

<script>
import constants from '@/utils/constants';

export default {
	props: {
		feature: {
			type: Object,
			required: false
		},
		id: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			aqiColor: null,
			satelliteType: constants.DATASOURCETYPE_SATELLITE,
			satelliteModel: constants.SATELLITE_MODEL,
			primaryColor: this.$store.state.global.primaryColor,
			statusProperties: {},
			parameterValues: {}
		};
	},
	computed: {
		isParameters() {
			return Object.keys(this.parameters).length > 0;
		},
		parameters() {
			if (this.feature) {
				return this.$store.getters.getParameters.filter(
					(parameter) =>
						parameter.visible !== 0 &&
						parameter.pmorganizationid == this.feature.pmorganizationid &&
						parameter.pmdatasourcetypeid == this.feature.pmdatasourcetypeid
				);
			} else {
				return {};
			}
		},

		aqiStyle() {
			return {
				color: this.aqiColor
			};
		},
		featureNotifications() {
			return this.$store.getters.getNotifications && this.feature
				? this.$store.getters.getNotifications.filter(
						(notification) => notification.pmdatasourceinstanceid == this.feature.pmdatasourceinstanceid
					)
				: false;
		}
	},
	watch: {},
	mounted() {},
	updated() {},
	methods: {}
};
</script>
<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.ol-popup {
	width: fit-content;
	max-width: 250px; /* Establece un ancho máximo para limitar el crecimiento */
	min-width: 100px;
	background-color: var(--gris-secondary);
}

.ol-popup:after,
.ol-popup:before {
	top: 90%;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}
.ol-popup:after {
	border-top-color: var(--gris-secondary);
	//opacity: 90%;
	border-width: 10px;
	left: 48px;
	margin-left: -10px;
}
.ol-popup:before {
	border-top-color: var(--gris-secondary);
	//opacity: 90%;
	border-width: 11px;
	left: 48px;
	margin-left: -11px;
}
.card-title-info {
	background-color: var(--gris-secondary);
	.card-title-text {
		font-size: 16px !important;
		color: #fff;
	}
	.card-subtitle-text {
		font-size: 1rem !important;
	}
}
</style>

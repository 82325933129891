<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12>
					<pui-field-set :title="$t('pmsatellitetype.formtitle')">
						<v-layout wrap>
							<v-flex xs12 md7 lg8 xl9>
								<v-layout wrap>
									<v-flex xs12 lg6>
										<pui-text-field
											:label="$t('pmsatellitetype.name')"
											v-model="model.name"
											toplabel
											required
											maxlength="150"
										></pui-text-field>
									</v-flex>
									<v-flex xs12 lg6>
										<pui-text-field
											:label="$t('pmsatellitetype.acronym')"
											v-model="model.acronym"
											toplabel
											required
											maxlength="5"
										></pui-text-field>
									</v-flex>
								</v-layout>
								<v-layout wrap>
									<v-flex xs12>
										<pui-text-area
											v-model="model.description"
											:label="$t('pmsatellitetype.description')"
											maxlength="250"
											toplabel
										></pui-text-area>
									</v-flex>
								</v-layout>
							</v-flex>
							<v-flex xs12 md5 lg4 xl3>
								<pui-field-set :title="$t('pmsatellitetype.color')" class="d-flex justify-center align-center">
									<v-color-picker
										v-model="model.color"
										dot-size="15"
										hide-mode-switch
										mode="hexa"
										elevation="0"
										canvas-height="100"
										:label="$t('pmsatellitetype.color')"
										class="my-2"
										required
									></v-color-picker>
								</pui-field-set>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<v-flex xs12 md4>
								<pui-checkbox
									:label="$t('pmsatellitetype.disabled')"
									v-model="model.disabled"
									true-value="1"
									false-value="0"
								></pui-checkbox>
							</v-flex>
						</v-layout>
					</pui-field-set>
				</v-flex>
			</v-layout>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="checkColor"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
//
import { isSuperAdmin } from '../../api/common';
export default {
	name: 'pmsatellitetypeform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmsatellitetype',
			model: 'pmsatellitetype',
			isSuperAdmin: false,
			pmdatasourcetypeFixedFilter: {},
			pmparameterFixedFilter: {}
		};
	},
	computed: {},
	watch: {
		'model.color': {
			handler(newValue, oldValue) {
				console.log('debug color', oldValue, newValue, this.model.color.length, this.model.color.substring(0, 6));
				if (newValue && typeof newValue == 'object') {
					this.model.color = this.model.color.hex;
				}
				if (!oldValue && newValue.length == 6) {
					console.log('debug color 2', newValue, this.model.color);
					this.model.color = '#' + newValue;
				}
			}
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	updated() {
		if (this.model) {
			if (!this.modelLoaded || !this.model.pmorganizationid) {
				this.modelLoaded = true;
			}
		}
		//console.log('debug updated satellitetype form', this.model.pmdatasourcetypeid, this.pmparameterFixedFilter, this.model);
	},
	methods: {
		checkColor() {
			if (this.model.color.length == 9) {
				this.model.color = this.model.color.substring(0, 7);
			}
			this.save();
		}
	}
};
</script>

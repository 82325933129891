<template>
	<v-data-table
		v-if="actionLogs"
		:headers="getActionsLogHeaders()"
		:items="actionLogs"
		:single-expand="singleExpand"
		:expanded.sync="expanded"
		item-key="pmactionslogid"
		show-expand
		:show-select="false"
		:items-per-page="displayLocation == 'card' ? 5 : fullscreenDialog ? 20 : 10"
		class="elevation-1"
		style="overflow-y: scroll !important"
		:loading="loadingAnimation"
		loading-text="Loading..."
		:footer-props="{
			showFirstLastPage: true,
			showCurrentPage: true,
			firstIcon: 'fak fa-line-start',
			lastIcon: 'fak fa-line-final',
			prevIcon: 'fak fa-line-back',
			nextIcon: 'fak fa-line-next',
			itemsPerPageOptions: [5, 10, 20, 50, -1]
		}"
		@click:row="expandRow"
	>
		<template v-slot:item.actionname="{ item }">
			<v-tooltip v-if="item.description" top transition="none" color="var(--gris-secondary)">
				<template v-slot:activator="{ on }">
					<div class="fw-regular fs-regular pr-2 pointer" v-on="on">
						<span>{{ item.actionname }}</span>
						<!-- <v-icon size="16" class="fw-regular pl-1 info-tooltip pl-1 fas fa-info-circle info-tooltip"></v-icon> -->
						<v-icon color="secondary" class="info-tooltip pl-1" size="14">fas fa-info-circle info-tooltip</v-icon>
					</div>
				</template>
				<div class="d-flex flex-column align-center">
					<span class="fs-regular fw-regular">{{ item.description }}</span>
				</div>
			</v-tooltip>
			<span v-else class="fw-regular fs-regular pr-2">
				{{ item.actionname }}
			</span>
		</template>
		<template v-slot:item.executionutc="{ item }">
			<span v-if="item['executionutc']" class="fw-regular fs-sm">{{ getDateFormat(item['executionutc']) }}</span>
		</template>
		<template v-slot:item.executionresult="{ item }">
			<div>
				<span class="fw-bold fs-sm pr-2" :style="'color:' + getStatusColor(item['executionresult'])" dark>
					{{ $t('grid.pmactionslog.' + item['executionresult']) }}
				</span>
			</div>
		</template>
		<template v-slot:item.executionresult="{ item }">
			<div>
				<span class="fw-bold fs-sm pr-2" :style="'color:' + getStatusColor(item['executionresult'])" dark>
					{{ $t('grid.pmactionslog.' + item['executionresult']) }}
				</span>
			</div>
		</template>
		<template v-slot:item.data-table-expand="{ item, isExpanded }">
			<v-icon v-if="item['parameterused']" size="16" class="fw-900 text-center width-100" :class="isExpanded ? 'rotated' : ''"
				>fas fa-chevron-down</v-icon
			>
		</template>
		<template v-slot:expanded-item="{ headers, item }">
			<td v-if="item['parameterused']" :colspan="headers.length" class="px-1">
				<v-data-table
					:headers="getParameterHeaders(item['parameterused'])"
					:items="[JSON.parse(item['parameterused'])]"
					hide-default-footer
					class="elevation-1 my-1"
				></v-data-table>
			</td>
			<!-- <td v-else :colspan="headers.length" class="px-1 d-flex justify-center">
				<span class="text-center">No data available</span>
			</td> -->
		</template>
	</v-data-table>
	<v-data-table v-else :items="[]" :loading="loadingAnimation" loading-text="Loading..." class="elevation-1"></v-data-table>
</template>

<script>
import constants from '@/utils/constants';
import { formatTimestamp } from '@/api/common';
export default {
	name: 'DeviceActionsLog',
	components: {},
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			dialogModel: false,
			singleExpand: true,
			loadingAnimation: false,
			elasticDetail: {},
			elasticDetailRounded: {},
			expanded: [],
			primaryColor: this.$store.state.global.primaryColor
		};
	},
	computed: {
		deviceParameters() {
			return this.$store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid);
		},
		actionLogs() {
			if (this.$store.getters.getActionsLogs && this.$store.getters.getActionsLogs.length > 0) {
				return this.$store.getters.getActionsLogs.filter((log) => log.pmdatasourceinstanceid == this.deviceObject.pmdatasourceinstanceid);
			}
			return null;
		},
		fullscreenDialog() {
			return this.$store.getters.getFullscreenDialog;
		},
		displayMode: {
			get() {
				return this.$store.getters.getDisplayMode;
			},
			set(value) {
				this.$store.commit('setDisplayMode', value);
			}
		}
	},
	watch: {
		dialog() {
			this.dialogModel = this.dialog;
		}
	},
	created() {},
	mounted() {
		console.log('debug getActionsLogs', this.deviceObject, this.actionLogs, this.$store.getters.getActionsLogs);
	},
	methods: {
		getActionsLogHeaders() {
			console.log('debug: getActionsLogHeaders', this.actionLogs);

			//let notificationKeys = Object.keys(this.actionLogs[0]);
			let notificationKeys = ['actionname', 'actioncode', 'actiontype', 'executionutc', 'executionresult', 'errormessage'];

			const mappedArray = notificationKeys.map((key) => {
				return { text: this.$t('panels.actionLogs.' + key), value: key };
			});
			console.log('debug: mapped array', notificationKeys, mappedArray);
			mappedArray.push({ text: this.$t('panels.notifications.source'), value: 'data-table-expand' });

			return mappedArray;
		},
		getParameterHeaders(parameterUsed) {
			console.log('debug: getParameterHeaders', parameterUsed);
			let parameterObj = JSON.parse(parameterUsed);
			let notificationKeys = [];
			if (parameterObj && typeof parameterObj === 'object') {
				notificationKeys = Object.keys(parameterObj);
			}

			const mappedArray = notificationKeys.map((key) => {
				return { text: key, value: key };
			});
			console.log('debug: mapped array', notificationKeys, mappedArray);

			return mappedArray;
		},
		getStatusColor(status) {
			switch (status) {
				case constants.ACTION_RESULT_SUCCESS:
					return '#43a047';
				case constants.ACTION_RESULT_FAILURE:
					return '#d32f2f';
				case constants.ACTION_RESULT_COMPLETED:
					return '#f9a825';
				default:
					return 'black';
			}
		},
		expandRow(rowData) {
			console.log('expandRow', rowData);
			if (rowData['parameterused']) {
				if (this.expanded[0] == rowData) this.expanded = [];
				else this.expanded = [rowData];
			}
		},
		getDateFormat(date) {
			//console.log('getDateFormat', date, new Date(date), new Date(date).valueOf());
			return formatTimestamp(new Date(date).valueOf(), this.userProperties);
		}
	}
};
</script>

<style lang="postcss">
.v-data-table {
	& span {
		font-size: 1rem;
	}

	& td {
		font-size: 1rem;
	}
	& .rotated {
		transform: rotate(-180deg);
	}
}
</style>

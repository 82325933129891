<template>
	<div>
		<v-row class="px-3 mt-12">
			<v-col xs12 :md6="!showAdvancedDate">
				<pui-select
					:label="$t('aqi.dateInterval')"
					toplabel
					:disabled="form.formDisabled"
					v-model="form.dateInterval"
					:items="form.intervals"
					itemText="text"
					itemValue="value"
					@input="filterSearch"
				></pui-select>
			</v-col>
			<template v-if="showAdvancedDate">
				<v-col xs12 :md4="!form.showChartOptions" :md3="form.showChartOptions">
					<pui-date-field
						:label="$t('aqi.startDate')"
						toplabel
						:required="!form.startDateDisabled"
						v-model="form.startDate"
						:disabled="form.startDateDisabled"
						:max="form.endDate ?? Date.now()"
						:tooltipDescription="form.infoStartDate"
						@input="inputStartDate"
					></pui-date-field>
				</v-col>
				<v-col xs12 :md4="!form.showChartOptions" :md3="form.showChartOptions">
					<pui-date-field
						:label="$t('aqi.endDate')"
						toplabel
						:required="!form.endDateDisabled"
						v-model="form.endDate"
						:disabled="form.endDateDisabled"
						:min="form.startDate"
						:max="Date.now()"
						:tooltipDescription="form.infoEndDate"
						@input="submitEndDate"
					></pui-date-field>
				</v-col>
			</template>
			<template v-else>
				<v-col xs12 md4>
					<pui-text-field
						:label="$t('aqi.nameFilter')"
						toplabel
						v-model="form.nameFilter"
						:disabled="form.formDisabled"
						@input="filterSearch"
					></pui-text-field>
				</v-col>
				<v-col xs12 md4>
					<pui-select
						:label="$t('aqi.statusFilter')"
						toplabel
						variant="outlined"
						v-model="form.statusFilter"
						:disabled="form.formDisabled"
						@input="filterSearch"
						:items="form.statuses"
						itemText="text"
						itemValue="value"
					></pui-select>
				</v-col>
			</template>
		</v-row>
		<v-row class="px-3">
			<template v-if="showAdvancedDate">
				<v-col xs12 md4>
					<pui-text-field
						:label="$t('aqi.nameFilter')"
						toplabel
						v-model="form.nameFilter"
						:disabled="form.formDisabled"
						@input="filterSearch"
					></pui-text-field>
				</v-col>
				<v-col xs12 md4>
					<pui-text-field
						:label="$t('aqi.statusFilter')"
						toplabel
						variant="outlined"
						v-model="form.statusFilter"
						:disabled="form.formDisabled"
						@input="filterSearch"
					></pui-text-field>
				</v-col>
			</template>
		</v-row>

		<v-data-table
			:headers="getHeaders()"
			:items="filtered_items"
			:single-expand="singleExpand"
			:expanded.sync="expanded"
			item-key="aqvesselcallsid"
			class="vessel_calls border mx-5"
			show-expand
			id="vessel_calls_datatable"
			hide-default-footer
			:loading="loadingAnimation"
			loading-text="Loading..."
			:footer-props="{
				showFirstLastPage: true,
				showCurrentPage: true,
				firstIcon: 'fal fa-arrow-to-left',
				lastIcon: 'fal fa-arrow-to-right',
				prevIcon: 'fal fa-arrow-left',
				nextIcon: 'fal fa-arrow-right',
				itemsPerPageOptions: [10, 20, 30, 50, 100]
			}"
		>
			<template v-slot:item="{ item, index }">
				<tr :class="['main-row', getRowClass(index)]">
					<td v-for="header in getHeaders()" :key="header.value">
						<template v-if="header.value === 'data-table-expand'">
							<i class="fa-kit fa-solid-down more_details" v-if="!item.expanded_row" @click="expandRow(item)"></i>
							<i class="fa-kit fa-solid-up more_details" v-else @click="expandRow(item)"></i>
						</template>
						<template v-else-if="header.value === 'consumption'">
							<i class="fak fa-line-information-consultation pr-2 more_details" @click="callDetailsConsumption(item)">
								<span class="fw-500 font_inherit">Info</span>
							</i>
						</template>
						<template v-else-if="header.value === 'pollutants'">
							<i class="fak fa-line-information-consultation pr-2 more_details" @click="callDetailsPollutant(item)">
								<span class="fw-500 font_inherit">Info</span>
							</i>
						</template>
						<template v-else>
							{{ item[header.value] }}
						</template>
					</td>
				</tr>
			</template>

			<template v-slot:expanded-item="{ headers, item }">
				<td :colspan="headers.length" v-if="item['aqvesselcallsid']">
					<v-list-item>
						<v-list-item-content class="expanded_form_header">
							<v-data-table
								:headers="getHeaders2()"
								:items="[expanded_items[item['aqvesselcallsid']]]"
								item-key="aqvesselcallsid"
								hide-default-footer
								class="elevation-1 my-1"
								style="width: 100%"
								id="expanded_row_header_form"
							>
								<template v-slot:item.toportcode="{ item }">
									<span class="fw-500 expanded_cell_data">{{ item.toportcode }}</span>
								</template>
								<template v-slot:item.ata="{ item }">
									<span class="fw-500 expanded_cell_data">{{ item.ata }}</span>
								</template>
								<template v-slot:item.atd="{ item }">
									<span class="fw-500 expanded_cell_data">{{ item.atd }}</span>
								</template>
							</v-data-table>
						</v-list-item-content>
					</v-list-item>
					<v-list-item>
						<v-list-item-content>
							<v-list-item-title class="fw-bold expanded_title_style">{{ $t('grid.aqvesselcalls.duration') }}</v-list-item-title>
							<v-data-table
								:headers="getHeaders3()"
								:items="[expanded_items[item['aqvesselcallsid']]]"
								hide-default-footer
								class="elevation-1 my-1"
								style="width: 100%"
								id="expanded_row_datatable"
							>
								<template v-slot:item.man_time="{ item }">
									<span class="fw-500 expanded_cell_data">{{ item.man_time }}H</span>
								</template>
								<template v-slot:item.hot_time="{ item }">
									<span class="fw-500 expanded_cell_data">{{ item.hot_time }}H</span>
								</template>
								<template v-slot:item.anch_time="{ item }">
									<span class="fw-500 expanded_cell_data">{{ item.anch_time }}H</span>
								</template>
								<template v-slot:item.total_time="{ item }">
									<span class="fw-500 expanded_cell_data">{{ item.total_time }}H</span>
								</template>
							</v-data-table>
						</v-list-item-content>
					</v-list-item>
				</td>
				<td :colspan="headers.length" class="px-1" v-else>
					<div class="d-flex flex-column align-center">
						<span>{{ $t('common.noData') }}</span>
					</div>
				</td>
			</template>

			<template v-slot:footer="{ props }">
				<tr>
					<td style="width: 1%">
						<div class="d-flex justify-space-between">
							<div class="d-flex align-center ms-4">
								<span class="mr-2">{{ $t('aqi.itemsperpage') }}</span>
								<v-select
									:items="props.itemsPerPageOptions"
									v-model="props.options.itemsPerPage"
									hide-details
									dense
									id="vesselcallsfooter"
									class="me-2 p-0 vessel_calls_footer"
								>
									<template v-slot:append> </template>
								</v-select>
								<span class="mr-2">{{ $t('aqi.entries') }}</span>
							</div>
							<div class="d-flex align-center">
								<span class="mr-2"
									>{{ props.pagination.pageStart + 1 }}-{{ props.pagination.pageStop }} {{ $t('aqi.of') }}
									{{ props.pagination.itemsLength }} {{ $t('aqi.entries') }}</span
								>
								<v-btn
									icon
									class="pagination-btn ma-1"
									@click="() => (props.options.page = 1)"
									:disabled="props.pagination.page === 1"
								>
									<v-icon>fal fa-arrow-to-left</v-icon>
								</v-btn>
								<v-btn
									icon
									class="pagination-btn ma-1"
									@click="() => (props.options.page = Math.max(props.pagination.page - 1, 1))"
									:disabled="props.pagination.page === 1"
								>
									<v-icon>fal fa-arrow-left</v-icon>
								</v-btn>
								<v-text-field
									v-model="props.options.page"
									type="number"
									min="1"
									:max="props.pagination.pageCount"
									dense
									outlined
									hide-details
									class="mx-2 pagination-input"
									style="width: 50px"
									@input="onPageInput(props)"
								></v-text-field>
								<v-btn
									icon
									class="pagination-btn ma-1"
									@click="() => (props.options.page = Math.min(props.pagination.page + 1, props.pagination.pageCount))"
									:disabled="props.pagination.page === props.pagination.pageCount"
								>
									<v-icon>fal fa-arrow-right</v-icon>
								</v-btn>
								<v-btn
									icon
									class="pagination-btn ma-1"
									@click="() => (props.options.page = props.pagination.pageCount)"
									:disabled="props.pagination.page === props.pagination.pageCount"
								>
									<v-icon>fal fa-arrow-to-right</v-icon>
								</v-btn>
							</div>
						</div>
					</td>
				</tr>
			</template>
		</v-data-table>
		<v-dialog v-model="dialog" max-width="90%">
			<v-card>
				<v-card-title>Detail View</v-card-title>
				<v-card-text>
					<component :is="currentComponent" :id="selectedId" v-if="selectedId" @close="closeDetail" />
				</v-card-text>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="blue darken-1" text @click="closeDetail">Close</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<!-- MODAL DIALOG TO SHOW WHILE AN ACTION IS IN PROGRESS -->
		<!-- <v-dialog v-model="loadingDialog" persistent max-width="35%">
			<v-card>
				<v-card-title class="fw-bold fs-xl justify-center">
					{{ $t('common.loading') }}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-container fluid fill-height>
						<v-layout justify-center align-center class="pb-2">
							<v-progress-circular indeterminate :color="primaryColor"> </v-progress-circular>
						</v-layout>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog> -->
	</div>
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';

import { isSuperAdmin, search } from '../../api/common';
import Pollutant from './AqVesselCallsPollutant.vue';
import Consumption from './AqVesselCallsConsumption.vue';

export default {
	mixins: [PuiGridDetailMixin],
	name: 'aqvesselcallsgrid',
	components: {
		Pollutant,
		Consumption
	},
	data() {
		return {
			model: 'aqvesselcalls',
			dialog: false,
			currentComponent: null,
			expanded: [],
			items: [],
			expanded_items: [],
			filtered_items: [],
			singleExpand: false,
			elasticItems: null,
			loadingAnimation: true,
			elasticDetail: {},
			dialogPollutant: false,
			dialogConsumption: false,
			elasticDetailRounded: {},
			showAdvancedDate: false,
			selectedId: null,
			isSuperAdmin: false,
			form: {
				formDisabled: false,
				dateInterval: null,
				intervals: [
					{ text: this.$t('aqi.nofilter'), value: 0 },
					{ text: this.$t('aqi.lastWeek'), value: 7 },
					{ text: this.$t('aqi.last15D'), value: 15 },
					{ text: this.$t('aqi.lastMonth'), value: 30 },
					{ text: this.$t('aqi.customInterval'), value: -1 }
				],
				statuses: [
					{ text: this.$t('aqi.initiated'), value: this.$t('aqi.initiated') },
					{ text: this.$t('aqi.ended'), value: 'Finalitzada' }
				],
				startDate: null,
				endDate: null,
				startDateDisabled: true,
				endDateDisabled: true,
				infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
				infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
				nameFilter: null,
				statusFilter: null
			},
			primaryColor: this.$store.state.global.primaryColor + 'D9'
		};
	},
	computed: {
		loadingDialog: {
			get() {
				return this.$store.state.misc.loadingDialog;
			},
			set(value) {
				this.$store.state.misc.loadingDialog = value;
			}
		}
	},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		this.fetchData();
	},
	mounted() {},
	methods: {
		getHeaders() {
			let headerKeys = ['data-table-expand', 'consumption', 'pollutants', 'imo', 'name', 'numescala', 'estadoescala'];

			const mappedArray = headerKeys.map((key) => {
				const header = {
					text: this.$t('grid.aqvesselcalls.' + key),
					value: key,
					class: 'headers_style'
				};
				if (key === 'consumption' || key === 'pollutants') {
					header.width = '11%';
				}

				return header;
			});
			return mappedArray;
		},
		getHeaders2() {
			let headerKeys = ['toportcode', 'ata', 'atd'];

			const mappedArray = headerKeys.map((key) => {
				return { text: this.$t('grid.aqvesselcalls.' + key), value: key, class: 'expanded_headers_style', sortable: false };
			});
			return mappedArray;
		},
		getHeaders3() {
			let headerKeys = ['man_time', 'hot_time', 'anch_time', 'total_time'];

			const mappedArray = headerKeys.map((key) => {
				return { text: this.$t('grid.aqvesselcalls.' + key), value: key, class: 'expanded_headers_style', sortable: false };
			});
			return mappedArray;
		},
		async fetchData() {
			let rulesDtsType = [];
			if (!this.isSuperAdmin) {
				rulesDtsType.push({ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid });
				rulesDtsType.push({ field: 'anch_time', op: 'gt', data: '0' });
			} else {
				rulesDtsType.push({ field: 'anch_time', op: 'gt', data: '0' });
				rulesDtsType.push({ field: 'man_time', op: 'gt', data: '0' });
				rulesDtsType.push({ field: 'hot_time', op: 'gt', data: '0' });
			}
			//console.log("Las reglas son:",rulesDtsType);
			const filterDatasourcetype = {
				groups: [],
				groupOp: 'and',
				rules: rulesDtsType
			};

			const body = {
				model: 'aqvesselcalls',
				filter: filterDatasourcetype,
				rows: 100
			};
			const { data } = await search(body);
			this.loadingAnimation = false;
			this.items = this.formatItems(data.data);
			this.filtered_items = this.items;
			this.formatExpandedItems();
			this.filterSearch();
		},
		formatItems(items) {
			return items.map((item) => ({
				aqvesselcallsid: item.aqvesselcallsid,
				imo: item.aqvesselsid,
				numescala: item.callnum,
				name: item.name,
				estadoescala: item.callstatus,
				toportcode: item.toportcode,
				ata: item.ata,
				atd: item.atd,
				man_time: this.roundDown(item.mantime, 2),
				hot_time: this.roundDown(item.hottime, 2),
				anch_time: this.roundDown(item.anchtime, 2),
				expanded_row: false
			}));
		},
		formatExpandedItems() {
			//console.log(this.filtered_items);
			this.expanded_items = this.filtered_items.reduce((acc, item) => {
				acc[item.aqvesselcallsid] = {
					aqvesselcallsid: item.aqvesselcallsid,
					toportcode: item.toportcode,
					ata: item.ata == null ? '' : this.formatDate(new Date(item.ata)),
					atd: item.atd == null ? '' : this.formatDate(new Date(item.atd)),
					man_time: item.man_time,
					hot_time: item.hot_time,
					anch_time: item.anch_time,
					total_time: item.man_time + item.hot_time
				};
				return acc;
			}, {});
		},

		filterName() {
			const name = this.form.nameFilter.toLowerCase();
			this.filtered_items = this.filtered_items.filter((item) => item.name.toLowerCase().includes(name));
		},
		filterStatus() {
			const status = this.form.statusFilter.value.toLowerCase();
			this.filtered_items = this.filtered_items.filter((item) => item.estadoescala.toLowerCase().includes(status));
		},
		filterDate() {
			var d = new Date();
			this.showAdvancedDate = false;
			if (this.form.dateInterval.value == null || this.form.dateInterval.value == 0) {
				this.filtered_items = this.items;
			} else if (this.form.dateInterval.value == -1) {
				this.showAdvancedDate = true;
				this.form.startDateDisabled = false;
				this.form.infoStartDate = this.$t('aqi.infoStartDate');
				this.form.infoEndDate = this.$t('aqi.infoEndDate');
			} else {
				d.setDate(d.getDate() - this.form.dateInterval.value);
				this.filtered_items = this.items
					.filter((item) => {
						return new Date(item.ata) >= d;
					})
					.map((item) => ({
						...item,
						ata: this.formatDate(new Date(item.ata)),
						atd: this.formatDate(new Date(item.atd))
					}));
			}
			this.formatExpandedItems();
		},

		inputStartDate() {
			if (this.form.startDate) {
				this.form.endDateDisabled = false;
				if (this.form.endDate) this.submitEndDate();
			} else {
				this.form.endDateDisabled = true;
			}
		},

		submitEndDate() {
			console.log('info: submit end date ', this.form.endDate);
			if (this.form.startDate && this.form.endDate) {
				var startDate = new Date(this.form.startDate);
				var endDate = new Date(this.form.endDate);
				this.formatDate(startDate);
				this.formatDate(endDate);
				console.log("I'm going to fetch: ", 'start date: ', startDate, 'end date: ', endDate);
				this.fetchData(startDate, endDate);
			}
		},
		expandRow(rowData) {
			if (this.expanded[0] == rowData) this.expanded = [];
			else this.expanded = [rowData];
			rowData.expanded_row = !rowData.expanded_row;
		},
		formatDate(date) {
			const day = String(date.getDate()).padStart(2, '0');
			const month = String(date.getMonth() + 1).padStart(2, '0');
			const year = date.getFullYear();
			const hours = String(date.getHours()).padStart(2, '0');
			const minutes = String(date.getMinutes()).padStart(2, '0');
			return `${day}-${month}-${year} ${hours}:${minutes}`;
		},
		callDetailsConsumption(item) {
			this.selectedId = item.aqvesselcallsid;
			this.currentComponent = 'Consumption';
			this.dialog = true;
		},
		callDetailsPollutant(item) {
			this.selectedId = item.aqvesselcallsid;
			this.currentComponent = 'Pollutant';
			this.dialog = true;
			this.loadingDialog = true;
		},
		closeDetail() {
			this.selectedId = null;
			this.dialog = false;
			this.currentComponent = null;
		},
		filterSearch() {
			this.filtered_items = this.items;

			if (this.form.dateInterval != null) {
				this.filterDate();
			}

			if (this.form.nameFilter != null) {
				this.filterName();
			}

			if (this.form.statusFilter != null) {
				this.filterStatus();
			}
		},
		getRowClass(index) {
			return {
				vessel_calls_even_row: index % 2 === 0,
				vessel_calls_odd_row: index % 2 !== 0
			};
		},
		roundDown(number, decimals) {
			const factor = Math.pow(10, decimals);
			return Math.floor(number * factor) / factor;
		}
	},
	watch: {
		'form.dateInterval': {
			handler(newValue, oldValue) {
				console.debug('date interval watcher', oldValue, newValue);
				if (!newValue) {
					this.loadingAnimation = false;
					this.elasticItems = null;
					this.showAdvancedDate = false;
				}
			}
		},
		'form.startDate': {
			handler(newValue, oldValue) {
				console.info('startDate watcher', oldValue, newValue);
			}
		}
	}
};
</script>

<style lang="postcss" scoped>
.more_details {
	cursor: pointer;
}

.font_inherit {
	font-family: var(--font-family-primary) !important;
	color: rgba(0, 0, 0, 0.87);
	font-size: 14px;
}

.expanded_title_style {
	color: var(--azul-primary-100) !important;
	font-weight: 700 !important;
	font-size: 14px !important;
}

.expanded_cell_data {
	font-size: 12px !important;
}

.vessel_calls span {
	font-size: 14px;
}

#vessel_calls_datatable >>> td {
	border: 0.1px solid var(--gris-50);
	border-collapse: collapse;
	box-shadow: none !important;
}

#expanded_row_header_form >>> tr:hover,
#expanded_row_header_form >>> th:hover,
#expanded_row_datatable >>> tr:hover,
#expanded_row_datatable >>> th:hover {
	background: transparent !important;
	cursor: default !important;
}

#expanded_row_header_form,
#expanded_row_header_form >>> td,
#expanded_row_header_form >>> th,
#expanded_row_datatable,
#expanded_row_datatable >>> td,
#expanded_row_datatable >>> th {
	border: none !important;
	box-shadow: none !important;
	font-weight: 700;
	color: #101012;
}

#expanded_row_header_form {
	background-color: #d7ebff !important;
}

#expanded_row_header_form >>> span,
#expanded_row_datatable >>> span {
	font-size: 12px;
}

.vessel_calls_footer {
	width: 75px;
}

.vessel_calls_footer >>> i {
	display: none;
	width: 0px !important;
	height: 0px !important;
}

.pagination-btn {
	background-color: var(--N-10);
	color: black;
	transition: none;
}

.pagination-btn:hover {
	background-color: var(--N-50);
	color: white;
}

.pagination-btn .v-icon {
	font-size: 1rem;
}

.pagination-input {
	border: 1px solid var(--N-50);
	padding: 0px !important;
}

.pagination-input >>> input {
	margin: 0px !important;
	padding: 0px;
}
</style>

<template>
	<v-tooltip top nudge-right="125" max-width="310" color="var(--gris-secondary)">
		<template v-slot:activator="{ on, attrs }">
			<v-btn icon @click="toggleTool" ref="infoRasterBtn" id="infoRasterBtn" class="raster-tools-btn pa-1 ma-1" v-bind="attrs" v-on="on">
				<v-icon
					class="fw-bold fak fa-line-information-consultation"
					:color="isToolActivate ? '#fff' : 'var(--gris-secondary)'"
					size="16"
				></v-icon>
			</v-btn>

			<div ref="popup" id="popup-element" class="ol-popup pa-0" hidden v-if="popup" style="z-index: 0">
				<v-card elevation="3" class="pa-0 card-popup" @mouseover="stopTimeout" @mouseleave="startTimeout">
					<div class="card-title px-2 py-2">
						<v-card-title class="d-flex justify-space-between align-center ma-0 pa-0">
							<span class="fw-bold card-title-text">{{ popup.titleName }}</span>
							<v-btn icon ref="popupcloser" id="popupcloser" @click="closePopup" class="ol-popup-closer color-white"
								><v-icon size="12" class="pa-0"> fak fa-solid-close </v-icon></v-btn
							>
						</v-card-title>
						<v-card-subtitle class="pa-0 ma-0">
							<p class="card-subtitle-text fw-regular d-flex justify-start ma-0 pa-0">
								{{ popup.titleInfo }}
							</p>
							<p class="card-subtitle-text fw-regular d-flex justify-start ma-0 pa-0">
								{{ popup.hdms }}
							</p>
						</v-card-subtitle>
					</div>
					<v-progress-linear v-model="progress" color="white" :background-color="primaryColor" reverse></v-progress-linear>
					<v-container class="bg-surface-variant px-2 py-3" v-if="popup.infoValue">
						<v-row no-gutters class="pa-1">
							<v-tooltip
								v-if="popup.parameterName && popup.parameterName.length >= 16"
								max-width="500"
								left
								color="var(--gris-secondary)"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-col
										cols="6"
										class="d-flex flex-nowrap justify-start align-center pa-0"
										style="cursor: pointer"
										v-bind="attrs"
										v-on="on"
									>
										<span class="fw-regular">{{ popup.pollutant }}<i class="fak fa-line-info-2 fs-sm fw-regular ps-1"></i></span>
									</v-col>
								</template>
								<span class="fs-regular">{{
									popup.description ? popup.parameterName + ' - ' + popup.description : popup.parameterName
								}}</span>
							</v-tooltip>
							<v-tooltip v-else-if="popup.parameterName && popup.description" left color="var(--gris-secondary)" max-width="500">
								<template v-slot:activator="{ on, attrs }">
									<v-col
										cols="6"
										class="d-flex flex-nowrap justify-start align-center pa-0"
										style="cursor: pointer"
										v-bind="attrs"
										v-on="on"
									>
										<span class="fw-regular"
											>{{ popup.parameterName }}<i class="fak fa-line-info-2 fs-sm fw-regular ps-1"></i
										></span>
									</v-col>
								</template>
								<span class="fs-regular">{{ popup.description }}</span>
							</v-tooltip>
							<v-col v-else-if="popup.parameterName" cols="6" class="d-flex flex-nowrap justify-start align-center pa-0">
								<span class="fw-regular fs-regular">{{ popup.parameterName }}</span>
							</v-col>
							<v-col v-else cols="6" class="d-flex flex-nowrap justify-start align-center pa-0">
								<span class="fw-regular fs-regular">{{ popup.pollutant }}</span>
							</v-col>
							<v-col cols="6" class="d-flex flex-nowrap justify-center align-center pollutant-value pa-0">
								<span v-if="popup.infoValue != -9999" class="fw-semi fs-regular">{{ popup.infoValue }} {{ popup.unit }}</span>
								<span v-else class="fw-semi fs-regular">{{ $t('map.infoNoData') }}</span>
							</v-col>
						</v-row>
					</v-container>
					<v-progress-linear :indeterminate="true" :color="primaryColor" v-else></v-progress-linear>
				</v-card>
			</div>

			<div ref="popupCompareMap" id="popupCompareMap-element" class="ol-popup pa-0" hidden v-if="popupCompareMap" style="z-index: 0">
				<v-card elevation="3" class="pa-0 card-popup" @mouseover="stopTimeout" @mouseleave="startTimeout">
					<div class="card-title px-2 py-2">
						<v-card-title class="d-flex justify-space-between align-center ma-0 pa-0">
							<span class="fw-bold card-title-text">{{ popupCompareMap.titleName }}</span>
							<v-btn
								icon
								ref="popupCompareMapcloser"
								id="popupCompareMapcloser"
								@click="closePopupCompareMap"
								class="ol-popup-closer color-white"
								><v-icon size="12" class="pa-0"> fak fa-solid-close </v-icon></v-btn
							>
						</v-card-title>
						<v-card-subtitle class="pa-0 ma-0">
							<p class="card-subtitle-text fw-regular d-flex justify-start ma-0 pa-0">
								{{ popupCompareMap.titleInfo }}
							</p>
							<p class="card-subtitle-text fw-regular d-flex justify-start ma-0 pa-0">
								{{ popupCompareMap.hdms }}
							</p>
						</v-card-subtitle>
					</div>
					<v-progress-linear v-model="progress" color="white" :background-color="primaryColor" reverse></v-progress-linear>
					<v-container class="bg-surface-variant px-2 py-3" v-if="popupCompareMap.infoValue">
						<v-row no-gutters class="pa-1">
							<v-tooltip
								v-if="popupCompareMap.parameterName && popupCompareMap.parameterName.length >= 16"
								left
								color="var(--gris-secondary)"
								max-width="500"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-col
										cols="6"
										class="d-flex flex-nowrap justify-start align-center pa-0"
										style="cursor: pointer"
										v-bind="attrs"
										v-on="on"
									>
										<span class="fw-regular"
											>{{ popupCompareMap.pollutant }}<i class="fak fa-line-info-2 fs-sm fw-regular ps-1"></i
										></span>
									</v-col>
								</template>
								<span class="fs-regular">{{
									popupCompareMap.description
										? popupCompareMap.parameterName + ' - ' + popupCompareMap.description
										: popupCompareMap.parameterName
								}}</span>
							</v-tooltip>
							<v-tooltip
								v-else-if="popupCompareMap.parameterName && popupCompareMap.description"
								left
								color="var(--gris-secondary)"
								max-width="500"
							>
								<template v-slot:activator="{ on, attrs }">
									<v-col
										cols="6"
										class="d-flex flex-nowrap justify-start align-center pa-0"
										style="cursor: pointer"
										v-bind="attrs"
										v-on="on"
									>
										<span class="fw-regular"
											>{{ popupCompareMap.parameterName }}<i class="fak fa-line-info-2 fs-sm fw-regular ps-1"></i
										></span>
									</v-col>
								</template>
								<span class="fs-regular">{{ popupCompareMap.description }}</span>
							</v-tooltip>
							<v-col v-else-if="popupCompareMap.parameterName" cols="6" class="d-flex flex-nowrap justify-start align-center pa-0">
								<span class="fw-regular fs-regular">{{ popupCompareMap.parameterName }}</span>
							</v-col>
							<v-col v-else cols="6" class="d-flex flex-nowrap justify-start align-center pa-0">
								<span class="fw-regular fs-regular">{{ popupCompareMap.pollutant }}</span>
							</v-col>
							<v-col cols="6" class="d-flex flex-nowrap justify-center align-center pollutant-value pa-0">
								<span v-if="popupCompareMap.infoValue != -9999" class="fw-semi fs-regular"
									>{{ popupCompareMap.infoValue }} {{ popupCompareMap.unit }}</span
								>
								<span v-else class="fw-semi fs-regular">{{ $t('map.infoNoData') }}</span>
							</v-col>
						</v-row>
					</v-container>
					<v-progress-linear :indeterminate="true" :color="primaryColor" v-else></v-progress-linear>
				</v-card>
			</div>
		</template>
		<span class="fs-regular">{{ $t('rasterTools.infoBypixel') }}</span>
	</v-tooltip>
</template>

<script>
import ol2map from './ol2map';

import { toLonLat } from 'ol/proj';
import { toStringHDMS } from 'ol/coordinate';
import Overlay from 'ol/Overlay';

import { toggleActiveClass } from '@/api/common';
//import constants from '@/utils/constants';

import { COMPAREMAPS_PANEL_ID } from '@/lib/variables/panels';

export default {
	name: 'mapgetfeatureinfo',

	//props: ['isMapLoaded'],

	data() {
		return {
			toolActive: false,
			toolActiveCompareMap: false,
			/* isRasterActive: false, */
			toolInfoBypixel: '',
			popup: {
				titleName: '',
				titleInfo: '',
				pollutant: null,
				unit: null,
				hdms: '',
				infoValue: null,
				description: null,
				parameterName: null
			},
			popupCompareMap: {
				titleName: '',
				titleInfo: '',
				pollutant: null,
				unit: null,
				hdms: '',
				infoValue: null,
				description: null,
				parameterName: null
			},
			primaryColor: this.$store.state.global.primaryColor,
			overlay: null,
			overlayCompareMap: null,
			progress: 0,
			refreshSession: 0,
			interval: null,
			delta: 20
		};
	},

	props: {},

	computed: {
		isToolActivate() {
			return this.toolActive;
		},

		isLayersrasterCompareMap2() {
			return this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap2;
		},

		activeRasterLayer: {
			get() {
				return this.$store.getters.getActiveRasterLayer;
			},
			set(value) {
				this.$store.commit('setActiveRasterLayer', value);
			}
		},
		activeCompareMap1RasterLayer: {
			get() {
				return this.$store.getters.getActiveCompareMap1RasterLayer;
			},
			set(value) {
				this.$store.commit('setActiveCompareMap1RasterLayer', value);
			}
		},
		activeCompareMap2RasterLayer: {
			get() {
				return this.$store.getters.getActiveCompareMap2RasterLayer;
			},
			set(value) {
				this.$store.commit('setActiveCompareMap2RasterLayer', value);
			}
		},
		isRasterActive: {
			get() {
				return this.$store.getters.isRasterActive;
			},
			set(value) {
				this.$store.commit('setIsRasterActive', value);
			}
		},
		isRasterCompareMap1Active: {
			get() {
				return this.$store.getters.isRasterCompareMap1Active;
			},
			set(value) {
				this.$store.commit('setIsRasterCompareMap1Active', value);
			}
		},
		isRasterCompareMap2Active: {
			get() {
				return this.$store.getters.isRasterCompareMap2Active;
			},
			set(value) {
				this.$store.commit('setIsRasterCompareMap2Active', value);
			}
		}
	},
	watch: {
		'$store.state.mappanel.rasterLayersSettings': {
			handler() {
				if (this.$store.state.settingsPanel.isOpened.layersrasterSettings) {
					var layerActiveId = Object.keys(this.$store.state.mappanel.rasterLayersSettings).find(
						(layer) => this.$store.state.mappanel.rasterLayersSettings[layer]['active'] == true
					);
					if (this.activeRasterLayer && layerActiveId) {
						//this.isRasterActive = true;
						//this.toolActive = true;
						//this.activateTool(this.toolActive);
						var active = layerActiveId.length > 0;
						this.activateTool(active);
					} else {
						//this.isRasterActive = false;
						this.deactivateTool();
					}
				}
			},
			deep: true
		},

		'$store.state.mappanel.rasterLayersCompareMap1': {
			handler() {
				if (this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap1) {
					var layerActiveId = Object.keys(this.$store.state.mappanel.rasterLayersCompareMap1).find(
						(layer) => this.$store.state.mappanel.rasterLayersCompareMap1[layer]['active'] == true
					);
					if (this.activeCompareMap1RasterLayer && layerActiveId) {
						//this.isRasterCompareMap1Active = true;
						//this.toolActive = true;
						var active = layerActiveId.length > 0;
						this.activateTool(active);
					} else {
						//this.isRasterCompareMap1Active = false;
						this.deactivateTool();
					}
				}
			},
			deep: true
		},

		'$store.state.mappanel.rasterLayersCompareMap2': {
			handler() {
				if (this.$store.state.compareMapPanel.isOpened.layersrasterCompareMap2) {
					var layerActiveId = Object.keys(this.$store.state.mappanel.rasterLayersCompareMap2).find(
						(layer) => this.$store.state.mappanel.rasterLayersCompareMap2[layer]['active'] == true
					);
					if (this.activeCompareMap2RasterLayer && layerActiveId) {
						//this.isRasterCompareMap2Active = true;

						//this.toolActiveCompareMap = true;
						var active = layerActiveId.length > 0;
						this.activateToolCompareMap(active);
					} else {
						//this.isRasterCompareMap2Active = false;
						this.deactivateToolCompareMap();
					}
				}
			},
			deep: true
		},

		activeRasterLayer: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				if (!newValue) {
					this.deactivateTool();
				}
			}
		},

		activeCompareMap1RasterLayer: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue, oldValue) {
				if (!newValue) {
					this.deactivateTool();
				}
			}
		},

		activeCompareMap2RasterLayer: {
			// eslint-disable-next-line no-unused-vars
			handler(newValue) {
				if (!newValue) {
					this.deactivateToolCompareMap();
				}
			}
		},
		isToolActivate: {
			handler(newValue) {
				toggleActiveClass('infoRasterBtn', newValue);
			}
		}
	},
	mounted() {
		this.toolInfoBypixel = this.$t('rasterTools.infoBypixel');
	},
	destroyed() {
		if (ol2map.getMapInstance()) {
			ol2map.getMapInstance().un('singleclick', this.getFeatureInfo);
			if (this.overlay) {
				this.overlay.setPosition(undefined);
			}
		}
		if (ol2map.getMapCompareInstance()) {
			ol2map.getMapCompareInstance().un('singleclick', this.getFeatureInfo);
			if (this.overlayCompareMap) {
				this.overlayCompareMap.setPosition(undefined);
			}
		}
		if (this.interval) {
			clearInterval(this.interval);
		}
	},
	methods: {
		toggleTool() {
			if (this.isRasterActive) {
				this.toolActive = !this.toolActive;
				this.activateTool(this.toolActive);
			} else {
				this.$puiNotify.info(this.$t('panels.configpannel.nosatelliteimageactive'));
			}
		},

		deactivateTool() {
			this.toolActive = false;
			this.hidePopup();
			ol2map.getMapInstance().un('singleclick', this.getFeatureInfo);
		},

		deactivateToolCompareMap() {
			this.toolActiveCompareMap = false;
			this.hidePopupCompareMap();
			ol2map.getMapCompareInstance().un('singleclick', this.getFeatureInfo);
		},
		activateTool(activate) {
			this.toolActive = activate;
			if (activate) {
				ol2map.getMapInstance().on('singleclick', this.getFeatureInfo);
			} else {
				ol2map.getMapInstance().un('singleclick', this.getFeatureInfo);
				this.hidePopup();
			}
		},

		activateToolCompareMap(activate) {
			this.toolActiveCompareMap = activate;
			if (activate) {
				ol2map.getMapCompareInstance().on('singleclick', this.getFeatureInfo);
			} else {
				ol2map.getMapCompareInstance().un('singleclick', this.getFeatureInfo);
				this.hidePopupCompareMap();
			}
		},

		hidePopup() {
			// Hide Pop up
			const container = this.$refs.popup;
			if (container) {
				container.setAttribute('hidden', true);
			}
			clearInterval(this.interval);
		},
		hidePopupCompareMap() {
			// Hide Pop up
			const container = this.$refs.popupCompareMap;
			if (container) {
				container.setAttribute('hidden', true);
			}
			clearInterval(this.interval);
		},

		getFeatureInfo(evt) {
			if (this.$store.getters.activePanel !== COMPAREMAPS_PANEL_ID) {
				var layerMapSettings = null;
				const activeLayerSettings = Object.keys(this.$store.state.mappanel.rasterLayersSettings).find(
					(layerActive) => this.$store.state.mappanel.rasterLayersSettings[layerActive]['active']
				);
				if (activeLayerSettings !== undefined) {
					ol2map
						.getMapInstance()
						.getLayers()
						.forEach(function (layerActive) {
							if (layerActive.get('id') === activeLayerSettings) {
								layerMapSettings = layerActive;
							}
						});
				}
				var wmsSource = layerMapSettings.getSource();
				const viewResolution = ol2map.getMapInstance().getView().getResolution();
				const url = wmsSource.getFeatureInfoUrl(evt.coordinate, viewResolution, 'EPSG:3857', { INFO_FORMAT: 'application/json' });
				if (url) {
					fetch(url)
						.then((response) => response.text())
						.then((json) => {
							//console.log('then json', json, layerMapSettings.values_.id);
							if (json.split(':')[0] && json.split(':')[0].toLowerCase() == 'proxy error') {
								//console.log('proxy error');
							} else {
								var jsonParse = JSON.parse(json);
								//console.log('jsonParse', jsonParse);
								if (jsonParse.features.length !== 0) {
									this.showPopupInfo(json, evt, layerMapSettings.values_.id);
								} else {
									//console.log('Para estas coordenadas no hay una capa raster en el MapSettings.');
								}
							}
						});
				}
			} else {
				var layerCompareMap1 = null;
				var activeLayerCompareMap1 = null;
				if (this.$store.state.mappanel.rasterLayersCompareMap1 !== null) {
					activeLayerCompareMap1 = Object.keys(this.$store.state.mappanel.rasterLayersCompareMap1).find(
						(layerActive) => this.$store.state.mappanel.rasterLayersCompareMap1[layerActive]['active']
					);
				}
				if (activeLayerCompareMap1 !== undefined && activeLayerCompareMap1 !== null) {
					ol2map
						.getMapInstance()
						.getLayers()
						.forEach(function (layerActive) {
							if (layerActive.get('id') === activeLayerCompareMap1) {
								layerCompareMap1 = layerActive;
							}
						});

					const wmsSourceCompareMap1 = layerCompareMap1.getSource();
					const viewResolutionCompareMap1 = ol2map.getMapInstance().getView().getResolution();
					const urlCompareMap1 = wmsSourceCompareMap1.getFeatureInfoUrl(evt.coordinate, viewResolutionCompareMap1, 'EPSG:3857', {
						INFO_FORMAT: 'application/json'
					});
					if (urlCompareMap1) {
						fetch(urlCompareMap1)
							.then((response) => response.text())
							.then((json) => {
								//console.log('then json', json, layerCompareMap1.values_.id);
								if (json.split(':')[0] && json.split(':')[0].toLowerCase() == 'proxy error') {
									//console.log('proxy error');
								} else {
									const jsonParse = JSON.parse(json);
									//console.log('jsonParse', jsonParse);
									if (jsonParse.features.length !== 0) {
										this.showPopupInfo(json, evt, layerCompareMap1.values_.id);
									} /* else {
										console.log('Para estas coordenadas no hay una capa raster en el MapSettings.');
									}*/
								}
							});
					}
				}

				var layerCompareMap2 = null;
				var activeLayerCompareMap2 = null;

				if (this.$store.state.mappanel.rasterLayersCompareMap2 !== null) {
					activeLayerCompareMap2 = Object.keys(this.$store.state.mappanel.rasterLayersCompareMap2).find(
						(layerActive) => this.$store.state.mappanel.rasterLayersCompareMap2[layerActive]['active']
					);
				}
				if (activeLayerCompareMap2 !== undefined && activeLayerCompareMap2 !== null) {
					ol2map
						.getMapCompareInstance()
						.getLayers()
						.forEach(function (layerActive) {
							if (layerActive.get('id') === activeLayerCompareMap2) {
								layerCompareMap2 = layerActive;
							}
						});

					const wmsSourceCompareMap2 = layerCompareMap2.getSource();
					const viewResolutionCompareMap2 = ol2map.getMapCompareInstance().getView().getResolution();
					const urlCompareMap2 = wmsSourceCompareMap2.getFeatureInfoUrl(evt.coordinate, viewResolutionCompareMap2, 'EPSG:3857', {
						INFO_FORMAT: 'application/json'
					});

					if (urlCompareMap2) {
						fetch(urlCompareMap2)
							.then((response) => response.text())
							.then((json) => {
								if (json.split(':')[0] && json.split(':')[0].toLowerCase() == 'proxy error') {
									//console.log('proxy error');
								} else {
									const jsonParse = JSON.parse(json);
									if (jsonParse.features.length !== 0) {
										this.showPopupInfoCompareMap(json, evt, layerCompareMap2.values_.id);
									} /* else {
										console.log('Para estas coordenadas no hay una capa raster en el MapCompare.');
									}*/
								}
							});
					}
				}
			}
		},

		showPopupInfo(jsonData, evt, id) {
			const container = this.$refs.popup;

			this.overlay = new Overlay({
				id: 'overlay',
				element: container,
				autoPan: {
					animation: {
						duration: 250
					}
				}
			});

			/* closer.onclick = function () {
				overlay.setPosition(undefined);
				closer.blur();
				return false;
			}; */
			var jsonParse = JSON.parse(jsonData);
			console.info('debug json parse', jsonParse, jsonParse.features[0].properties.GRAY_INDEX);
			if (jsonParse.features && jsonParse.features.length > 0 && jsonParse.features[0].properties.GRAY_INDEX) {
				//if (jsonParse.features && jsonParse.features.length > 0) {
				container.removeAttribute('hidden');
				/*let decimalPlaces = jsonParse.features[0].properties.GRAY_INDEX % 1;
				console.debug(
					'debug gray index',
					decimalPlaces.toString().length,
					jsonParse.features[0].properties.GRAY_INDEX % 1,
					jsonParse.features[0].properties.GRAY_INDEX
				);
				if (decimalPlaces != 0 && decimalPlaces.toString().length > 2) {
					this.popup.infoValue = jsonParse.features[0].properties.GRAY_INDEX.toExponential(2);
				} else {
					this.popup.infoValue = jsonParse.features[0].properties.GRAY_INDEX;
				}*/

				const value = jsonParse.features[0].properties.GRAY_INDEX;
				const decimalPart = value.toString().split('.')[1] || '';

				if (value < 1 && decimalPart.startsWith('0')) {
					// Si el valor es menor que 1 y los decimales empiezan con '0', usa notación exponencial
					this.popup.infoValue = value.toExponential(2);
				} else if (decimalPart.length > 2) {
					// Si el número tiene más de dos decimales, muestra solo los primeros tres
					this.popup.infoValue = parseFloat(value.toFixed(3));
				} else {
					// Si el número no tiene más de dos decimales, muestra el valor original
					this.popup.infoValue = value;
				}

				const data = this.getTitle(id);
				this.popup.titleName = data.titleName;
				this.popup.titleInfo = data.titleInfo;
				this.popup.pollutant = data.pollutant;
				this.popup.unit = this.getUnitForPollutant(data.pollutant);
				this.popup.parameterName = this.getParameterTooltip(data.pollutant);

				const coordinate = evt.coordinate;
				this.popup.hdms = toStringHDMS(toLonLat(coordinate));
				//console.debug('debug popup info', this.popup.hdms.split('N'), this.popup.hdms.split('S'), this.popup.hdms.replace('S', 'S,'));
				if (this.popup.hdms.split('N').length == 2) {
					//console.debug('debug 1');
					let newHdms = this.popup.hdms.replace('N', 'N,');
					this.popup.hdms = newHdms;
				} else if (this.popup.hdms.split('S').length == 2) {
					//console.debug('debug 2');
					let newHdms = this.popup.hdms.replace('S', 'S,');
					this.popup.hdms = newHdms;
				}
				this.overlay.setPosition(coordinate);

				ol2map.addOverlay(this.overlay, ol2map.getMapInstance());

				this.startTimeout();
			} else {
				this.closePopup();
			}
		},

		showPopupInfoCompareMap(jsonData, evt, id) {
			const container = this.$refs.popupCompareMap;

			this.overlayCompareMap = new Overlay({
				id: 'overlayCompareMap',
				element: container,
				autoPan: {
					animation: {
						duration: 250
					}
				}
			});
			/* closer.onclick = function () {
				overlay.setPosition(undefined);
				closer.blur();
				return false;
			}; */
			var jsonParse = JSON.parse(jsonData);
			//console.info('debug json parse', jsonParse, jsonParse.features[0].properties.GRAY_INDEX);
			if (jsonParse.features && jsonParse.features.length > 0 && jsonParse.features[0].properties.GRAY_INDEX) {
				//if (jsonParse.features && jsonParse.features.length > 0) {
				container.removeAttribute('hidden');
				/*let decimalPlaces = jsonParse.features[0].properties.GRAY_INDEX % 1;
				console.debug(
					'debug gray index',
					decimalPlaces.toString().length,
					jsonParse.features[0].properties.GRAY_INDEX % 1,
					jsonParse.features[0].properties.GRAY_INDEX
				);
				if (decimalPlaces != 0 && decimalPlaces.toString().length > 2) {
					this.popupCompareMap.infoValue = jsonParse.features[0].properties.GRAY_INDEX.toExponential(2);
				} else {
					this.popupCompareMap.infoValue = jsonParse.features[0].properties.GRAY_INDEX;
				}*/

				const value = jsonParse.features[0].properties.GRAY_INDEX;
				const decimalPart = value.toString().split('.')[1] || '';

				if (value < 1 && decimalPart.startsWith('0')) {
					// Si el valor es menor que 1 y los decimales empiezan con '0', usa notación exponencial
					this.popupCompareMap.infoValue = value.toExponential(2);
				} else if (decimalPart.length > 2) {
					// Si el número tiene más de dos decimales, muestra solo los primeros tres
					// this.popupCompareMap.infoValue = value.toFixed(3);
					this.popupCompareMap.infoValue = parseFloat(value.toFixed(3));
				} else {
					// Si el número no tiene más de dos decimales, muestra el valor original
					this.popupCompareMap.infoValue = value;
				}

				const data = this.getTitle(id);
				this.popupCompareMap.titleName = data.titleName;
				this.popupCompareMap.titleInfo = data.titleInfo;
				this.popupCompareMap.pollutant = data.pollutant;
				this.popupCompareMap.unit = this.getUnitForPollutant(data.pollutant);
				this.popupCompareMap.parameterName = this.getParameterTooltip(data.pollutant);

				const coordinate = evt.coordinate;
				this.popupCompareMap.hdms = toStringHDMS(toLonLat(coordinate));
				/*console.debug(
					'debug popupCompareMap info',
					this.popupCompareMap.hdms.split('N'),
					this.popupCompareMap.hdms.split('S'),
					this.popupCompareMap.hdms.replace('S', 'S,')
				);*/
				if (this.popupCompareMap.hdms.split('N').length == 2) {
					let newHdms = this.popupCompareMap.hdms.replace('N', 'N,');
					this.popupCompareMap.hdms = newHdms;
				} else if (this.popupCompareMap.hdms.split('S').length == 2) {
					let newHdms = this.popupCompareMap.hdms.replace('S', 'S,');
					this.popupCompareMap.hdms = newHdms;
				}
				this.overlayCompareMap.setPosition(coordinate);

				ol2map.addOverlay(this.overlayCompareMap, ol2map.getMapCompareInstance());

				this.startTimeout();
			} else {
				this.closePopupCompareMap();
			}
		},

		getUnitForPollutant(parameterAcronym) {
			const satelliteParameters = this.$store.getters.getSatelliteParameters;
			console.log('debug satelliteParameters ', parameterAcronym, satelliteParameters);
			let parameter = satelliteParameters.find((parameter) => parameter.acronym == parameterAcronym);

			if (parameter) return parameter.symbol;
			return '';
		},

		getTitle(idImage) {
			let title = idImage.split(':')[1];
			console.debug('debug title', title, idImage, title.split('_'));

			//var satelliteType = title.split('_')[0];
			var titleName = this.$t('map.titleSentinel5P');
			var titleInfo = this.$t('map.infoSentinel5P');
			var pollutant = title.split('_')[1];

			/* if (satelliteType == constants.CAMS_ACRONYM) {
				//console.log('pollutant CAMS: ' + title.split('_')[1]);
				titleName = this.$t('map.titleCAMS');
				titleInfo = this.$t('map.infoCAMS');
				pollutant = title.split('_')[1];
			} else if (satelliteType == constants.CMEMS_ACRONYM) {
				//console.log('pollutant CMEMS: ' + title.split('_')[2]);
				titleName = this.$t('map.titleCMEMS');
				titleInfo = this.$t('map.infoCMEMS');
				pollutant = title.split('_')[2];
			} else {
				titleName = this.$t('map.titleSentinel5P');
				titleInfo = this.$t('map.infoSentinel5P');
				pollutant = title.split('_')[1];
			} */

			return { titleName, titleInfo, pollutant };
		},
		getParameterTooltip(acronym) {
			//console.log('parameter tooltip sat ' + acronym,  this.$store.getters.getSatelliteParameters);
			let parameter = this.$store.getters.getSatelliteParameters.find((parameter) => parameter.acronym == acronym);
			if (parameter) {
				//console.debug('debug parameter length', parameter.name, parameter.name.length);
				this.popup.description = this.popupCompareMap.description = parameter.description ? parameter.description : null;
				return parameter.name /* + (parameter.description ? ' - ' + parameter.description : '') */;
			}
			this.popup.description = this.popupCompareMap.description = null;
			return null;
		},
		startTimeout() {
			// start next interval
			//console.log('debug start refresh time', this.delta, this.progress);
			if (this.interval) {
				clearInterval(this.interval);
			}
			this.progress = 0;
			this.interval = setInterval(this.tick, 1000);
		},
		stopTimeout() {
			//console.log('debug stop refresh time', this.interval, this.progress);
			if (this.interval) {
				clearInterval(this.interval);
			}
			this.progress = 0;
		},

		tick() {
			//console.warn('debug popup timeout', this.progress);
			this.progress += this.delta;
			if (this.progress <= 100) {
				return;
			}

			this.progress = 0;
			this.refreshSession += 1;

			this.hidePopup();
			this.hidePopupCompareMap();

			if (this.refreshSession % 5 !== 0) {
				return;
			}

			this.refreshSession = 0;
		},
		closePopup() {
			this.overlay.setPosition(undefined);
			//closer.blur();
			return false;
		},

		closePopupCompareMap() {
			this.overlayCompareMap.setPosition(undefined);
			//closer.blur();
			return false;
		}
	}
};
</script>

<style lang="postcss">
.ol-popup {
	position: absolute;
	background-color: white;
	box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
	padding: 15px;
	border-radius: 4px;
	border: 0px solid #cccccc;
	bottom: 12px;
	left: -50px;
	min-width: 300px;
}

.ol-popup:after,
.ol-popup:before {
	top: 100%;
	border: solid transparent;
	content: ' ';
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.ol-popup:after {
	border-top-color: white;
	border-width: 10px;
	left: 48px;
	margin-left: -10px;
}

.ol-popup:before {
	border-top-color: #cccccc;
	border-width: 11px;
	left: 48px;
	margin-left: -11px;
}

.popup-title {
	flex: 1 1 100%;
}

.card-popup {
	border-radius: 4px !important;
}

.card-title {
	background-color: var(--azul-primary-100);
	border-top-right-radius: 8px;
	border-top-left-radius: 8px;
	color: #fff;

	& .card-title-text {
		font-size: 20px !important;
	}

	& .card-subtitle-text {
		font-size: 1rem !important;
	}
}

.switchoff {
	color: var(--gris-300);

	& .switchoff_icon {
		font-size: 2rem !important;
	}

	& .switchoff-text {
		font-size: 1rem !important;
	}
}

.pollutant-value {
	border-radius: 4px;
}
</style>

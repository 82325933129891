<template>
	<div>
		<v-tooltip top color="var(--gris-secondary)" transition="none">
			<template v-slot:activator="{ on, attrs }">
				<v-btn id="image-download" text class="btndownloadpng" @click="mapdownloadpng" v-bind="attrs" v-on="on">
					<v-icon class="fw-bold" size="12">far fa-file-export</v-icon>
				</v-btn>
			</template>
			<span class="fs-regular">{{ $t('map.downloadimg') }}</span>
		</v-tooltip>
	</div>
</template>

<script>
import ol2map from './ol2map';
import 'ol/ol.css';
import { trackEvent } from '@/plugins/matomo';
import { formatTimestamp } from '@/api/common';

export default {
	name: 'mapdownloadpng',
	data() {
		return {
			map: null,
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},
	mounted() {},
	computed: {},
	watch: {},
	methods: {
		mapdownloadpng() {
			console.log('Download PNG');

			ol2map.getMapInstance().once('rendercomplete', function () {
				const mapCanvas = document.createElement('canvas');
				const size = ol2map.getMapInstance().getSize();
				mapCanvas.width = size[0];
				mapCanvas.height = size[1];
				const mapContext = mapCanvas.getContext('2d');

				function drawPopups() {
					const popup = document.getElementById('popup-element');
					console.log('popup', popup);
					if (popup) {
						const content = popup.querySelector('.card-popup');
						console.log('content', content);
						const posX = parseInt(10, 10);
						const posY = parseInt(10, 10);
						console.log(posX, posY);

						const tempContainer = document.createElement('div');
						tempContainer.appendChild(content.cloneNode(true));
						console.log('tempContainer', tempContainer);

						const popupImage = new Image();

						popupImage.onload = function () {
							// Dibujar la imagen en el canvas
							console.log('popupImage.onload', popupImage);
							mapContext.drawImage(popupImage, 100, 100);
							console.log('mapContext', mapContext);
						};

						popupImage.src = 'data:image/svg+xml,' + encodeURIComponent(tempContainer.innerHTML);
					}
				}

				Array.prototype.forEach.call(
					ol2map.getMapInstance().getViewport().querySelectorAll('.ol-popup canvas, .ol-layer canvas, canvas.ol-layer'),
					function (canvas) {
						if (canvas.width > 0) {
							const opacity = canvas.parentNode.style.opacity || canvas.style.opacity;
							mapContext.globalAlpha = opacity === '' ? 1 : Number(opacity);
							let matrix;
							const transform = canvas.style.transform;
							if (transform) {
								// Get the transform parameters from the style's transform matrix
								matrix = transform
									.match(/^matrix\(([^(]*)\)$/)[1]
									.split(',')
									.map(Number);
							} else {
								matrix = [parseFloat(canvas.style.width) / canvas.width, 0, 0, parseFloat(canvas.style.height) / canvas.height, 0, 0];
							}
							// Apply the transform to the export map context
							CanvasRenderingContext2D.prototype.setTransform.apply(mapContext, matrix);
							const backgroundColor = canvas.parentNode.style.backgroundColor;
							if (backgroundColor) {
								mapContext.fillStyle = backgroundColor;
								mapContext.fillRect(0, 0, canvas.width, canvas.height);
							}
							mapContext.drawImage(canvas, 0, 0);
						}
					}
				);
				drawPopups();

				console.log('mapContext', mapContext);

				var link = document.createElement('a');
				//console.log('Link', link);
				link.href = mapCanvas.toDataURL('image/png');
				link.download = this.$t('menu.map') + '_' + formatTimestamp(Date.now(), this.userProperties) + '.png';
				link.click();
				trackEvent('Exportar', 'Exportar PNG', link.download);
				link.remove();
			});
			ol2map.getMapInstance().renderSync();
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.maptools {
	& .btndownloadpng {
		margin: 0px;
		width: 32px;
		min-width: 32px !important;
		height: 32px;
		background-color: var(--negative);
	}
}
</style>

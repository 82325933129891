<template>
	<v-card class="pa-0 device-historic" v-if="pollutants">
		<v-tabs fixed-tabs v-model="selectedTabPrediction">
			<v-tab v-for="pollutant in pollutants" :key="pollutants.indexOf(pollutant)" :value="pollutants.indexOf(pollutant)">
				<v-tooltip top color="var(--gris-secondary)">
					<template v-slot:activator="{ on, attrs }">
						<span class="width-100" v-bind="attrs" v-on="on">{{ pollutant.acronym }}</span>
					</template>
					<span class="fs-regular">{{ pollutant.name }}</span>
				</v-tooltip>
			</v-tab>
			<!-- <v-tab
				v-for="pollutant in pollutants"
				:key="pollutants.indexOf(pollutant)"
				:value="pollutants.indexOf(pollutant)"
				:title="pollutant.name"
			>
				{{ pollutant.acronym }}
			</v-tab> -->
		</v-tabs>
		<v-card-text class="pa-0 overflow-visible">
			<v-window v-model="selectedTabPrediction">
				<v-window-item v-for="pollutant in pollutants" :key="pollutants.indexOf(pollutant)" :value="pollutants.indexOf(pollutant)">
					<v-card flat>
						<waterports-chart :feature="deviceObject" :pollutant="pollutant" :displayLocation="displayLocation" />
						<!-- :chartData="historicElasticData[pollutant.elasticacronym]" -->
					</v-card>
				</v-window-item>
			</v-window>
		</v-card-text>
	</v-card>
</template>

<script>
import WaterportsChart from '@/components/echart/WaterportsChart.vue';

export default {
	components: {
		WaterportsChart
	},
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			tab: 0,
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},
	computed: {
		selectedTabPrediction: {
			get() {
				return this.$store.getters.getSelectedTabPrediction;
			},
			set(value) {
				this.$store.commit('setSelectedTabPrediction', value);
			}
		},
		pollutants() {
			return this.$store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid);
		}
	},
	created() {
		console.log('prediction created', this.deviceObject, this.$store.getters.getParameters, this.pollutants);
		if (this.displayLocation === 'card') {
			this.setupPredictionStore();
		}
	},
	methods: {
		setupPredictionStore() {
			console.warn('setupPredictionStore', this.$store.getters.getPredictionStore, this.$store.getters.getPredictionStore.length);
			if (this.$store.getters.getPredictionStore.length === 0) {
				this.pollutants.forEach((pollutant) => {
					this.$store.commit('setFormPredictionObject', {
						index: pollutant.elasticacronym,
						data: {
							index: pollutant.elasticacronym,
							dateInterval: null,
							startDate: null,
							endDate: null,
							designOption: null,
							formDisabled: false,
							startDateDisabled: true,
							endDateDisabled: true,
							infoStartDate: this.$t('aqi.infoDateIntervalDisabled'),
							infoEndDate: this.$t('aqi.infoDateIntervalDisabled'),
							chartData: [],
							loadingAnimation: false,
							parameterConfiguration: {},
							chartType: '',
							configurations: [],
							showChartOptions: false,
							historicalChartData: {},
							showMetrics: false,
							minMAE: '',
							minMAPE: '',
							minMSE: '',
							minR2: '',
							maxMAE: '',
							maxMAPE: '',
							maxMSE: '',
							maxR2: ''
						}
					});
				});
			}
			console.log('historicStore', this.$store.getters.getHistoricStore);
		}
	}
};
</script>

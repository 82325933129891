/* import ol2map from '@/components/map/sections/map/subcomponents/ol2map';
import * as olProj from 'ol/proj';
import * as olExtent from 'ol/extent'; */

export default {
	data() {
		return {
			selectedAreaId: null
		};
	},
	methods: {
		centerAreaMap(areaId) {
			let areas = this.$store.getters.getAreas;
			//let userProps = this.userProperties;
			let selectedArea = areas.find((area) => area.pmareaid == areaId);
			//let polygon = [];
			// TODO: use area points array [lat, lon] to center on area. example:
			/* {
				name: 'Duisport area', // name to show in legend
				color: '#3361DB', // line highlight color
				polygon: [
					[51.4497, 6.7209],
					[51.444, 6.7582],
					[51.4495, 6.7921],
					[51.4318, 6.8045],
					[51.4164, 6.7919]
				]
			}; */
			if (selectedArea && selectedArea.areapoints) {
				let areaDefinition = {
					name: selectedArea.name,
					color: selectedArea.color,
					polygon: JSON.parse(selectedArea.areapoints)
				};
				console.log('NEW Structure: Area points ->', areaDefinition);

				this.$puiNotify.info(this.$t('map.areaSelected'), areaDefinition.name);

				/* TODO: Remove comment when ready to zoom
				ol2map.zoomToExtent(olProj.transformExtent(new olExtent.boundingExtent(polygon), 'EPSG:4326', 'EPSG:3857'));
				*/
				return areaDefinition;
			} else {
				this.$puiNotify.warning(this.$t('map.areaNotFound'), this.$t('status.warning'));
			}
		}
	}
};

<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmvisualizations.formtitle')">
					<v-layout wrap>
						<v-flex xs12 md6>
							<pui-text-field
								:label="$t('pmvisualizations.name')"
								v-model="model.name"
								required
								toplabel
								maxlength="150"
							></pui-text-field>
						</v-flex>
						<v-flex xs12 md6>
							<pui-text-field
								v-model="model.acronym"
								:label="$t('pmvisualizations.acronym')"
								required
								maxlength="5"
								toplabel
							></pui-text-field>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12>
							<pui-text-area
								v-model="model.description"
								:label="$t('pmvisualizations.description')"
								maxlength="250"
								toplabel
							></pui-text-area>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md2>
							<pui-checkbox
								:label="$t('pmvisualizations.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
						<v-flex xs12 md2>
							<pui-checkbox
								:label="$t('pmvisualizations.applyranges')"
								v-model="model.applyranges"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
						<v-flex xs12 md2>
							<pui-checkbox
								:label="$t('pmvisualizations.exportable')"
								v-model="model.exportable"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
						<v-flex xs12 md2>
							<pui-checkbox
								:label="$t('pmvisualizations.showintervals')"
								v-model="model.showintervals"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
						<v-flex xs12 md2>
							<pui-checkbox
								:label="$t('pmvisualizations.isdynamic')"
								v-model="model.isdynamic"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
					<v-row no-gutters>
						<v-col xs12>
							<pui-field-set :title="$t('pmvisualizations.chartconfiguration')">
								<vue-json-editor
									v-model="chartconfiguration"
									:options="options"
									:plus="false"
									height="500px"
									@error="onErrorQuery"
								></vue-json-editor>
							</pui-field-set>
						</v-col>
					</v-row>
					<pui-field-set :title="$t('pmvisualizations.visualizationtype')">
						<v-layout wrap>
							<pui-multi-select
								id="multiSelectVisualizationTypes"
								itemValue="text"
								itemText="text"
								v-model="model.visualizationtype"
								:items="items"
								:itemsToSelect="allVisualizationType"
								:disabled="formDisabled"
								required
							></pui-multi-select>
						</v-layout>
					</pui-field-set>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="formatVisualizations"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import VueJsonEditor from '@/components/ui/VueJsonEditor';

export default {
	name: 'pmvisualizationsform',
	mixins: [PuiFormMethodsMixin],
	components: {
		VueJsonEditor
	},
	data() {
		return {
			modelName: 'pmvisualizations',
			model: 'pmvisualizations',
			chartconfiguration: null,
			options: {
				mode: 'code',
				enableSort: true,
				enableTransform: true
			},
			items: [{ text: 'HISTORIC' }, { text: 'REALTIME' }, { text: 'PREDICTIONS' }, { text: 'COMPAREAQI' }],
			allVisualizationType: []
			//items: ['HISTORIC', 'REALTIME', 'PREDICTIONS']
		};
	},
	computed: {
		datatypeItemsToSelect() {
			return [{ pmdatatypeid: this.model.pmdatatypeid }];
		}
	},
	watch: {
		'model.visualizationtype': function () {
			//console.log('visualization watcher');
			if (typeof this.model.visualizationtype == 'string') {
				// first retrieval of values
				this.model.visualizationtype.split(',').forEach((visualization) => {
					this.allVisualizationType.push({ text: visualization });
				});
			}

			/* this.model.visualizationtype.forEach((type) => {
				console.log('type: ', type);
				console.log(type.text);
			}); */
		},
		'model.configuration'(val) {
			//console.log('config', prev, val);
			if (val) this.chartconfiguration = JSON.parse(val);
		},
		chartconfiguration: {
			handler(newValue) {
				if (newValue) {
					this.validJson = true;
					//this.model.configuration = JSON.stringify(this.chartconfiguration);
				}
			}
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	mounted() {
		/* this.allVisualizationType = this.model.visualizationtype.split(',');
		console.warn(this.model.visualizationname);
		console.warn(this.model.pmvisualizationsid); */
	},
	updated() {},
	methods: {
		formatVisualizations() {
			//console.warn('FORMAT VISUALIZATIONS BEFORE INSERT', this.model.visualizationtype);
			if (typeof this.model.visualizationtype !== 'string') {
				if (Object.keys(this.model.visualizationtype).length > 0) {
					var tempVisualizations = [];

					this.model.visualizationtype.forEach((element) => {
						//console.log('deubg foreach', element);
						tempVisualizations.push(element.text);
					});
					//console.log('debug temp visualizations', tempVisualizations);
					this.model.visualizationtype = tempVisualizations.join(',');
				} else {
					this.model.visualizationtype = null;
				}
			}
			if (this.validJson) this.model.configuration = JSON.stringify(this.chartconfiguration);
			else this.model.configuration = null;
			console.log('saving debug', this.model.configuration, this.validJson);
			this.save();
		},
		onErrorQuery() {
			console.log('error query');
			this.validJson = false;
		}
	}
};
</script>

<template>
	<div class="notification-historic">
		<pui-datatable
			:modelName="modelName"
			:modalDialog="true"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:pageLength="5"
			:lengthMenu="[5, 10, 20, 50, 100]"
			:externalFilter="externalFilter"
			:modelColumnDefs="columnDefs"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
			:showRowActions="false"
			:readOnly="true"
			:navigableDetail="false"
		>
		</pui-datatable>
		<!-- <pui-datatable
			v-else
			:modelName="modelName"
			:modalDialog="true"
			:showCreateBtn="false"
			:showDeleteBtn="false"
			:pageLength="displayLocation == 'card' ? 5 : 10"
			:lengthMenu="[10, 20, 50, 100]"
			:externalFilter="externalFilter"
			:modelColumnDefs="columnDefs"
			:masterDetailColumnVisibles="masterDetailColumnVisibles"
			:showRowActions="false"
			:readOnly="true"
			:navigableDetail="false"
		>
		</pui-datatable> -->
		<notification-historic-dialog
			v-show="notificationSource"
			:source="notificationSource"
			:deviceObject="deviceObject"
			:dialog.sync="visible"
			@update:historicDialog="visible = false"
			className="notification-dialog"
		></notification-historic-dialog>
	</div>

	<!-- :modelColumnDefs="columnDefs" -->
</template>

<script>
import PuiGridDetailMixin from '@/mixins/PuiGridDetailMixin';
import { formatTimestamp, isSuperAdmin } from '@/api/common';
import $ from 'jquery';
import NotificationHistoricDialog from './NotificationHistoricDialog.vue';

export default {
	mixins: [PuiGridDetailMixin],
	components: { NotificationHistoricDialog },
	name: 'HistoricalNotifications',
	props: {
		deviceObject: {
			type: Object,
			required: true
		},
		displayLocation: {
			type: String,
			required: false
		}
	},
	data() {
		return {
			modelName: 'historicalnotifications',
			visible: false,

			currentRules: [],
			masterDetailColumnVisibles: {
				rulename: true,
				startdate: true,
				enddate: true,
				isaverage: true,
				ismobile: true,
				source: true
			},
			columnDefs: {
				rulename: {
					createdCell: (td, cellData, rowData) => {
						let ruleName = this.getRuleName(cellData);
						return (td.innerHTML = `<label class="fw-600" style="color:${rowData.color}">${ruleName}</label>`);
					}
				},
				startdate: {
					render: (data) => {
						return formatTimestamp(data, this.userProperties);
					}
				},
				enddate: {
					render: (data) => {
						return formatTimestamp(data, this.userProperties);
					}
				},
				source: {
					// eslint-disable-next-line no-unused-vars
					createdCell: (td, cellData, rowData) => {
						td.id = 'source_' + rowData.id;
						td.classList.add('source-cell', 'd-flex', 'justify-center', 'pa-1');
						let inputSource = document.createElement('button');
						inputSource.classList.add('source-btn', 'px-3', 'py-1');
						inputSource.addEventListener('click', this.showSource, false);
						inputSource.sourceInfo = JSON.parse(cellData);
						// icon test
						let inputIcon = document.createElement('i');
						inputIcon.classList.add('fak', 'fa-line-statistics', 'fs-regular');
						inputSource.append(inputIcon);
						inputSource.innerHTML = '<i class="fak fa-line-statistics pr-2"></i>' + this.$t('grid.historicalnotifications.source');
						td.innerHTML = '';
						td.appendChild(inputSource);
						return;
					}
				},
				isaverage: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				},
				ismobile: {
					render: (data) => {
						if (data === 0) {
							return this.$t('general.no');
						} else if (data === 1) {
							return this.$t('general.yes');
						}
						return data;
					}
				}
			},
			notificationSource: {},
			isSuperAdmin: false
		};
	},
	computed: {
		pollutants() {
			return this.deviceObject
				? this.$store.getters.getParameters.filter((parameter) => parameter.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid)
				: [];
		},
		selectedTabNotificationDialog: {
			get() {
				return this.$store.getters.getSelectedTabNotificationDialog;
			},
			set(value) {
				this.$store.commit('setSelectedTabNotificationDialog', value);
			}
		},
		selectedTabDevice() {
			return this.$store.getters.getSelectedTabDevice;
		},
		displayMode() {
			return this.$store.getters.getDisplayMode;
		},
		fullscreenDialog() {
			return this.$store.getters.getFullscreenDialog;
		}
	},
	watch: {
		fullscreenDialog(val) {
			if (val) {
				this.$puiEvents.$emit(`onPui-changeRowNum-dataTable-${this.modelName}`, 20);
			} else {
				this.$puiEvents.$emit(`onPui-changeRowNum-dataTable-${this.modelName}`, 10);
			}
			//this.$puiEvents.$emit(`onPui-changeRowNum-dataTable-${this.modelName}`, len);
		}
	},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	mounted() {
		this.setExternalFilter();
		this.table = $(this.$el)
			.find('#' + this.modelName)
			.dataTable()
			.api();
	},
	destroyed() {},
	methods: {
		showSource(evt) {
			this.notificationSource = evt.currentTarget.sourceInfo;
			let rule = this.$store.getters.getRules.find((rule) => rule.acronym == this.notificationSource['rule_name']);
			let rulePollutant = null;
			if (rule) {
				rulePollutant = this.pollutants.find((pollutant) => pollutant.elasticacronym == rule.filterparameter);
				this.selectedTabNotificationDialog = this.pollutants.indexOf(rulePollutant);
				this.visible = true;
			} else {
				this.$puiNotify.info('Please try again later', 'Not available');
				evt.currentTarget.disabled = true;
				evt.currentTarget.classList.add('disabled');
			}
		},
		getRuleName(ruleName) {
			let rule = this.$store.getters.getRules.find((rule) => rule.acronym == ruleName);
			if (rule) return rule.name;
			return ruleName;
		},
		setExternalFilter() {
			let ruleNames = this.$store.getters.getRules
				.filter((rule) => rule.pmdatasourcetypeid == this.deviceObject.pmdatasourcetypeid)
				.map((obj) => obj.acronym);
			if (ruleNames.length > 0) {
				if (this.isSuperAdmin) {
					this.externalFilter = {
						groupOp: 'or',
						rules: [],
						groups: []
					};
					ruleNames.forEach((rule) => {
						this.externalFilter.groups.push({
							groupOp: 'and',
							rules: [
								{ field: 'rulename', op: 'eq', data: rule },
								{ field: 'device', op: 'eq', data: this.deviceObject.code }
							]
						});
					});
				} else {
					this.externalFilter = {
						groupOp: 'or',
						rules: [],
						groups: []
					};
					ruleNames.forEach((rule) => {
						this.externalFilter.groups.push({
							groupOp: 'and',
							rules: [
								{ field: 'rulename', op: 'eq', data: rule },
								{ field: 'device', op: 'eq', data: this.deviceObject.code },
								{ field: 'organizationid', op: 'eq', data: this.userProperties.organizationid }
							]
						});
					});
				}
			} else {
				if (this.isSuperAdmin) {
					this.externalFilter = {
						groupOp: 'and',
						rules: [{ field: 'device', op: 'eq', data: this.deviceObject.code }],
						groups: []
					};
				} else {
					this.externalFilter = {
						groupOp: 'and',
						rules: [
							{ field: 'device', op: 'eq', data: this.deviceObject.code },
							{ field: 'organizationid', op: 'eq', data: this.userProperties.organizationid }
						],
						groups: []
					};
				}
			}
		}
	}
};
</script>

<style lang="postcss">
td.source-cell {
	button.source-btn {
		color: #fff !important;
		background-color: var(--azul-primary-100) !important;
		border-radius: 4px !important;
	}
	button.source-btn.disabled {
		color: #fff !important;
		background-color: var(--gris-300) !important;
		border-radius: 4px !important;
	}
}
</style>

<template>
	<div class="header d-flex flex-column ml-4 justify-center">
		<div class="d-flex mt-2 justify-start" :class="{ disabled }" @click="centerAreaMap(areaId)" style="width: 100% !important">
			<div>{{ title }}</div>

			<v-icon size="18" class="ml-2" @click="$listeners['click:close']" v-if="showClose">close</v-icon>

			<div class="ml-auto">{{ total }}</div>
		</div>

		<div class="kpibar d-flex justify-start">
			<slot />
		</div>
	</div>
</template>

<script>
import AreaMixin from '@/mixins/AreaMixin';

export default {
	mixins: [AreaMixin],
	props: {
		disabled: {
			type: Boolean
		},

		showClose: {
			type: Boolean,
			default: false
		},

		title: {
			type: String
		},
		areaId: {
			type: String
		},

		total: {
			type: Number
		}
	},
	methods: {}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.header {
	color: var(--high);

	& * {
		transition: color 0.2s ease-in;
	}

	& .disabled {
		* {
			color: var(--moderate);
		}
	}
}

.kpibar {
	height: 6px;
	width: 100%;
	background: #e4e4e4;
	border-radius: 2px;
	margin-top: 3px;
	overflow: hidden;
}
</style>

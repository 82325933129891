<template>
	<div class="d-flex flex-column settingsmappanel fill-height max-height-100 dashboard-panel-kpis">
		<div class="d-flex flex-column py-3 px-3 bottomborder">
			<div class="settings-subtitle d-flex justify-start align-center fw-regular fs-sm">
				<v-list v-if="slaDate" lines="one" density="compact" class="d-flex justify-start pa-0" style="background: transparent">
					<v-icon size="14" class="fw-500 pr-2">fak fa-solid-hour</v-icon>
					<span class="date-text fw-regular fs-sm ma-0 px-0 py-1">
						{{ slaDate }}
					</span>
				</v-list>
				<!-- <v-skeleton-loader v-else class="pa-0" max-width="100" loading type="chip"></v-skeleton-loader> -->
			</div>
		</div>
		<div v-if="!isSuperAdmin">
			<v-container v-if="type == 'area'" class="d-flex flex-column pa-0 mt-2">
				<v-card flat class="mx-3 mb-2 rounded" v-if="devices.length > devicesThreshold">
					<v-text-field
						class="ma-0 pa-0 border"
						v-model="filterAreaSLA"
						hide-details
						:placeholder="$t('panels.notifications.searchByCode')"
						:title="$t('panels.notifications.searchByCode')"
						append-icon="fak fa-line-search"
						single-line
						solo
						dense
						flat
						outlined
						maxlength="25"
						clearable
					></v-text-field>
				</v-card>
				<div
					v-if="loadedArea"
					id="sla-area-info"
					:style="{ maxHeight: devices.length > devicesThreshold ? 'calc(100vh - 250px)' : 'calc(100vh - 215px)' }"
				>
					<v-list v-for="(item, i) in filteredDevicesByArea" :key="i" flat dense style="background: transparent" class="pt-0">
						<frequency-panel-group
							v-if="contentTab == 'sla-frequency'"
							:item="item"
							idField="pmareaid"
							nameField="pmareaname"
							:teoricDeliveryDevices="teoricDeliveryDevices"
							:sectionsDevices="sectionsDevices"
						></frequency-panel-group>
						<kpi-panel-group
							v-else
							:item="item"
							:sectionsGroup="sectionsAreas"
							:totalsGroup="totalsAreas"
							idField="pmareaid"
							nameField="pmareaname"
							:sectionsDevices="sectionsDevices"
							:totalsDevices="totalsDevices"
							:teoricSlaDevices="teoricSlaDevices"
						></kpi-panel-group>
					</v-list>
				</div>
				<div
					v-else-if="filteredAreas.length == 0"
					class="switchoff d-flex flex-column align-center justify-center px-0 py-2 mt-4"
					style="width: 100%"
				>
					<v-icon size="1.5rem"> fal fa-inbox </v-icon>
					<div class="switchoff-text">{{ $t('common.noData') }}</div>
				</div>
				<v-progress-linear v-else indeterminate :color="primaryColor"></v-progress-linear>
				<div
					v-if="filterAreaSLA && filteredDevicesByArea.length == 0"
					class="switchoff d-flex flex-column align-center justify-center px-0 py-2 mt-4"
					style="width: 100%"
				>
					<v-icon size="1.5rem"> fal fa-inbox </v-icon>
					<div class="switchoff-text">{{ $t('panels.dashboard.noMatch') }}</div>
				</div>
			</v-container>
			<v-container v-else-if="type == 'datasource'" class="d-flex flex-column pa-0 mt-2">
				<v-card flat class="mx-3 mb-2 rounded" v-if="devices.length > devicesThreshold">
					<v-text-field
						class="ma-0 pa-0 border"
						v-model="filterDatasourceSLA"
						hide-details
						:placeholder="$t('panels.notifications.searchByCode')"
						:title="$t('panels.notifications.searchByCode')"
						append-icon="fak fa-line-search"
						single-line
						solo
						dense
						flat
						outlined
						maxlength="25"
						clearable
					></v-text-field>
				</v-card>
				<div
					v-if="loadedDatasource"
					id="sla-datasource-info"
					:style="{ maxHeight: devices.length > devicesThreshold ? 'calc(100vh - 250px)' : 'calc(100vh - 215px)' }"
				>
					<v-list v-for="(item, i) in filteredDevicesByDatasource" :key="i" flat dense style="background: transparent" class="pt-0">
						<frequency-panel-group
							v-if="contentTab == 'sla-frequency'"
							:item="item"
							idField="pmdatasourcetypeid"
							nameField="pmdatasourcetypename"
							:teoricDeliveryDevices="teoricDeliveryDevices"
							:sectionsDevices="sectionsDevices"
						></frequency-panel-group>
						<kpi-panel-group
							v-else
							:item="item"
							:sectionsGroup="sectionsDatasources"
							:totalsGroup="totalsDatasources"
							idField="pmdatasourcetypeid"
							nameField="pmdatasourcetypename"
							:sectionsDevices="sectionsDevices"
							:totalsDevices="totalsDevices"
							:teoricSlaDevices="teoricSlaDevices"
						></kpi-panel-group>
					</v-list>
				</div>
				<div
					v-else-if="filteredDatasources.length == 0"
					class="switchoff d-flex flex-column align-center justify-center px-0 py-2 mt-4"
					style="width: 100%"
				>
					<v-icon size="1.5rem"> fal fa-inbox </v-icon>
					<div class="switchoff-text">{{ $t('common.noData') }}</div>
				</div>
				<v-progress-linear v-else indeterminate :color="primaryColor"></v-progress-linear>
				<div
					v-if="filterDatasourceSLA && filteredDevicesByDatasource.length == 0"
					class="switchoff d-flex flex-column align-center justify-center px-0 py-2"
					style="width: 100%"
				>
					<v-icon size="1.5rem"> fal fa-inbox </v-icon>
					<div class="switchoff-text">{{ $t('panels.dashboard.noMatch') }}</div>
				</div>
			</v-container>
		</div>
		<div v-else-if="loadedOrg">
			<v-container v-if="type == 'area' && loadedAreaOrg" class="d-flex flex-column pa-0 mt-2">
				<!-- <v-card flat class="mx-3 mb-2 rounded">
					<v-text-field
						class="ma-0 pa-0 border"
						v-model="filterAreaSLA"
						hide-details
						:placeholder="$t('panels.notifications.searchByCode')"
						:title="$t('panels.notifications.searchByCode')"
						append-icon="fak fa-line-search"
						single-line
						solo
						dense
						flat
						outlined
						maxlength="25"
						clearable
					></v-text-field>
				</v-card> -->
				<v-list v-for="(org, i) in areasByOrganization" :key="i" flat dense style="background: transparent" class="pt-0">
					<v-list-group v-model="isOpenedOrg[i]" no-action :ripple="false" append-icon="">
						<template #prependIcon>
							<v-icon size="14" :class="{ rotated: isOpenedOrg[i] }" class="color-high">
								far
								{{ isOpenedOrg[i] ? 'fa-minus' : 'fa-plus' }}
							</v-icon>
						</template>
						<template #activator>
							<kpi-organization-activator
								:orgItem="org"
								:sectionsOrgs="sectionsOrgs"
								:totalsOrg="totalsOrg"
							></kpi-organization-activator>
						</template>
						<v-list
							v-for="(item, i) in devicesByOrgArea[org.pmorganizationid]"
							:key="i"
							flat
							dense
							style="background: transparent"
							class="pt-0"
						>
							<frequency-panel-group
								v-if="contentTab == 'sla-frequency'"
								:item="item"
								idField="pmareaid"
								nameField="pmareaname"
								:teoricDeliveryDevices="teoricDeliveryDevices"
								:sectionsDevices="sectionsDevices"
							></frequency-panel-group>
							<kpi-panel-group
								v-else
								:item="item"
								:sectionsGroup="sectionsAreas"
								:totalsGroup="totalsAreas"
								idField="pmareaid"
								nameField="pmareaname"
								:sectionsDevices="sectionsDevices"
								:totalsDevices="totalsDevices"
								:teoricSlaDevices="teoricSlaDevices"
							></kpi-panel-group>
						</v-list>
					</v-list-group>
				</v-list>
			</v-container>
			<v-container v-else-if="type == 'datasource' && loadedDatasourceOrg" class="d-flex flex-column pa-0 mt-2">
				<v-list v-for="(org, i) in datasourcesByOrganization" :key="i" flat dense style="background: transparent" class="pt-0">
					<v-list-group v-model="isOpenedOrg[i]" no-action :ripple="false" append-icon="">
						<template #prependIcon>
							<v-icon size="14" :class="{ rotated: isOpenedOrg[i] }" class="color-high">
								far
								{{ isOpenedOrg[i] ? 'fa-minus' : 'fa-plus' }}
							</v-icon>
						</template>
						<template #activator>
							<kpi-organization-activator
								:orgItem="org"
								:sectionsOrgs="sectionsOrgs"
								:totalsOrg="totalsOrg"
							></kpi-organization-activator>
						</template>
						<v-list
							v-for="(item, i) in devicesByOrgDatasource[org.pmorganizationid]"
							:key="i"
							flat
							dense
							style="background: transparent"
							class="pt-0"
						>
							<frequency-panel-group
								v-if="contentTab == 'sla-frequency'"
								:item="item"
								idField="pmdatasourcetypeid"
								nameField="pmdatasourcetypename"
								:teoricDeliveryDevices="teoricDeliveryDevices"
								:sectionsDevices="sectionsDevices"
							></frequency-panel-group>
							<kpi-panel-group
								v-else
								:item="item"
								:sectionsGroup="sectionsDatasources"
								:totalsGroup="totalsDatasources"
								idField="pmdatasourcetypeid"
								nameField="pmdatasourcetypename"
								:sectionsDevices="sectionsDevices"
								:totalsDevices="totalsDevices"
								:teoricSlaDevices="teoricSlaDevices"
							></kpi-panel-group>
						</v-list>
					</v-list-group>
				</v-list>
			</v-container>
		</div>
	</div>
</template>

<script>
import KpiOrganizationActivator from './KpiOrganizationActivator.vue';
import KpiPanelGroup from './KpiPanelGroup.vue';
import FrequencyPanelGroup from './FrequencyPanelGroup.vue';
import { search, isSuperAdmin, formatTimestamp, sortKey } from '@/api/common';

import constants from '@/utils/constants';
//import debounce from 'debounce';

export default {
	components: {
		KpiOrganizationActivator,
		KpiPanelGroup,
		FrequencyPanelGroup
	},
	props: {
		type: {
			type: String,
			required: true
		}
	},
	data() {
		return {
			isSuperAdmin: false,
			disabled: false,
			primaryColor: this.$store.state.global.primaryColor,
			loadedOrg: false,
			loadedArea: false,
			loadedDatasource: false,
			loadedAreaOrg: false,
			loadedDatasourceOrg: false,
			rotated: true,
			slaDate: null,
			devicesThreshold: constants.SLA_DEVICES_THRESHOLD,
			/* Organization */
			isOpenedOrg: {},
			totalsOrg: {},
			sectionsOrgs: {},
			areasByOrganization: null,
			datasourcesByOrganization: null,
			devicesByOrgArea: {},
			devicesByOrgDatasource: {},
			/* Area */
			rotatedArea: {},
			devicesByArea: null,
			slaByArea: null,
			slaByAreaAndTimestamp: {},
			sectionsAreas: {},
			totalsAreas: {},
			filterAreaSLA: null,
			emptyAreas: true,
			/* Datasource */
			rotatedDatasource: {},
			devicesByDatasource: null,
			slaByDatasource: null,
			slaByDatasourceAndTimestamp: {},
			sectionsDatasources: {},
			totalsDatasources: {},
			filterDatasourceSLA: null,
			/* Device */
			sectionsDevices: {},
			totalsDevices: {},
			teoricSlaDevices: {},
			teoricDeliveryDevices: {}
		};
	},
	computed: {
		isDevices() {
			return Object.keys(this.devices).length > 0;
		},

		devices() {
			if (this.$store.getters.getDevicesData) return this.$store.getters.getDevicesData;
			return [];
		},
		filteredDevicesByArea() {
			if (this.filterAreaSLA) {
				let filtered = [];
				this.devicesByArea.forEach((group) => {
					let devicesFiltered = group.group.filter((device) => !!device.code.toLowerCase().includes(this.filterAreaSLA.toLowerCase()));
					if (devicesFiltered.length > 0) {
						filtered.push({
							pmareaid: group.pmareaid,
							pmareaname: group.pmareaname,
							group: devicesFiltered
						});
					}
				});
				return filtered;
			}
			return this.devicesByArea;
		},
		filteredDevicesByDatasource() {
			if (this.filterDatasourceSLA) {
				let filtered = [];
				this.devicesByDatasource.forEach((group) => {
					let devicesFiltered = group.group.filter(
						(device) => !!device.code.toLowerCase().includes(this.filterDatasourceSLA.toLowerCase())
					);
					if (devicesFiltered.length > 0) {
						filtered.push({
							pmdatasourcetypeid: group.pmdatasourcetypeid,
							pmdatasourcetypename: group.pmdatasourcetypename,
							group: devicesFiltered
						});
					}
				});
				return filtered;
			}
			return this.devicesByDatasource;
		},
		filteredAreas() {
			if (this.$store.getters.getAreas && this.$store.getters.getAreas.length > 0) {
				return this.$store.getters.getAreas.filter((area) => area.activestations > 0);
			}
			return [];
		},
		filteredDatasources() {
			if (this.$store.getters.getDatasourcetype && this.$store.getters.getDatasourcetype.length > 0) {
				return this.$store.getters.getDatasourcetype.filter((datasource) => datasource.activestations > 0);
			}
			return [];
		},
		slaDevices: {
			get() {
				if (this.$store.getters.getSlaDevices) return this.$store.getters.getSlaDevices;
				return [];
			},
			set(value) {
				this.$store.commit('setSlaDevices', value);
			}
		},
		contentTab() {
			return this.$store.getters.getContentTab;
		}
	},
	watch: {},
	created() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
	},
	beforeMount() {
		if (!this.slaDevices) {
			this.storeSlaData().then((result) => {
				if (!this.isSuperAdmin) this.afterGetData(result);
				else this.afterGetDataSP(result);
			});
		} else {
			if (!this.isSuperAdmin) this.afterGetData(this.slaDevices);
			else this.afterGetDataSP(this.slaDevices);
		}
	},
	mounted() {
		//this.$puiEvents.$on('update:dashboard-height', this.adjustDashboardHeight);
	},
	updated() {},
	methods: {
		// GROUPING
		groupByOrganization(dataset) {
			let sortedDataset = dataset;
			if (dataset[0].code) {
				sortedDataset = dataset.sort((a, b) => {
					const [textA, numA] = sortKey(a.code);
					const [textB, numB] = sortKey(b.code);

					// First, compare the text parts
					if (textA < textB) return -1;
					if (textA > textB) return 1;

					// If the text parts are equal, compare the numeric parts
					return numA - numB;
				});
			}

			const object = sortedDataset.reduce((r, { organizationname, pmorganizationid, ...rest }) => {
				if (!r[pmorganizationid]) r[pmorganizationid] = { organizationname, pmorganizationid, group: [rest] };
				else r[pmorganizationid].group.push(rest);
				return r;
			}, {});

			const result = Object.values(object).sort((a, b) => {
				const elementA = a.organizationname.toUpperCase();
				const elementB = b.organizationname.toUpperCase();
				return elementA.localeCompare(elementB);
			});
			return result;
		},
		// Area
		groupByArea(devicesDataset) {
			const sortedDataset = devicesDataset.sort((a, b) => {
				const [textA, numA] = sortKey(a.code);
				const [textB, numB] = sortKey(b.code);

				// First, compare the text parts
				if (textA < textB) return -1;
				if (textA > textB) return 1;

				// If the text parts are equal, compare the numeric parts
				return numA - numB;
			});
			const groupedDevices = sortedDataset.reduce((r, { pmareaname, pmareaid, ...rest }) => {
				if (!r[pmareaname]) {
					r[pmareaname] = { pmareaname, pmareaid, group: [rest] };
				} else {
					r[pmareaname].group.push(rest);
				}
				return r;
			}, {});

			return Object.values(groupedDevices).sort((a, b) => {
				const elementA = a.pmareaname.toUpperCase();
				const elementB = b.pmareaname.toUpperCase();
				return elementA.localeCompare(elementB);
			});
		},
		getSlaByArea(dataset) {
			const groupedSla = dataset.reduce((r, { pmareaid, ...rest }) => {
				if (!r[pmareaid]) {
					r[pmareaid] = { pmareaid, group: [rest] };
				} else {
					r[pmareaid].group.push(rest);
				}
				return r;
			}, {});
			return Object.values(groupedSla);
		},
		// Datasource
		groupByDatasource(devicesDataset) {
			const sortedDataset = devicesDataset.sort((a, b) => {
				const [textA, numA] = sortKey(a.code);
				const [textB, numB] = sortKey(b.code);

				// First, compare the text parts
				if (textA < textB) return -1;
				if (textA > textB) return 1;

				// If the text parts are equal, compare the numeric parts
				return numA - numB;
			});
			const groupedDevices = sortedDataset.reduce((r, { pmdatasourcetypename, pmdatasourcetypeid, ...rest }) => {
				if (!r[pmdatasourcetypename]) {
					r[pmdatasourcetypename] = { pmdatasourcetypename, pmdatasourcetypeid, group: [rest] };
				} else {
					r[pmdatasourcetypename].group.push(rest);
				}
				return r;
			}, {});

			return Object.values(groupedDevices).sort((a, b) => {
				const elementA = a.pmdatasourcetypename.toUpperCase();
				const elementB = b.pmdatasourcetypename.toUpperCase();
				return elementA.localeCompare(elementB);
			});
		},
		getSlaByDatasource(dataset) {
			const groupedSla = dataset.reduce((r, { pmdatasourcetypeid, ...rest }) => {
				if (!r[pmdatasourcetypeid]) {
					r[pmdatasourcetypeid] = { pmdatasourcetypeid, group: [rest] };
				} else {
					r[pmdatasourcetypeid].group.push(rest);
				}
				return r;
			}, {});
			return Object.values(groupedSla);
		},
		groupByTimestamp(dataset) {
			const grouped = dataset.reduce((r, { datetimestamp, ...rest }) => {
				if (!r[datetimestamp]) {
					r[datetimestamp] = {
						datevalue: formatTimestamp(datetimestamp, this.userProperties, true),
						datetimestamp,
						group: [rest]
					};
				} else {
					r[datetimestamp].group.push(rest);
				}
				return r;
			}, {});
			const result = Object.values(grouped).sort((a, b) => {
				const timestampA = a.datetimestamp;
				const timestampB = b.datetimestamp;
				if (timestampA > timestampB) {
					return -1;
				}
				if (timestampA < timestampB) {
					return 1;
				}
				return 0;
			});

			return result;
		},
		// CALCULATIONS
		async getOrgSections(id, type) {
			let orgDevices = this.devices.filter((device) => device.pmorganizationid == id);
			let orgSla = this.slaDevices.filter((sla) => sla.pmorganizationid == id);
			console.log('sla org ' + type, orgDevices, orgSla);
			let totalOk = 0;
			let totalError = 0;
			let sections = [];
			if (orgDevices.length > 0 && orgSla.length > 0) {
				orgDevices.forEach((device) => {
					let slaDevice = orgSla.find((sla) => sla.pmdatasourceinstanceid == device.pmdatasourceinstanceid);
					if (slaDevice && slaDevice.devicesla) {
						totalOk += slaDevice.docsok;
						totalError += slaDevice.docserror;
						//slaSum += slaDevice.devicesla;
						//deviceCount++;
					}
				});
				let percentOk = totalOk && totalError ? (totalOk / (totalOk + totalError)) * 100 : 0;
				this.totalsOrg[id] = percentOk;
				let percentError = (totalError / (totalOk + totalError)) * 100;
				sections.push(
					{
						value: totalOk,
						click: () => {},
						color: '#36BA61',
						title: 'Valid',
						percent: percentOk
					},
					{
						value: totalError,
						click: () => {},
						color: '#ED5E4A',
						title: 'Errors',
						percent: percentError
					}
				);
			}
			return sections;
		},
		async getGroupSections(id, type) {
			let groupSla = {};
			if (type == 'area' && this.slaByAreaAndTimestamp[id]) {
				groupSla = this.slaByAreaAndTimestamp[id].group[0];
			} else if (type == 'datasource' && this.slaByDatasourceAndTimestamp[id]) {
				groupSla = this.slaByDatasourceAndTimestamp[id].group[0];
			} else {
				return [];
			}
			this.slaDate = groupSla.datevalue;
			let totalOk = 0;
			//let totalWarning = 0;
			let totalError = 0;
			let sections = [];
			let slaSum = 0;
			let deviceCount = 0;
			groupSla.group.forEach((sla) => {
				if (sla) {
					totalOk += sla.docsok;
					//totalWarning += sla.docswarning;
					totalError += sla.docserror;
					slaSum += sla.devicesla;
					deviceCount++;
				}
			});
			let avgTeoricSla = slaSum / deviceCount;
			let percentOk = totalOk /* - totalWarning */ > 0 ? (totalOk /* - totalWarning */ / (totalOk + totalError)) * 100 : 0;
			//let percentWarning = totalWarning > 0 ? (totalWarning / totalOk) * 100 : 0;
			type == 'area'
				? (this.totalsAreas[id] = { percent: percentOk /* + percentWarning */, ok: totalOk /* + totalWarning */, error: totalError })
				: (this.totalsDatasources[id] = { percent: percentOk /* + percentWarning */, ok: totalOk /* + totalWarning */, error: totalError });
			let percentError = (totalError / (totalOk + totalError)) * 100;
			sections.push(
				{
					value: totalOk,
					click: () => {},
					color: '#36BA61',
					title: 'Valid',
					percent: percentOk
				},
				/* {
					value: totalWarning,
					click: () => {},
					color: '#FCD37F',
					title: 'Warning',
					percent: percentWarning
				}, */
				{
					value: totalError,
					click: () => {},
					color: '#ED5E4A',
					title: 'Errors',
					percent: percentError
				}
			);

			console.log('calc ' + type, avgTeoricSla, this.totalsAreas, this.totalsDatasources, sections);
			this.emptyAreas = false;
			return sections;
		},
		async getDeviceSections(deviceId) {
			let deviceSla = this.slaDevices.find((sla) => sla.pmdatasourceinstanceid == deviceId);
			let totalOk = 0;
			//let totalWarning = 0;
			let totalError = 0;
			let sections = [];
			let teoricSla = 0;
			if (deviceSla) {
				totalOk = deviceSla.docsok;
				//totalWarning = deviceSla.docswarning;
				totalError = deviceSla.docserror;
				teoricSla = deviceSla.devicesla;
			}
			if (totalOk + totalError > 0) {
				let percentOk = totalOk /* - totalWarning */ > 0 ? (totalOk /* - totalWarning */ / (totalOk + totalError)) * 100 : 0;
				//let percentWarning = totalWarning > 0 ? (totalWarning / totalOk) * 100 : 0;
				let percentError = (totalError / (totalOk + totalError)) * 100;
				this.totalsDevices[deviceId] = percentOk /* + percentWarning */;
				sections.push(
					{
						value: totalOk,
						click: () => {},
						color: '#36BA61',
						title: 'Valid',
						percent: percentOk
					},
					/* {
						value: totalWarning,
						click: () => {},
						color: '#FCD37F',
						title: 'Warning',
						percent: percentWarning
					}, */
					{
						value: totalError,
						click: () => {},
						color: '#ED5E4A',
						title: 'Errors',
						percent: percentError
					}
				);
			}

			return { sections, sla: teoricSla };
		},
		async getDeviceSectionsV2(device) {
			let deviceSla = this.slaDevices.find((sla) => sla.pmdatasourceinstanceid == device.pmdatasourceinstanceid);
			let totalOk = 0;
			//let totalWarning = 0;
			let totalError = 0;
			let sections = [];
			let teoricSla = device.sla;
			let teoricDelivery = -1;
			if (device.deliveryperiod) teoricDelivery = device.deliveryperiod + ' ' + device.unitname;
			if (deviceSla) {
				totalOk = deviceSla.docsok;
				//totalWarning = deviceSla.docswarning;
				totalError = deviceSla.docserror;
				teoricSla = deviceSla.devicesla;
			}
			if (totalOk + totalError > 0) {
				let percentOk = totalOk /* - totalWarning */ > 0 ? (totalOk /* - totalWarning */ / (totalOk + totalError)) * 100 : 0;
				//let percentWarning = totalWarning > 0 ? (totalWarning / totalOk) * 100 : 0;
				let percentError = (totalError / (totalOk + totalError)) * 100;
				this.totalsDevices[device.pmdatasourceinstanceid] = percentOk /* + percentWarning */;
				sections.push(
					{
						value: totalOk,
						click: () => {},
						color: '#36BA61',
						title: 'Valid',
						percent: percentOk
					},
					/* {
						value: totalWarning,
						click: () => {},
						color: '#FCD37F',
						title: 'Warning',
						percent: percentWarning
					}, */
					{
						value: totalError,
						click: () => {},
						color: '#ED5E4A',
						title: 'Errors',
						percent: percentError
					}
				);
			}

			return { sections, sla: teoricSla, delivery: teoricDelivery };
		},
		afterGetData(result) {
			let slaData = result;
			let areas = this.filteredAreas;
			let datasources = this.filteredDatasources;
			if (this.type == 'area' && areas.length > 0) {
				this.devicesByArea = this.groupByArea(this.devices);
				this.slaByArea = this.getSlaByArea(slaData);
				this.slaByArea.forEach((areaGroup) => {
					let area = areas.find((area) => area.pmareaid == areaGroup.pmareaid);
					if (area) {
						this.slaByAreaAndTimestamp[areaGroup.pmareaid] = {
							pmareaid: areaGroup.pmareaid,
							pmareaname: area.name,
							group: this.groupByTimestamp(areaGroup.group)
						};
					}
				});
				this.devicesByArea.forEach((areaGroup) => {
					this.getGroupSections(areaGroup.pmareaid, this.type).then((sections) => {
						this.sectionsAreas[areaGroup.pmareaid] = sections;
						this.loadedArea = true;
					});
					/* areaGroup.group.forEach((device) => {
							this.getDeviceSections(device.pmdatasourceinstanceid).then(({ sections, sla }) => {
								this.sectionsDevices[device.pmdatasourceinstanceid] = sections;
								this.teoricSlaDevices[device.pmdatasourceinstanceid] = sla;
							});
						}); */
				});
			} else if (this.type == 'datasource' && datasources.length > 0) {
				this.devicesByDatasource = this.groupByDatasource(this.devices);
				this.slaByDatasource = this.getSlaByDatasource(slaData);
				this.slaByDatasource.forEach((datasourceGroup) => {
					let datasource = datasources.find((datasource) => datasource.pmdatasourcetypeid == datasourceGroup.pmdatasourcetypeid);
					if (datasource) {
						this.slaByDatasourceAndTimestamp[datasourceGroup.pmdatasourcetypeid] = {
							pmdatasourcetypeid: datasourceGroup.pmdatasourcetypeid,
							pmdatasourcetypename: datasource.name,
							group: this.groupByTimestamp(datasourceGroup.group)
						};
					}
				});
				this.devicesByDatasource.forEach((datasourceGroup) => {
					this.getGroupSections(datasourceGroup.pmdatasourcetypeid, this.type).then((sections) => {
						this.sectionsDatasources[datasourceGroup.pmdatasourcetypeid] = sections;
						this.loadedDatasource = true;
					});
				});
			}

			this.devices.forEach((device) => {
				this.getDeviceSectionsV2(device).then(({ sections, sla, delivery }) => {
					this.sectionsDevices[device.pmdatasourceinstanceid] = sections;
					this.teoricSlaDevices[device.pmdatasourceinstanceid] = sla;
					this.teoricDeliveryDevices[device.pmdatasourceinstanceid] = delivery;
				});
			});
		},
		afterGetDataSP(result) {
			let slaData = result;
			let areas = this.filteredAreas;
			let datasources = this.filteredDatasources;

			this.areasByOrganization = this.groupByOrganization(areas);
			this.datasourcesByOrganization = this.groupByOrganization(datasources);
			let devicesByOrganization = this.groupByOrganization(this.devices);
			if (this.type == 'area' && areas.length > 0) {
				this.devicesByArea = this.groupByArea(this.devices);
				this.slaByArea = this.getSlaByArea(slaData);
				this.slaByArea.forEach((areaGroup) => {
					let area = areas.find((area) => area.pmareaid == areaGroup.pmareaid);
					if (area) {
						this.slaByAreaAndTimestamp[areaGroup.pmareaid] = {
							pmareaid: areaGroup.pmareaid,
							pmareaname: area.name,
							group: this.groupByTimestamp(areaGroup.group)
						};
					}
				});
				this.devicesByArea.forEach((areaGroup) => {
					this.getGroupSections(areaGroup.pmareaid, this.type).then((sections) => {
						this.sectionsAreas[areaGroup.pmareaid] = sections;
					});
				});

				this.loadedAreaOrg = true;
			} else if (this.type == 'datasource' && datasources.length > 0) {
				this.devicesByDatasource = this.groupByDatasource(this.devices);
				this.slaByDatasource = this.getSlaByDatasource(slaData);
				this.slaByDatasource.forEach((datasourceGroup) => {
					let datasource = datasources.find((datasource) => datasource.pmdatasourcetypeid == datasourceGroup.pmdatasourcetypeid);
					if (datasource) {
						this.slaByDatasourceAndTimestamp[datasourceGroup.pmdatasourcetypeid] = {
							pmdatasourcetypeid: datasourceGroup.pmdatasourcetypeid,
							pmdatasourcetypename: datasource.name,
							group: this.groupByTimestamp(datasourceGroup.group)
						};
					}
				});
				this.devicesByDatasource.forEach((datasourceGroup) => {
					this.getGroupSections(datasourceGroup.pmdatasourcetypeid, this.type).then((sections) => {
						this.sectionsDatasources[datasourceGroup.pmdatasourcetypeid] = sections;
					});
				});

				this.loadedDatasourceOrg = true;
			}

			Object.values(devicesByOrganization).forEach((orgGroup) => {
				this.devicesByOrgArea[orgGroup.pmorganizationid] = this.groupByArea(orgGroup.group);
				this.devicesByOrgDatasource[orgGroup.pmorganizationid] = this.groupByDatasource(orgGroup.group);
				this.getOrgSections(orgGroup.pmorganizationid, this.type).then((sections) => {
					this.sectionsOrgs[orgGroup.pmorganizationid] = sections;
					this.loadedOrg = true;
				});
			});

			this.devices.forEach((device) => {
				this.getDeviceSectionsV2(device).then(({ sections, sla, delivery }) => {
					this.sectionsDevices[device.pmdatasourceinstanceid] = sections;
					this.teoricSlaDevices[device.pmdatasourceinstanceid] = sla;
					this.teoricDeliveryDevices[device.pmdatasourceinstanceid] = delivery;
				});
			});
		},
		// MISC
		toggleExpand() {
			this.rotated = !this.rotated;
		},
		/* toggleExpandArea(i) {
			this.rotatedArea[i] = !this.rotatedArea[i];
		},
		toggleExpandDatasource(i) {
			this.rotatedDatasource[i] = !this.rotatedDatasource[i];
		}, */
		async storeSlaData() {
			let filterUser = {};
			if (!this.isSuperAdmin) {
				filterUser = {
					groups: [],
					groupOp: 'and',
					rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
				};
			}
			let body = {
				model: 'pmslacalculations',
				rows: 1000,
				filter: filterUser
			};

			let { data } = await search(body);
			this.slaDevices = data.data;
			return data.data;
		}
		/* adjustDashboardHeight() {
			let constantHeight = 235;
			document.documentElement.style.setProperty('--dashboard-panel-diff', constantHeight + 'px');
			if (document.getElementById('sla-datasource-info')) {
				let configHeight = document.getElementById('dashboard-config').clientHeight + constantHeight;
				document.documentElement.style.setProperty('--dashboard-panel-diff', configHeight + 'px');
			}
			if (document.getElementById('sla-datasource-info')) {
				let configHeight = document.getElementById('dashboard-config').clientHeight + constantHeight;
				document.documentElement.style.setProperty('--dashboard-panel-diff', configHeight + 'px');
			}
		} */
	}
};
</script>

<style lang="postcss">
.dashboard-panel-info {
	& #sla-area-info {
		overflow-y: scroll;
		//max-height: calc(100vh - var(--dashboard-panel-diff));
		//max-height: calc(100vh - 250px);
	}

	& #sla-datasource-info {
		overflow-y: scroll;
		//max-height: calc(100vh - var(--dashboard-panel-diff));
		//max-height: calc(100vh - 250px);
	}
}

.dashboard-panel-kpis {
	& .icon {
		transform-origin: center center;
	}

	& .rotated {
		transform: rotate(-180deg);
	}

	& .header-title {
		transition: color 0.2s ease-in !important;
		cursor: pointer;
	}

	& .disabled {
		color: var(--moderate);
	}

	& .sub-kpi {
		padding-left: 48px !important;
	}

	& .switchoff {
		color: var(--gris-300);

		& .switchoff_icon {
			font-size: 2rem;
		}

		& .switchoff-text {
			font-size: 1rem;
		}
	}
}
</style>

<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmparameter.title')">
					<v-row no-gutters>
						<v-col cols="12" md="4">
							<pui-text-field :label="$t('pmparameter.name')" v-model="model.name" toplabel required maxlength="150"></pui-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<pui-text-field
								v-model="model.acronym"
								:label="$t('pmparameter.acronym')"
								maxlength="10"
								required
								toplabel
							></pui-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<pui-select
								attach="pmunitid"
								:label="this.$t('pmparameter.unit')"
								:placeholder="this.$t('pmparameter.phunit')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmunit"
								:modelFormMapping="{ pmunitid: 'pmunitid' }"
								:itemsToSelect="unitItemsToSelect"
								itemValue="pmunitid"
								itemText="name"
							></pui-select>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col cols="12" md="3" v-if="isSuperAdmin">
							<pui-select
								attach="pmorganizationid"
								:label="this.$t('pmparameter.organization')"
								:placeholder="this.$t('pmparameter.phorganization')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmorganization"
								:modelFormMapping="{ pmorganizationid: 'pmorganizationid' }"
								:itemsToSelect="organizationItemsToSelect"
								itemValue="pmorganizationid"
								itemText="name"
							></pui-select>
						</v-col>
						<v-col cols="12" md="4" v-else>
							<pui-text-field
								:label="$t('pmparameter.organization')"
								toplabel
								disabled
								required
								v-model="organizationName"
							></pui-text-field>
						</v-col>
						<v-col cols="12" md="3" v-if="isSuperAdmin">
							<pui-text-field
								v-model="model.elasticacronym"
								:label="$t('pmparameter.elasticacronym')"
								maxlength="30"
								toplabel
							></pui-text-field>
						</v-col>
						<v-col cols="12" :md="isSuperAdmin ? '3' : '4'">
							<pui-select
								attach="pmvisualizationsid"
								:label="this.$t('pmparameter.chartname')"
								:placeholder="this.$t('pmparameter.phchartname')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmvisualizations"
								:modelFormMapping="{ pmvisualizationsid: 'pmvisualizationsid' }"
								:itemsToSelect="visualizationItemsToSelect"
								itemValue="pmvisualizationsid"
								itemText="visualizationname"
							></pui-select>
						</v-col>
						<v-col cols="12" :md="isSuperAdmin ? '3' : '4'">
							<pui-select
								attach="mobilevisualizationid"
								:label="this.$t('pmparameter.mobilechartname')"
								:placeholder="this.$t('pmparameter.phchartname')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmvisualizations"
								:modelFormMapping="{ pmvisualizationsid: 'mobilevisualizationid' }"
								:itemsToSelect="visualizationItemsToSelect2"
								itemValue="pmvisualizationsid"
								itemText="visualizationname"
								:fixedFilter="mobileFilter"
							></pui-select>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col cols="12">
							<pui-text-area
								v-model="model.description"
								:label="$t('pmparameter.description')"
								maxlength="250"
								toplabel
							></pui-text-area>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col cols="12" md="3">
							<pui-checkbox
								:label="$t('pmparameter.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-col>
						<v-col cols="12" md="3">
							<pui-checkbox
								:label="$t('pmparameter.visible')"
								v-model="model.visible"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-col>
						<v-col cols="12" md="3">
							<pui-checkbox
								:label="$t('pmparameter.showstatusname')"
								v-model="model.showstatusname"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-col>
						<v-col cols="12" md="3" v-if="isSuperAdmin">
							<pui-checkbox
								:label="$t('pmparameter.iscalculatedparameter')"
								v-model="model.iscalculatedparameter"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-col>
					</v-row>
					<pui-field-set :title="$t('pmparameter.dataquality')">
						<v-row no-gutters>
							<v-col cols="12" md="6">
								<pui-number-field :label="$t('pmparameter.deliveryperiod')" v-model="model.deliveryperiod" toplabel required min="0">
								</pui-number-field>
							</v-col>
							<v-col cols="12" md="6">
								<pui-select
									attach="pmtimeunitid"
									:label="this.$t('pmparameter.timeunit')"
									toplabel
									clearable
									required
									:disabled="formDisabled"
									v-model="model"
									modelName="vluppmtimeunit"
									:modelFormMapping="{ pmtimeunitid: 'pmtimeunitid' }"
									:itemsToSelect="timeunitItemsToSelect"
									itemValue="pmtimeunitid"
									itemText="name"
								></pui-select>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="12" md="4">
								<pui-number-field :label="$t('pmparameter.thresholdmin')" v-model="model.thresholdminvalue" toplabel decimals="3">
								</pui-number-field>
							</v-col>
							<v-col cols="12" md="4">
								<pui-number-field :label="$t('pmparameter.thresholdmax')" v-model="model.thresholdmaxvalue" toplabel decimals="3">
								</pui-number-field>
							</v-col>
							<v-col cols="12" md="4">
								<pui-text-field
									v-model="model.errorcodes"
									:label="$t('pmparameter.errorcodes')"
									maxlength="150"
									toplabel
									:tooltipDescription="$t('pmparameter.errorcodestooltip')"
								></pui-text-field>
							</v-col>
						</v-row>
					</pui-field-set>
					<pui-field-set :title="$t('pmparameter.calculations')" v-if="isSuperAdmin && model.iscalculatedparameter == 1">
						<v-row no-gutters>
							<v-col cols="12" md="6">
								<pui-text-field
									v-model="model.methodtoinvoke"
									:label="$t('pmparameter.methodtoinvoke')"
									maxlength="100"
									toplabel
									:required="model.iscalculatedparameter == 1"
								></pui-text-field>
							</v-col>
						</v-row>
					</pui-field-set>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

// eslint-disable-next-line no-unused-vars
import { search, isSuperAdmin } from '../../api/common';

export default {
	name: 'pmparameterform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmparameter',
			model: 'pmparameter',
			isSuperAdmin: false,
			mobileFilter: null
		};
	},
	watch: {
		'model.pmvisualizationsid': function (newValue, oldValue) {
			console.log('value changed from ' + oldValue + ' to ' + newValue);
		}
	},
	computed: {
		unitItemsToSelect() {
			return [{ pmunitid: this.model.pmunitid }];
		},
		timeunitItemsToSelect() {
			return [{ pmtimeunitid: this.model.pmtimeunitid }];
		},
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		},
		visualizationItemsToSelect() {
			return [{ pmvisualizationsid: this.model.pmvisualizationsid }];
		},
		visualizationItemsToSelect2() {
			return [{ pmvisualizationsid: this.model.mobilevisualizationid }];
		},
		organizationName() {
			return this.userProperties['organizationname'];
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		this.mobileFilter = {
			groups: [],
			groupOp: 'and',
			rules: [{ field: 'isdynamic', op: 'eq', data: 1 }]
		};
	},
	updated() {
		if (this.model) {
			console.log('param model id: ' + this.model.pmorganizationid);
			if (!this.modelLoaded || !this.model.pmorganizationid) {
				this.modelLoaded = true;

				this.model.pmorganizationid = this.userProperties['organizationid'];
			}
		}
	},
	methods: {}
};
</script>

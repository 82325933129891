<template>
	<kpi-panel :items="items_byDatasourcetype" :total-viewport="totalViewport_byDatasourcetype">
		<template slot-scope="{ item: { id: itemId, total: itemTotal, bars }, percents }">
			<component
				:is="bars ? 'kpi-expanded-header-datasourcetype' : 'kpi-header'"
				v-bind="{
					total: itemTotal,
					title: titles_byDatasourcetype[itemId],
					disabled: isDisabled_byDatasourcetype[itemId],
					showClose: closeButtons_byDatasourcetype[itemId]
				}"
				v-on:click:close="clearFilter_byDatasourcetype"
				v-model="expanded[itemId]"
			>
				<template v-if="!bars" #default>
					<kpi-bar-item
						:value="itemTotal"
						@click="() => filterMethod_byDatasourcetype[itemId]()"
						:colorClass="colors_byDatasourcetype[itemId]"
						:disabled="isDisabled_byDatasourcetype[itemId]"
						:tip-text="titles_byDatasourcetype[itemId]"
						:percent="percents[itemId]"
					/>
				</template>

				<template v-else #default>
					<kpi-bar-item
						v-for="{ id: barId, total: barTotal } in bars"
						:key="barId"
						:value="barTotal"
						@click="() => filterMethod_byDatasourcetype[barId]()"
						:colorClass="colors_byDatasourcetype[barId]"
						:disabled="isDisabled_byDatasourcetype[barId]"
						:tip-text="titles_byDatasourcetype[barId]"
						:percent="percents[barId]"
					/>
				</template>

				<template #expand>
					<div v-if="expanded[itemId]" class="mt-2">
						<kpi-detail-item
							v-for="{ id: barId, total: barTotal } in bars"
							:key="barId"
							:total="barTotal"
							@click="() => filterMethod_byDatasourcetype[barId]()"
							:colorClass="colors_byDatasourcetype[barId]"
							:disabled="isDisabled_byDatasourcetype[barId]"
							:title="titles_byDatasourcetype[barId]"
							:show-exclamation="exclamations_byDatasourcetype[barId]"
						/>
					</div>
				</template>
			</component>
		</template>
	</kpi-panel>
</template>

<script>
import KpiPanel from '../kpis-common/KpiPanel';
import KpiHeader from '../kpis-common/KpiHeader';
import KpiExpandedHeaderDatasourcetype from '../kpis-common/KpiExpandedHeaderDatasourcetype';
import KpiBarItem from '../kpis-common/KpiBarItem';
import KpiDetailItem from '../kpis-common/KpiDetailItem';

import NotificationsKpiMixin from '@/mixins/NotificationsKpiMixin';

export default {
	name: 'notificationskpipanel_datasourcetype',

	mixins: [NotificationsKpiMixin],

	components: {
		KpiPanel,
		KpiHeader,
		KpiExpandedHeaderDatasourcetype,
		KpiBarItem,
		KpiDetailItem
	},
	mounted() {},
	methods: {}
};
</script>

<template>
	<div>
		<v-tooltip top color="var(--gris-secondary)" transition="none">
			<template v-slot:activator="{ on, attrs }">
				<v-btn text class="btnzoom pa-0" @click="zoom--" v-bind="attrs" v-on="on">
					<v-icon class="fw-500" size="16">fak fa-line-less-zoom</v-icon>
				</v-btn>
			</template>
			<span class="fs-regular">{{ $t('map.zoomout') }}</span>
		</v-tooltip>

		<v-tooltip top color="var(--gris-secondary)" transition="none">
			<template v-slot:activator="{ on, attrs }">
				<v-btn text :ripple="false" class="btnlevel fs-regular fw-500 pa-0" v-bind="attrs" v-on="on">{{ zoom }}</v-btn>
			</template>
			<span class="fs-regular">{{ $t('map.currentzoom') }}</span>
		</v-tooltip>

		<!-- <map-zoom-reset id="map-zoom-reset" /> -->
		<v-tooltip top color="var(--gris-secondary)" transition="none">
			<template v-slot:activator="{ on, attrs }">
				<v-btn text class="btnzoom pa-0" @click="zoom++" v-bind="attrs" v-on="on">
					<v-icon class="fw-500" size="16">fak fa-line-more-zoom</v-icon>
				</v-btn>
			</template>
			<span class="fs-regular">{{ $t('map.zoomin') }}</span>
		</v-tooltip>
	</div>
</template>

<script>
import ol2map from './ol2map';
import { MIN_ZOOM, MAX_ZOOM, INITIAL_ZOOM } from '@/components/map/sections/map/subcomponents/mapVariables';
//import constants from '@/utils/constants';
//import MapZoomReset from '@/components/map/sections/map/subcomponents/MapZoomReset';

//import MapMeasureTool from '@/components/map/sections/map/subcomponents/MapMeasureTool';

export default {
	name: 'MapZoomsControlMap',
	data() {
		return {
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},

	components: {
		//MapZoomReset
		//MapMeasureTool
	},
	computed: {
		zoom: {
			get() {
				if (!this.$store.getters.zoom) {
					return INITIAL_ZOOM;
				}

				return this.$store.getters.zoom | 0; // El zero es para devolver enteros.
			},
			set(value) {
				if (value > MAX_ZOOM || value < MIN_ZOOM) {
					return;
				}

				if (ol2map.map) {
					ol2map.setZoom(value);
					this.$store.commit('setMapZoom', value);
				}
			}
		}
	},
	watch: {},
	methods: {}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';
.maptools {
	& .btnzoom {
		margin: 0px;
		width: 32px;
		min-width: 32px !important;
		height: 32px;
		background-color: var(--negative);
	}
	& .btnlevel {
		margin: 0px;
		width: 34px;
		min-width: 34px !important;
		height: 32px;
		background-color: var(--negative);
		cursor: auto !important;
		font-family: var(--font-family-primary);

		&:hover {
			background: var(--negative) !important;
		}
		&:focus {
			background: var(--negative) !important;
		}
	}
}
</style>

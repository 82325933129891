<template>
	<v-tooltip right color="var(--gris-secondary)">
		<template v-slot:activator="{ on }">
			<div class="sectionbar" :style="{ width: percent + '% !important', background: colorClass }" @click="$listeners.click" v-on="on" />
			<!-- :class="{ disabled /* , [colorClass]: true */ }" -->
		</template>
		<span class="tooltip d-flex align-center rounded elevation-2">
			<div class="tipicon pr-3">
				<div :style="'background: ' + colorClass"></div>
				<!-- :class="{ [colorClass]: true }" -->
			</div>
			<div class="tiptext flex-grow-1">{{ tipText }}</div>
			<div class="pl-5">
				<strong>{{ value }}</strong>
			</div>
		</span>
	</v-tooltip>
</template>

<script>
export default {
	name: 'KpiBarItem',

	props: {
		value: {
			type: Number,
			required: true
		},

		disabled: {
			type: Boolean,
			default: false
		},

		colorClass: {
			type: String,
			required: true
		},

		tipText: {
			type: String,
			required: true
		},

		percent: {
			type: Number,
			required: true
		}
	},
	data() {
		return {
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.sectionbar {
	float: left;
	height: 6px;
	padding: 0px;
	margin: 0px;
	transition: width 0.3s ease;

	&:hover {
		cursor: pointer;
		border-left: 1px solid var(--negative) !important;
		border-right: 1px solid var(--negative) !important;
		filter: brightness(120%);
	}
	&.disabled {
		background: var(--moderate) !important;
		border-left: 0px solid var(--moderate) !important;
		&:hover {
			background: var(--moderate) !important;
			border-left: 0px solid var(--moderate) !important;
		}
	}
	&.green {
		background: var(--status-ok);
	}
	&.orange {
		background: var(--status-warning);
	}
	&.red {
		background: var(--status-alerm);
	}
}

.tooltip {
	* {
		font-size: 0.9rem;
	}

	& .tipicon {
		* {
			width: 10px !important;
			height: 10px !important;
			border-radius: 10px;
		}

		& .green {
			background: var(--status-ok);
		}
		& .orange {
			background: var(--status-warning);
		}
		& .red {
			background: var(--status-alerm);
		}
	}
}
</style>

<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row wrap class="pui-form-layout">
				<pui-field-set :title="$t('pmseverity.title')">
					<v-layout wrap>
						<v-flex xs12 md7 lg8 xl9>
							<v-layout wrap>
								<v-flex xs12 md11 lg6>
									<pui-text-field
										:label="$t('pmseverity.name')"
										v-model="model.name"
										required
										toplabel
										maxlength="150"
									></pui-text-field>
								</v-flex>
								<v-flex xs12 md11 lg5>
									<pui-text-field
										:label="$t('pmseverity.acronym')"
										v-model="model.acronym"
										required
										toplabel
										maxlength="10"
									></pui-text-field>
								</v-flex>
								<v-flex xs12 md11 lg6>
									<div class="label_container">
										<label class="fixed_label">
											{{ this.$t('pmseverity.icon') }}
											<span style="color: var(--R-200)">*</span>
										</label>
									</div>
									<v-select
										v-model="iconSelected"
										:items="items"
										:placeholder="this.$t('pmseverity.phicon')"
										clearable
										:disabled="formDisabled"
										rounded
										:rules="[rules.required]"
										persistent-placeholder
										variant="outlined"
										class="pt-5 px-1 mt-auto"
									>
										<template v-slot:selection="{ item }">
											<div :style="itemStyle(item)"></div>
											{{ item.name }}
										</template>
										<template v-slot:item="{ item }">
											<div :style="itemStyle(item)"></div>
											{{ item.name }}
										</template>
									</v-select>
								</v-flex>
								<v-flex xs12 md11 lg5>
									<pui-number-field :label="$t('pmseverity.priority')" v-model="model.priority" min="0" toplabel></pui-number-field>
								</v-flex>
								<v-flex xs12 md11>
									<pui-text-area
										v-model="model.description"
										:label="$t('pmseverity.description')"
										maxlength="250"
										toplabel
									></pui-text-area>
								</v-flex>
							</v-layout>
						</v-flex>
						<v-flex xs12 md4 lg4 xl3>
							<pui-field-set :title="$t('pmseverity.color')" class="d-flex justify-center align-center">
								<v-color-picker
									v-model="model.color"
									dot-size="15"
									hide-mode-switch
									mode="hexa"
									elevation="0"
									canvas-height="50"
									:label="$t('pmseverity.color')"
									class="my-2"
									required
								></v-color-picker>
							</pui-field-set>
						</v-flex>
					</v-layout>
					<v-layout wrap>
						<v-flex xs12 md4>
							<pui-checkbox
								:label="$t('pmseverity.disabled')"
								v-model="model.disabled"
								true-value="1"
								false-value="0"
								style="margin-top: -1em !important"
							></pui-checkbox>
						</v-flex>
					</v-layout>
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="checkColor"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import { getIconsType } from '@/api/common';
import constants from '@/utils/constants';

export default {
	name: 'pmseverityform',
	mixins: [PuiFormMethodsMixin],

	data() {
		return {
			modelName: 'pmseverity',
			model: 'pmseverity',
			items: [],
			iconSelected: null
		};
	},
	watch: {
		'model.color': {
			handler(newValue) {
				//console.log('debug color', oldValue, newValue);
				if (newValue && typeof newValue == 'object') {
					this.model.color = this.model.color.hex;
				}
			}
		},
		iconSelected: {
			handler() {
				if (this.isSuperAdmin) {
					this.model.pmiconid == this.iconSelected ? this.iconSelected.pmiconsid : null;
				}
			}
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	mounted() {
		this.items = getIconsType(constants.NOTIFICATIONS_ICONS_TYPE, this.$store.getters.getIcons);
		/* .concat(getIconsType(constants.DEFAULT_DTS_ICONS_TYPE, this.$store.getters.getIcons)) */
		this.items = this.items.sort((a, b) => {
			if (a.name < b.name) return -1;
			if (a.name > b.name) return 1;
			return 0;
		});
	},
	beforeUpdate() {
		if (this.isSuperAdmin) {
			this.iconSelected = this.items.find((element) => element.pmiconsid == this.model.pmiconid);
		} else {
			this.iconSelected = this.items.find(
				(element) => element.pmiconsid == getIconsType(constants.ACTIONS_ICONS_TYPE, this.$store.getters.getIcons)[0].pmiconsid
			);
		}
		//console.log(this.model.pmiconid);
	},

	methods: {
		checkColor() {
			if (this.model.color.length == 9) {
				this.model.color = this.model.color.substring(0, 7);
			}
			this.save();
		},
		itemStyle(item) {
			var urlBaseIcons = this.$store.getters.getUrlBaseIcons;
			//console.log('dev hlp', urlBaseIcons + item.route);
			return 'content: url(' + urlBaseIcons + item.route.trim() + '); margin-right: 1rem; transform: scale(0.7); width: 38px';
		}
	}
};
</script>
<style scoped>
.label_container {
	position: relative;
}

.fixed_label {
	position: absolute !important;
	top: auto !important;
	left: 5px !important;
	color: rgba(0, 0, 0, 0.87);
}
</style>

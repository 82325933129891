<template>
	<div class="pui-form">
		<v-form @submit.prevent class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation v-if="modelLoaded">
			<div row no-gutters class="pui-form-layout">
				<pui-field-set :title="$t('pmrules.title')">
					<v-row no-gutters>
						<v-col cols="12" md="6">
							<pui-text-field :label="$t('pmrules.name')" v-model="model.name" required toplabel maxlength="150"></pui-text-field>
						</v-col>
						<v-col cols="12" md="6">
							<pui-text-field
								:label="$t('pmrules.acronym')"
								v-model="model.acronym"
								required
								toplabel
								:disabled="disabledAcronym"
								maxlength="30"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row no-gutters
						><v-col cols="12" md="4">
							<pui-select
								v-if="isSuperAdmin"
								attach="pmorganizationid"
								:label="this.$t('pmrules.organization')"
								:placeholder="this.$t('pmrules.phorganization')"
								toplabel
								clearable
								required
								:disabled="formDisabled"
								v-model="model"
								modelName="vluppmorganization"
								:modelFormMapping="{ pmorganizationid: 'pmorganizationid' }"
								:itemsToSelect="organizationItemsToSelect"
								itemValue="pmorganizationid"
								itemText="name"
							></pui-select>
							<pui-text-field
								v-else
								:label="$t('pmrules.organization')"
								toplabel
								disabled
								required
								v-model="organizationName"
							></pui-text-field>
						</v-col>
						<v-col cols="12" md="4">
							<pui-select
								attach="pmdatasourcetypeid"
								:label="this.$t('pmrules.pmdatasourcetype')"
								:placeholder="this.$t('pmrules.phdatasourcetype')"
								toplabel
								clearable
								required
								v-model="model"
								modelName="vluppmdatasourcetype"
								:modelFormMapping="{ pmdatasourcetypeid: 'pmdatasourcetypeid' }"
								itemValue="pmdatasourcetypeid"
								itemText="name"
								:itemsToSelect="datasourcetypeItemsToSelect"
								:fixedFilter="pmdatasourcetypeFixedFilter"
								:disabled="hasOrganization"
							></pui-select>
						</v-col>
						<v-col cols="12" md="4">
							<pui-text-field
								:label="$t('pmrules.elasticindex')"
								v-model="model.elasticindex"
								required
								toplabel
								maxlength="100"
								:disabled="elasticIndexDisabled"
							></pui-text-field>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col cols="12">
							<pui-field-set :title="$t('pmrules.pmdatasourceinstance')">
								<v-row no-gutters>
									<v-col cols="12" md="4">
										<pui-checkbox
											:label="$t('pmrules.applytoall')"
											v-model="model.applytoall"
											true-value="1"
											false-value="0"
											:disabled="hasDatasourcetype"
										></pui-checkbox>
									</v-col>
								</v-row>
								<v-row no-gutters>
									<v-col cols="12">
										<pui-multi-select
											id="multiSelectDatasourceinstance"
											itemValue="pmdatasourceinstanceid"
											itemText="code"
											v-model="model.datasourceinstances"
											:items="allPmDatasourceinstances"
											:itemsToSelect="model.datasourceinstances"
											:disabled="devicesDisabled"
										></pui-multi-select>
									</v-col>
								</v-row>
							</pui-field-set>
						</v-col>
					</v-row>
					<v-row no-gutters>
						<v-col cols="12">
							<pui-text-area v-model="model.description" :label="$t('pmrules.description')" maxlength="250" toplabel></pui-text-area>
						</v-col>
					</v-row>
					<pui-field-set :title="$t('pmrules.config')">
						<v-row no-gutters>
							<v-col cols="12" class="mb-2">
								<!-- Tabs Headers -->
								<v-tabs v-model="tab" height="30" color="blue" background-color="rgba(0, 0, 0, 0)" show-arrows>
									<v-tab ref="filter-tab" href="#filter-tab">{{ $t('pmrules.filter-tab') }}</v-tab>
									<v-tab ref="query-tab" href="#query-tab">{{ $t('pmrules.query-tab') }}</v-tab>
								</v-tabs>

								<!-- Tabs Content -->
								<v-tabs-items v-model="tab" style="overflow-y: auto; background: rgba(0, 0, 0, 0) !important">
									<v-tab-item value="filter-tab">
										<pui-field-set :title="$t('form.common.parameterCondition')">
											<rules-form-filter
												ref="rulesFormFilter"
												:filterProp="filterData"
												:columns="columns"
												:disabled="columnsDisabled"
												:maxFilters="maxFilters"
												:showSubgroupBtn="false"
												@change="checkFilter"
												@mustUpdateKibana="
													(value) => {
														mustUpdateKibana = value;
													}
												"
											></rules-form-filter>
										</pui-field-set>
									</v-tab-item>
									<v-tab-item value="query-tab">
										<v-col sm="12">
											<vue-json-editor
												v-model="model.elasticquery"
												:options="options"
												:plus="false"
												height="350px"
												@error="onErrorQuery"
											></vue-json-editor>
										</v-col>
									</v-tab-item>
								</v-tabs-items>
							</v-col>
						</v-row>
						<v-row no-gutters>
							<v-col cols="12" md="3">
								<pui-number-field
									v-model="model.windowrule"
									:label="$t('pmrules.window')"
									min="1"
									decimals="2"
									required
									toplabel
								></pui-number-field>
								<!-- :disabled="!aggsOperator" -->
							</v-col>
							<v-col cols="12" md="3">
								<pui-select
									attach="pmwindowtimeunitid"
									:label="this.$t('pmrules.windowtimeunit')"
									toplabel
									clearable
									v-model="model"
									modelName="vluppmtimeunit"
									:modelFormMapping="{ pmtimeunitid: 'pmwindowtimeunitid' }"
									:itemsToSelect="timeunitItemsToSelect"
									itemValue="pmtimeunitid"
									:itemText="(item) => `${item.name} (${item.acronym})`"
									required
								></pui-select>
								<!-- :disabled="!aggsOperator" -->
							</v-col>
							<v-col cols="12" md="3">
								<pui-number-field
									v-model="model.frequency"
									:label="$t('pmrules.frequency')"
									min="1"
									decimals="2"
									required
									toplabel
								></pui-number-field>
								<!-- :disabled="!aggsOperator" -->
							</v-col>
							<v-col cols="12" md="3">
								<pui-select
									attach="pmfrequencytimeunitid"
									:label="this.$t('pmrules.frequencytimeunit')"
									toplabel
									clearable
									v-model="model"
									modelName="vluppmtimeunit"
									:modelFormMapping="{ pmtimeunitid: 'pmfrequencytimeunitid' }"
									:itemsToSelect="timeunitItemsToSelect2"
									itemValue="pmtimeunitid"
									:itemText="(item) => `${item.name} (${item.acronym})`"
									required
								></pui-select>
								<!-- :disabled="!aggsOperator" -->
							</v-col>
							<!-- <v-col cols="12" md="6">
								<pui-text-field
									v-model="model.condition"
									:label="$t('pmrules.condition')"
									maxlength="150"
									toplabel
									tooltipDescription="pm10;gt;100"
								></pui-text-field>
							</v-col> -->
						</v-row>
						<v-row no-gutters>
							<v-col sm="12" md="3">
								<pui-select
									attach="pmseverityid"
									:label="this.$t('pmrules.severity')"
									toplabel
									clearable
									required
									v-model="model"
									modelName="vluppmseverity"
									:modelFormMapping="{ pmseverityid: 'pmseverityid' }"
									itemValue="pmseverityid"
									itemText="name"
									:itemsToSelect="severityToSelect"
								></pui-select>
							</v-col>
							<v-col sm="12" md="3" class="pt-4">
								<pui-checkbox :label="$t('pmrules.disabled')" v-model="model.disabled" true-value="1" false-value="0"></pui-checkbox>
							</v-col>
							<v-col v-if="showIsAverage" sm="12" md="4" class="pt-4">
								<pui-checkbox
									:label="$t('pmrules.isaverage')"
									v-model="model.isaverage"
									true-value="1"
									false-value="0"
								></pui-checkbox>
							</v-col>
						</v-row>
					</pui-field-set>
					<v-row no-gutters>
						<v-col cols="12">
							<pui-field-set :title="$t('pmrules.communication')">
								<v-row no-gutters>
									<v-col cols="12">
										<!-- Tabs Headers -->
										<v-tabs
											height="30"
											v-model="tabCommunication"
											color="blue"
											background-color="rgba(0, 0, 0, 0)"
											class="mt-3"
											show-arrows
										>
											<v-tab ref="email-tab" href="#email-tab">{{ $t('common.email') }}</v-tab>
											<v-tab ref="telegram-tab" href="#telegram-tab">{{ $t('common.telegram') }}</v-tab>
										</v-tabs>

										<!-- Tabs Content -->
										<v-tabs-items v-model="tabCommunication" style="overflow-y: auto; background: rgba(0, 0, 0, 0) !important">
											<v-tab-item value="email-tab" class="pa-2">
												<v-combobox
													v-model="modelCombo"
													:filter="filter"
													:hide-no-data="!search"
													:items="emailItems"
													:search-input.sync="search"
													hide-selected
													:label="$t('pmrules.destinations')"
													multiple
													small-chips
													outlined
													class="mt-6"
												>
													<template v-slot:no-data>
														<v-list-item>
															<span class="subheading pr-2">Add:</span>
															<v-chip :color="primaryColor" class="color-white" label> {{ search }} </v-chip>
															<span class="subheading pl-2">(Press 'Enter')</span>
														</v-list-item>
													</template>
													<template v-slot:selection="{ attrs, item, parent, selected }">
														<v-chip
															v-if="item === Object(item)"
															v-bind="attrs"
															:color="item.color"
															:input-value="selected"
															label
														>
															<span class="pr-2 color-white">
																{{ item.text }}
															</span>
															<v-icon small @click="parent.selectItem(item)" class="color-white"> $delete </v-icon>
														</v-chip>
													</template>
													<template v-slot:item="{ index, item }">
														<v-text-field
															v-if="editing === item"
															v-model="editing.text"
															autofocus
															flat
															background-color="transparent"
															hide-details
															solo
															@keyup.enter="edit(index, item)"
														></v-text-field>
														<v-chip v-else :color="item.color" dark label small>
															{{ item.text }}
														</v-chip>
														<v-spacer></v-spacer>
														<v-list-item-action @click.stop>
															<v-btn icon @click.stop.prevent="edit(index, item)">
																<v-icon size="16">{{
																	editing !== item ? 'fas fa-edit' : 'fak fa-line-check'
																}}</v-icon>
															</v-btn>
														</v-list-item-action>
													</template>
												</v-combobox>
											</v-tab-item>
											<v-tab-item value="telegram-tab">
												<v-row no-gutters>
													<v-col sm="12" md="8">
														<v-row no-gutters>
															<v-col>
																<pui-text-field
																	:label="$t('pmrules.botname')"
																	toplabel
																	disabled
																	v-model="botUsername"
																></pui-text-field>
															</v-col>
														</v-row>
														<v-row no-gutters>
															<v-col>
																<pui-text-field
																	:label="$t('pmrules.channelid')"
																	toplabel
																	:disabled="formDisabled"
																	v-model="model.channelid"
																></pui-text-field>
															</v-col>
														</v-row>
													</v-col>
													<v-col sm="12" md="4" class="d-flex justify-center align-center">
														<v-btn
															:color="primaryColor"
															large
															@click="telegramInfo = true"
															class="color-white fs-regular"
														>
															<v-icon size="16" class="pr-2">fak fa-line-help</v-icon
															>{{ $t('telegram.howToSetup') }}</v-btn
														>
													</v-col>
												</v-row>
											</v-tab-item>
										</v-tabs-items>
									</v-col>
								</v-row>
							</pui-field-set>
						</v-col>
					</v-row>
					<!-- UNCOMMENT FOR RULE-ACTIONS FUNCTIONALITY -->
					<!-- <v-row no-gutters>
						<v-col cols="12" class="pa-0">
							<pui-field-set :title="$t('pmactions.title')">
								<pui-multi-select
									id="multiSelectAction"
									itemValue="pmactionsid"
									itemText="actioncode"
									v-model="model.actions"
									:items="allPmActions"
									:itemsToSelect="model.actions"
									:disabled="actionsDisabled"
								></pui-multi-select>
							</pui-field-set>
						</v-col>
					</v-row> -->
				</pui-field-set>
			</div>
			<!-- footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="validateRule"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
		<!-- MODAL DIALOG TO SHOW WHILE AN ACTION IS IN PROGRESS -->
		<v-dialog v-model="saving" persistent max-width="35%">
			<v-card>
				<v-card-title class="fw-bold fs-xl justify-center">
					{{ savingMessage }}
				</v-card-title>
				<v-card-actions>
					<v-spacer></v-spacer>
					<v-container fluid fill-height>
						<div class="pb-2 d-flex justify-center align-center width-100">
							<v-progress-circular indeterminate :color="primaryColor"> </v-progress-circular>
						</div>
					</v-container>
				</v-card-actions>
			</v-card>
		</v-dialog>
		<v-dialog v-model="telegramInfo" persistent max-width="75%">
			<v-card>
				<v-card-title style="background-color: var(--azul-primary-100)" class="fs-lg fw-bold color-white">
					<v-icon size="18" class="pr-2 color-white">fak fa-line-help</v-icon> {{ $t('telegram.setup.title') }}
				</v-card-title>

				<v-card-text class="pa-0">
					<telegram-steps></telegram-steps>
				</v-card-text>

				<v-divider></v-divider>

				<v-card-actions>
					<v-spacer></v-spacer>
					<v-btn color="primary" text @click="telegramInfo = false"> {{ $t('common.close') }} </v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

// eslint-disable-next-line no-unused-vars
import { createRule, updateRule, validateQuery } from '@/api/databases_API';
//import constants from '@/utils/constants';
import { search, isSuperAdmin, obtainIndexName, setRules, formatRuleModel, formatRuleModelMulti, formatRuleModelQuery } from '@/api/common';
import { debounce } from 'lodash';
import RulesFormFilter from '../form/rules_form_filter/RulesFormFilter';
import VueJsonEditor from '@/components/ui/VueJsonEditor';

import TelegramSteps from './TelegramSteps';

export default {
	name: 'pmrulesform',
	mixins: [PuiFormMethodsMixin],
	components: {
		VueJsonEditor,
		RulesFormFilter,
		TelegramSteps
	},
	data() {
		return {
			modelName: 'pmrules',
			modelFilter: 'pmfiltercondition',
			devicesDisabled: true,
			actionsDisabled: true,
			/* pmdatasourceinstanceFixedFilter: {}, */
			pmdatasourcetypeFixedFilter: {},
			pmfilterconditionFixedFilter: {},
			elasticquery: {},
			allPmDatasourceinstances: [],
			//allPmActions: [],
			isSuperAdmin: false,
			elasticIndexDisabled: true,
			columns: [
				{
					name: this.$t('form.common.noParameterData'),
					type: 'common',
					title: this.$t('form.common.noParameterData')
				}
			],
			columnNames: [],
			columnsDisabled: true,
			maxFilters: 3,
			tab: null,
			tabCommunication: null,
			json: '{ query: { bool: { must: [{ range: { pm10: { gt: 0 } } }], should: [] } } }',
			options: {
				mode: 'code',
				enableSort: true,
				enableTransform: true
				/* onChangeText(json) {
					console.log('onChange query', json);
				} */
			},
			savingMessage: this.$t('pmrules.dialog.validatingFormData'),
			primaryColor: this.$store.state.global.primaryColor + 'D9',
			showIsAverage: false,
			disabledAcronym: false,
			botUsername: null,
			/* COMBO-BOX */
			editing: null,
			editingIndex: -1,
			emailItems: [{ header: 'Select or add email address' }],
			modelCombo: [],
			search: null,
			mustUpdateKibana: false,
			telegramInfo: false,
			aggsOperator: false
		};
	},
	computed: {
		datasourcetypeItemsToSelect() {
			return [{ pmdatasourcetypeid: this.model.pmdatasourcetypeid }];
		},
		/* datasourceinstanceItemsToSelect() {
			return [{ pmdatasourceinstanceid: this.model.pmdatasourceinstanceid }];
		}, */
		severityToSelect() {
			return [{ pmseverityid: this.model.pmseverityid }];
		},
		organizationItemsToSelect() {
			return [{ pmorganizationid: this.model.pmorganizationid }];
		},
		organizationName() {
			return this.userProperties.organizationname;
		},
		timeunitItemsToSelect() {
			return [{ pmtimeunitid: this.model.pmwindowtimeunitid }];
		},
		timeunitItemsToSelect2() {
			return [{ pmtimeunitid: this.model.pmfrequencytimeunitid }];
		},
		filterItemsToSelect() {
			return [{ pmfilterconditionid: this.model.pmfilterconditionid }];
		},
		hasDatasourcetype() {
			return !this.model.pmdatasourcetypeid;
		},
		hasOrganization() {
			return !this.model.pmorganizationid;
		},
		filterData: {
			get() {
				return this.$store.getters.getFilterData;
				//return this.model.pmfilterconditionid ? JSON.parse(this.getFilter()) : { groupOp: 'and', groups: [], rules: [] };
			},
			set(value) {
				this.$store.commit('setFilterData', value);
			}
		},

		computedModel() {
			return typeof this.model == 'object' ? this.model : {};
		}
	},
	watch: {
		'model.applytoall': {
			handler(val, prev) {
				console.log('watcher applytoall', prev, val, !!(val && prev));
				if (val == 1) {
					this.model.datasourceinstances = [];
					this.devicesDisabled = true;
				} else {
					this.devicesDisabled = false;
				}
				if (val && prev) this.mustUpdateKibana = true;
			}
		},
		'model.datasourceinstances'(val, prev) {
			if (val && prev && prev.length > 0) this.mustUpdateKibana = true;
			console.log('watcher datasourceinstances', prev, val, this.mustUpdateKibana);
		},
		'model.pmwindowtimeunitid'(val, prev) {
			if (val && prev) this.mustUpdateKibana = true;
			console.log('watcher pmwindowtimeunitid', prev, val, this.timeunitItemsToSelect);
		},
		'model.pmfrequencytimeunitid'(val, prev) {
			if (val && prev) this.mustUpdateKibana = true;
			console.log('watcher pmfrequencytimeunitid', prev, val, this.timeunitItemsToSelect2);
		},
		'model.windowrule'(val, prev) {
			if (val && prev) this.mustUpdateKibana = true;
			console.log('watcher windowrule', prev, val, this.mustUpdateKibana);
		},
		'model.frequency'(val, prev) {
			if (val && prev) this.mustUpdateKibana = true;
			console.log('watcher frequency', prev, val, this.mustUpdateKibana);
		},
		'model.isaverage'(val, prev) {
			if (val && prev) this.mustUpdateKibana = true;
			console.log('watcher isaverage', prev, val, this.mustUpdateKibana);
		},
		mustUpdateKibana(val, prev) {
			console.log('mustUpdateKibana watcher', prev, val, this.mustUpdateKibana);
		},
		'model.pmorganizationid': {
			handler(val, prev) {
				console.log('debug organization', prev, val, !!(val && prev));

				if (val && prev && val != prev) {
					this.model.pmdatasourcetypeid = null;
				}
				if (val) {
					this.pmdatasourcetypeFixedFilter = {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'pmorganizationid', op: 'eq', data: this.model.pmorganizationid }]
					};
				} else {
					this.pmdatasourcetypeFixedFilter = {
						groups: [],
						groupOp: 'and',
						rules: [{ field: 'pmorganizationid', op: 'eq', data: -1 }]
					};
				}
			}
		},
		'model.pmdatasourcetypeid': {
			handler(val, prev) {
				if (val) {
					if (!this.model.applytoall) this.devicesDisabled = false;
					this.actionsDisabled = false;
					if (prev) {
						this.model.datasourceinstances = [];
						this.model.actions = [];
					}

					this.fillDevices();
					//this.fillActions(); // UNCOMMENT FOR RULE-ACTIONS FUNCTIONALITY
					this.model.elasticindex = this.getElasticIndex();
					this.setColumns(this.$store.getters.getAllParameters, val);

					this.columnsDisabled = false;
				} else {
					this.devicesDisabled = this.actionsDisabled = true;
					this.allPmDatasourceinstances = [];
					this.allPmActions = [];
					this.model.elasticindex = null;
					this.columnsDisabled = true;
					this.columns = [
						{
							name: this.$t('form.common.noParameterData'),
							type: 'common',
							title: this.$t('form.common.noParameterData')
						}
					];
				}
				console.log('debug datasourcetype', prev, val, !!(val && prev), this.pmparameterFixedFilter);
			}
		},
		'model.acronym': {
			handler(val, prev) {
				if (val && (val.indexOf(' ') > -1 || val.indexOf('_') > -1)) {
					console.log('acronym watcher', prev, val);
					this.debouncedFormat();
				}
			}
		},
		'model.pmfilterconditionid': {
			handler(val, prev) {
				console.debug('info: model.pmfilterconditionid watcher', prev, val, !!this.model.filter);
				if (!prev && val) {
					let filterData = this.getFilter(val);
					if (filterData && filterData.isquery == 0) {
						this.filterData = JSON.parse(filterData.filter);
						this.tab = 'filter-tab';
					} else if (filterData && filterData.isquery == 1) {
						this.tab = 'query-tab';
						this.model.elasticquery = JSON.parse(filterData.filter);
					}
				}
			}
		},
		'model.pmrulesid': {
			handler(val, prev) {
				console.log('rule id watcher', prev, val);
				this.disabledAcronym = !!val;
				if (val) this.mustUpdateKibana = false;
			}
		},
		'model.elasticquery'(val, prev) {
			console.log('elasticquery watcher', prev, val);
		},
		modelCombo(val, prev) {
			if (val.length === prev.length) return;
			console.log('modelCombo watcher', prev, val);
			let validEmail = true;
			let invalidEmail = null;
			this.modelCombo = val.map((v) => {
				console.log('test v', v);
				if (typeof v === 'string') {
					validEmail = String(v)
						.toLowerCase()
						.match(
							/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
						);

					if (validEmail)
						this.emailItems.push({
							text: v.toLowerCase(),
							color: this.primaryColor
						});
					else invalidEmail = v;
					console.log('valid email', validEmail, invalidEmail);
					return {
						text: v.toLowerCase(),
						color: this.primaryColor
					};
				}

				return v;
			});
			if (!validEmail && invalidEmail) {
				console.log('debug 1', validEmail, invalidEmail);
				this.modelCombo.pop();
				this.$puiNotify.warning('"' + invalidEmail + '" is not a valid email address', this.$t('pmrules.invalidEmail'));
			}
		},
		'model.destinations'(val, prev) {
			console.log('destinations watcher', prev, val);
			if (val) {
				this.parseDestinations(this.model.destinations).forEach((item) => {
					this.modelCombo.push(item);
					this.emailItems.push(item);
				});
			}
		},
		telegramInfo(val) {
			if (val) window.removeEventListener('keydown', this.keydownPressed);
			else window.addEventListener('keydown', this.keydownPressed);
		},
		aggsOperator(val, prev) {
			console.log('aggs watcher', prev, val);
		}
	},
	beforeCreate() {
		if (this.model) {
			this.modelLoaded = true;
		}
	},
	created() {
		console.info('created pmrules', this.model, this.model.filter);
		this.debouncedFormat = debounce(this.formatAcronym, 1000);
		/* this.setColumns(this.$store.getters.getAllParameters); */
	},
	mounted() {
		this.isSuperAdmin = isSuperAdmin(this.session.profiles[0]);
		this.getBotName();
		if (!this.isSuperAdmin) {
			this.pmdatasourcetypeFixedFilter = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
			};
			this.pmfilterconditionFixedFilter = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid }]
			};
			if (this.session.email)
				this.emailItems.push({
					text: this.session.email,
					color: 'var(--gris-secondary)'
				});
		} else {
			this.pmfilterconditionFixedFilter = {
				groups: [],
				groupOp: 'and',
				rules: [{ field: 'pmorganizationid', op: 'eq', data: this.model.pmorganizationid }]
			};
		}

		console.log('debug mounted pmrules', this.model.filter, this.model.pmfilterconditionid, this.$store.getters.getAllParameters);
	},
	updated() {
		//console.log('debug updated pmrules', this.model, this.model.filter, this.model.pmfilterconditionid);
		if (this.model) {
			//if (!this.model.elasticquery) this.model.elasticquery = { query: { bool: { must: [{ range: { pm10: { gt: 75 } } }], should: [] } } };
			if (!this.modelLoaded || !this.model.pmorganizationid) {
				this.modelLoaded = true;
			}
			if (!this.model.pmorganizationid && !this.isSuperAdmin) {
				this.model.pmorganizationid = this.userProperties.organizationid;
			}
			this.model.model = this.modelName;
		}
	},
	methods: {
		async fillDevices() {
			//MultiSelect: Datasourceinstances
			let filterUser = {};
			if (!this.isSuperAdmin) {
				filterUser = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
						{ field: 'pmdatasourcetypeid', op: 'eq', data: this.model.pmdatasourcetypeid }
					]
				};
			} else {
				filterUser = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.model.pmorganizationid },
						{ field: 'pmdatasourcetypeid', op: 'eq', data: this.model.pmdatasourcetypeid }
					]
				};
			}
			let body = {
				model: 'pmdatasourceinstance',
				rows: 999,
				filter: filterUser
			};

			let { data } = await search(body);
			//console.log('After get data - Devices', data.data);
			this.allPmDatasourceinstances = data.data;
		},
		async fillActions() {
			//MultiSelect: Actions
			let filterUser = {};
			if (!this.isSuperAdmin) {
				filterUser = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.userProperties.organizationid },
						{ field: 'pmdatasourcetypeid', op: 'eq', data: this.model.pmdatasourcetypeid }
					]
				};
			} else {
				filterUser = {
					groups: [],
					groupOp: 'and',
					rules: [
						{ field: 'pmorganizationid', op: 'eq', data: this.model.pmorganizationid },
						{ field: 'pmdatasourcetypeid', op: 'eq', data: this.model.pmdatasourcetypeid }
					]
				};
			}
			let body = {
				model: 'pmactions',
				rows: 999,
				filter: filterUser
			};

			let { data } = await search(body);
			//console.log('After get data - Devices', data.data);
			this.allPmActions = data.data;
		},
		validateRule() {
			console.log('validateRule: ', this.tab, this.mustUpdateKibana, this.model.pmrulesid);
			if (this.$refs.form && this.$refs.form.validate && !this.$refs.form.validate()) {
				this.formValidationErrors = true;
				this.$puiNotify.error(this.$t('pui9.error.form_errors'));
				this.resetAfterSaveActions();
				return;
			}
			if (this.modelCombo.length > 0) {
				this.model.destinations = this.formatDestinations(this.modelCombo);
			}
			if ((this.mustUpdateKibana && this.model.pmrulesid) || !this.model.pmrulesid) {
				console.log('validateRule in: ', this.tab, this.mustUpdateKibana, this.model.pmrulesid);

				this.saving = true;
				switch (this.tab) {
					case 'filter-tab':
						!this.model.pmfilterconditionid ? this.insertFilter(this.model.filter, 0) : this.updateFilter(this.model.filter, 0);

						if (this.model.applytoall == 0) {
							if (
								this.model.datasourceinstances.length == 0 ||
								(this.model.datasourceinstances.length == 1 && this.model.datasourceinstances[0] == '')
							) {
								this.model.applytoall = 1;
							}
						}
						this.savingMessage = this.$t('pmrules.dialog.validatingFormData');
						if (JSON.parse(this.model.filter).rules.length > 1) {
							this.model = formatRuleModelMulti(this.model, this.$store.getters.getTimeunits);
						} else {
							this.model = formatRuleModel(this.model, this.$store.getters.getTimeunits);
						}
						this.ruleActions();

						break;
					case 'query-tab':
						this.savingMessage = this.$t('pmrules.dialog.validatingElasticQuery');
						validateQuery(this.model.elasticindex, this.model.elasticquery).then((result) => {
							console.log('validateRule then: ', result);
							if (result.status == 'OK' && result.message.valid) {
								!this.model.pmfilterconditionid
									? this.insertFilter(JSON.stringify(this.model.elasticquery), 1)
									: this.updateFilter(JSON.stringify(this.model.elasticquery), 1);

								this.model.applytoall = 1;
								this.model.isQuery = true;
								this.model = formatRuleModelQuery(this.model, this.$store.getters.getTimeunits);
								this.savingMessage = this.$t('pmrules.dialog.validatingFormData');
								this.ruleActions();
							} else {
								console.error('ERROR: query json', this.model.elasticquery);
								this.$puiNotify.error(this.$t('common.elasticQueryNotValid'), this.$t('common.error'));
								this.saving = false;
								//this.save(true);
							}
						});

						break;
					default:
						console.error('ERROR: tab', this.tab);
						break;
				}
				console.debug('debug: this model if', this.model, { id: this.model.ruleid, model: this.model });
			} else {
				console.debug('debug: this model else', this.model, { id: this.model.ruleid, model: this.model });
				this.save();
			}
		},
		getElasticIndex() {
			let deviceType = this.$store.getters.getDatasourcetype.find(
				(deviceType) => deviceType.pmdatasourcetypeid == this.model.pmdatasourcetypeid
			);
			console.log('deviceTypes', deviceType, obtainIndexName(deviceType));
			if (deviceType) return obtainIndexName(deviceType);

			return null;
		},

		formatAcronym: function () {
			this.model.acronym = this.model.acronym.trim().replace(/\s+/g, '_').replace(/_+/g, '_');
		},
		setColumns(filteredParameters = null, datasourcetypeId = null) {
			if (filteredParameters && datasourcetypeId) {
				filteredParameters = this.$store.getters.getAllParameters.filter(
					(parameter) => parameter.pmorganizationid == this.model.pmorganizationid && parameter.pmdatasourcetypeid == datasourcetypeId
				);
				console.log('Parameters data', this.$store.getters.getAllParameters, filteredParameters);

				this.formatColumns(filteredParameters);
			}
		},

		checkFilter(filter) {
			this.model.filter = JSON.stringify(filter);
			console.debug('info: filterData watcher', filter, filter.rules[0].op.split('_'));

			if (filter.rules[0].op.split('_').length > 1) this.aggsOperator = true;
			else {
				this.aggsOperator = false;
				let elasticAcronym = filter.rules[0].field;
				let parameter = this.$store.getters.getAllParameters.find((param) => param.elasticacronym == elasticAcronym);
				console.log('debug param 1', this.$store.getters.getAllParameters, parameter);
				if (parameter) {
					console.log('debug param 2', parameter, parameter.deliveryperiod, parameter.pmtimeunitid);
					this.model.windowrule = this.model.frequency = parameter.deliveryperiod;
					this.model.pmwindowtimeunitid = this.model.pmfrequencytimeunitid = parameter.pmtimeunitid;
				}
			}
		},
		getFilter(filterId) {
			let formFilter = this.$store.getters.getFormFilters.find((filter) => filter.pmfilterconditionid == filterId);
			console.log('debug getfilter', filterId, formFilter);
			if (formFilter) return formFilter;
			return false;
		},
		formatColumns(filteredParameters) {
			this.columns = [];
			for (const index in filteredParameters) {
				const column = filteredParameters[index];
				/* let title = this.$puiI18n.t(column.title);
				title = title !== column.title ? title : column.name; */
				let columnType;

				switch (column.unittype.toLowerCase()) {
					case 'integer':
						columnType = 'numeric';
						break;
					case 'float':
						columnType = 'decimal';
						break;
					case 'string':
						columnType = 'text';
						break;
					default:
						columnType = 'common';
						break;
				}
				this.columns.push({
					name: column.elasticacronym,
					type: columnType,
					title: column.name + ' (' + column.symbol + ')'
				});
			}
			if (this.columns.length > 0) {
				this.columns.sort((a, b) => (a.title < b.title ? -1 : a.title > b.title ? 1 : 0));

				for (const index in this.columns) {
					this.columnNames.push(this.columns[index]);
				}
			} else {
				this.columns.push({
					name: this.$t('form.common.noParameterData'),
					type: 'numeric',
					title: this.$t('form.common.noParameterData')
				});
			}
			console.log('Filter Columns', this.columns);
		},
		async insertFilter(ruleFilter, isQuery, shouldStayInPage = true) {
			this.savingMessage = this.$t('pmrules.dialog.savingFilter');
			let url = this.$store.getters.getModelByName('pmfiltercondition').url.insert;
			let filterModel = {
				pmorganizationid: this.model.pmorganizationid,
				model: this.modelName,
				name: this.model.acronym,
				description: this.model.description,
				filter: ruleFilter,
				disabled: 0,
				parameter: isQuery ? '' : JSON.parse(this.model.filter).rules[0].field,
				value: isQuery ? 0 : JSON.parse(this.model.filter).rules[0].data,
				operator: isQuery ? '' : JSON.parse(this.model.filter).rules[0].op,
				isquery: isQuery
			};
			this.$puiRequests.postRequest(
				url,
				filterModel,
				(response) => {
					//this.saving = false;
					//this.$store.$puiEvents.$emit(`onPui-insertRow-dataTable-${this.modelDependency.name}`, response.data);
					//this.$store.$puiNotify.success(this.$t('pui9.save.success'));
					//this.afterSave && this.afterSave(response.data);
					console.debug('filter insert response', response.data);
					if (response.data.pmfilterconditionid) {
						this.model.pmfilterconditionid = response.data.pmfilterconditionid;
					}
					if (!(shouldStayInPage === true)) {
						if (this.internal.afterInsertGoToUpdate) {
							this.goToUpdate(response.data);
						} else if (this.internal.afterInsertGoToCreate) {
							// Router not allow to go same router with same parameters
							this.refreshRouter();
						} else {
							this.back();
						}
						this.resetAfterSaveActions();
					}
				},
				(error) => {
					this.resetAfterSaveActions();
					this.saving = false;
					this.onSaveError && this.onSaveError();
					this.onError(error);
				}
			);
		},
		async updateFilter(ruleFilter, isQuery, shouldStayInPage = true) {
			this.savingMessage = this.$t('pmrules.dialog.updatingFilter');
			let url = this.$store.getters.getModelByName('pmfiltercondition').url.update;
			let filterModel = {
				pmfilterconditionid: this.model.pmfilterconditionid,
				pmorganizationid: this.model.pmorganizationid,
				model: this.modelName,
				name: this.model.acronym,
				description: this.model.description,
				filter: ruleFilter,
				disabled: 0,
				parameter: isQuery ? '' : JSON.parse(this.model.filter).rules[0].field,
				value: isQuery ? 0 : JSON.parse(this.model.filter).rules[0].data,
				operator: isQuery ? '' : JSON.parse(this.model.filter).rules[0].op,
				isquery: isQuery
			};

			this.$puiRequests.putRequest(
				url,
				filterModel,
				(response) => {
					//this.saving = false;
					//this.$store.$puiEvents.$emit(`onPui-insertRow-dataTable-${this.modelDependency.name}`, response.data);
					//this.$store.$puiNotify.success(this.$t('pui9.save.success'));
					//this.afterSave && this.afterSave(response.data);
					console.debug('filter update response', response.data);
					if (!(shouldStayInPage === true)) {
						if (this.internal.afterInsertGoToUpdate) {
							this.goToUpdate(response.data);
						} else if (this.internal.afterInsertGoToCreate) {
							// Router not allow to go same router with same parameters
							this.refreshRouter();
						} else {
							this.back();
						}
						this.resetAfterSaveActions();
					}
				},
				(error) => {
					this.resetAfterSaveActions();
					this.saving = false;
					this.onSaveError && this.onSaveError();
					this.onError(error);
				}
			);
		},
		onErrorQuery() {
			console.log('error query');
		},
		ruleActions() {
			console.log('=== rule actions ===', this.model.valid);
			if (this.model.valid) {
				if (this.model.pmrulesid && this.model.ruleid) {
					this.savingMessage = this.$t('pmrules.dialog.updatingRule');
					updateRule({ id: this.model.ruleid, model: this.model })
						.then((solved) => {
							this.$puiNotify.info(this.$t('common.ruleUpdated'), solved);
							console.info('info: solved updating rule', solved);
							if (solved.message) {
								this.model.ruleid = solved.message.id;
							}
							this.save();
							this.saving = false;
						})
						.catch((error) => {
							this.$puiNotify.error(this.$t('common.error'), error);
							console.info('info: error updating rule', error);
							this.saving = false;
						});
					//this.save();
				} else {
					this.savingMessage = this.$t('pmrules.dialog.creatingRule');
					createRule(this.model)
						.then((solved) => {
							this.$puiNotify.info(this.$t('common.status') + ': ' + solved.status, this.$t('common.ruleCreated'));
							console.info('info 3: solved rule', solved);
							if (solved.message) {
								this.model.ruleid = solved.message.id;
							}
							this.save();
							this.saving = false;
						})
						.catch((error) => {
							this.$puiNotify.error(error.response.data.message, this.$t('common.error'));
							console.info('info 4: error rule', error, error.response, error.response.message);
							this.saving = false;
						});
				}
				setRules(this.$store);
			} else {
				this.$puiNotify.warning('Please try again later', 'Validation error');
				this.saving = false;
				//this.save();
			}
		},
		edit(index, item) {
			if (!this.editing) {
				this.editing = item;
				this.editingIndex = index;
			} else {
				this.editing = null;
				this.editingIndex = -1;
			}
		},
		filter(item, queryText, itemText) {
			if (item.header) return false;

			const hasValue = (val) => (val ? val : '');

			const text = hasValue(itemText);
			const query = hasValue(queryText);

			return text.toString().toLowerCase().indexOf(query.toString().toLowerCase()) > -1;
		},
		formatDestinations(destinationsArray) {
			return destinationsArray
				.map((item) => {
					console.log('dest deb 1', item);
					return item.text;
				})
				.join(',');
		},
		parseDestinations(destinationsString) {
			return destinationsString.split(',').map((item) => {
				console.log('dest deb', item);
				return {
					text: item,
					color: this.primaryColor
				};
			});
		},
		async getBotName() {
			let body = {
				model: 'pmtelegramconfig',
				rows: 1,
				filter: null
			};
			let { data } = await search(body);
			this.botUsername = data.data[0].botname;
		}
	}
};
</script>
<style lang="postcss">
.v-radio {
	.v-input--selection-controls__input {
		margin: 0px !important;
		top: 4px;

		& .v-icon {
			font-size: 16px !important;
		}
	}
}
</style>

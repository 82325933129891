<template>
	<v-progress-linear v-model="progress" class="mapupdatecontrol"></v-progress-linear>
</template>

<script>
export default {
	name: 'mapupdatecontrol',

	data() {
		return {
			progress: 0,
			refreshSession: 0,
			interval: null
		};
	},

	methods: {
		keepSession() {
			this.$puiRequests.getRequest('/login/keepSessionActive');
		},

		startProgressBar() {
			// start next interval
			//if (this.delta !== 0) {
			this.interval = setInterval(this.tick, 1000);
			//}
		},

		tick() {
			this.progress += this.delta;
			if (this.progress <= 100) {
				return;
			}

			this.progress = 0;
			this.refreshSession += 1;

			this.$puiEvents.$emit('map:updated');
			this.$store.commit('resetLoadingProcess');

			if (this.refreshSession % 5 !== 0) {
				return;
			}

			this.refreshSession = 0;
			this.keepSession();
		},

		getPageVisibilityParams() {
			if (typeof document.hidden !== 'undefined') {
				// Opera 12.10 and Firefox 18 and later support
				return {
					hidden: 'hidden',
					visibilityChange: 'visibilitychange'
				};
			} else if (typeof document.msHidden !== 'undefined') {
				return {
					hidden: 'msHidden',
					visibilityChange: 'msvisibilitychange'
				};
			} else if (typeof document.webkitHidden !== 'undefined') {
				return {
					hidden: 'webkitHidden',
					visibilityChange: 'webkitvisibilitychange'
				};
			}
		},

		handleVisibilityChange() {
			//console.log('debug handleVisibilityChange', this.progress, this.refreshSession, this.interval, this.getPageVisibilityParams());
			if (this.interval) {
				const { hidden } = this.getPageVisibilityParams();
				//console.log('debug hidden', document, document[hidden]);
				if (document[hidden]) {
					return clearInterval(this.interval);
				}

				this.startProgressBar();
			}
		}
	},
	computed: {
		refreshTime() {
			return this.$store.getters.refreshTime;
		},

		delta() {
			return this.refreshTime ? 100 / this.refreshTime : 5;
		},

		zoom() {
			return this.$store.getters.zoom;
		},

		oldZoom() {
			return this.$store.getters.oldZoom;
		}
	},
	created() {
		this.startProgressBar();

		const { visibilityChange } = this.getPageVisibilityParams();

		document.addEventListener(visibilityChange, this.handleVisibilityChange);
	},
	destroyed() {
		//console.log('debug destroyed map update', this.progress, this.refreshSession, this.interval);
		if (this.interval) {
			clearInterval(this.interval);
		}

		const { visibilityChange } = this.getPageVisibilityParams();

		document.removeEventListener(visibilityChange, this.handleVisibilityChange);
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';

.mapupdatecontrol {
	width: 100%;
	height: 3px !important;
	margin: 0px !important;
	position: absolute;
	top: 0px;
	z-index: 11;
	cursor: pointer;

	& >>> .v-progress-linear__bar {
		background-color: rgba(255, 255, 255, 0);
	}
}
</style>

<template>
	<v-list-group no-action :ripple="false" append-icon="" @click="toggleRotated" :value="true">
		<template #prependIcon>
			<v-icon size="14" :class="{ rotated: rotated }" class="color-high">
				far
				{{ rotated ? 'fa-minus' : 'fa-plus' }}
			</v-icon>
		</template>
		<template #activator>
			<div class="d-flex flex-wrap width-100 ml-n8">
				<v-list-item-title class="color-high fw-bold fs-regular d-flex justify-start">
					<span>{{ item[nameField] }}</span>
				</v-list-item-title>
			</div>
		</template>
		<v-list-item v-for="(device, i) in item.group" :key="i" class="sub-kpi fw-500">
			<v-list-item-content>
				<v-list-item-title
					class="d-flex justify-start"
					@click="filterDeviceChart(device)"
					:style="{ cursor: !isByArea ? 'pointer' : 'default' }"
				>
					<v-tooltip right max-width="350" transition="scroll-x-transition" color="var(--gris-secondary)">
						<template v-slot:activator="{ on, attrs }">
							<div v-bind="attrs" v-on="on" style="cursor: pointer">
								<span class="color-high">{{ device.code }}</span>
								<v-icon v-if="teoricDeliveryDevices[device.pmdatasourceinstanceid] !== -1" size="12" class="fw-500 pl-2">
									fak fa-line-info-2
								</v-icon>
								<i v-else class="fas fa-do-not-enter pl-2" style="color: var(--gris-300)"></i>
								<!-- <i class="fas fa-do-not-enter pl-2" style="color: var(--gris-300)"></i> -->
							</div>
						</template>
						<span class="fw-500 fs-regular text-center">
							{{ $t('pmdatasourceinstance.deliveryperiod') }}:
							{{
								teoricDeliveryDevices[device.pmdatasourceinstanceid] !== -1
									? teoricDeliveryDevices[device.pmdatasourceinstanceid]
									: $t('common.notDefined')
							}}
						</span>
					</v-tooltip>
				</v-list-item-title>
				<v-list-item-subtitle
					><kpi-bar
						v-if="sectionsDevices[device.pmdatasourceinstanceid]"
						:sections="sectionsDevices[device.pmdatasourceinstanceid]"
						barHeight="6px"
					></kpi-bar
				></v-list-item-subtitle>
			</v-list-item-content>
		</v-list-item>
	</v-list-group>
</template>

<script>
import KpiBar from './KpiBar.vue';

export default {
	components: {
		KpiBar
	},
	props: {
		item: {
			type: Object,
			required: true
		},
		idField: {
			type: String,
			required: true
		},
		nameField: {
			type: String,
			required: true
		},
		teoricDeliveryDevices: {
			type: Object,
			required: true
		},
		sectionsDevices: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			rotated: true,
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},
	computed: {
		isByArea() {
			return this.idField == 'pmareaid';
		},
		slaChartFilter() {
			return this.$store.getters.getSlaChartFilter;
		}
	},
	watch: {
		rotated(val, prev) {
			console.log('watcher rotated', prev, val);
		}
	},
	mounted() {
		console.log('mounted frequency panel group', this.teoricDeliveryDevices, this.sectionsDevices);
	},
	methods: {
		toggleRotated() {
			this.rotated = !this.rotated;
		},
		filterDeviceChart(device) {
			console.log('filter device chart', device, this.isByArea);
			if (!this.isByArea) {
				if (this.slaChartFilter && this.slaChartFilter.pmdatasourceinstanceid == device.pmdatasourceinstanceid) {
					device = null;
				}
				this.$store.commit('setSlaChartFilter', device);
			}
		}
	}
};
</script>

<style lang="postcss">
.fak.fa-solid-anomalies {
	color: var(--gris-300) !important;
}
</style>

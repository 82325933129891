import { render, staticRenderFns } from "./PmSeverityForm.vue?vue&type=template&id=700d848b&scoped=true"
import script from "./PmSeverityForm.vue?vue&type=script&lang=js"
export * from "./PmSeverityForm.vue?vue&type=script&lang=js"
import style0 from "./PmSeverityForm.vue?vue&type=style&index=0&id=700d848b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "700d848b",
  null
  
)

export default component.exports
<template>
	<v-tooltip right color="var(--gris-secondary)">
		<template v-slot:activator="{ on, attrs }">
			<div class="tab" @click="openPanel" :class="{ tabactive: isActive }" :title="options.data.station_code" v-bind="attrs" v-on="on">
				<div class="tab tabnotifications d-flex justify-center align-center">
					<!-- TODO: Change icon dynamic-->
					<!-- <svg-icon :icon="icon" :width="22" /> -->
					<v-icon>fak fa-solid-notification</v-icon>
				</div>
			</div>
		</template>
		<span class="fs-regular">{{ options.data['station_code'].replace(/\s/g, '') + ': ' + options.data['rule_name'] }}</span>
	</v-tooltip>
</template>

<script>
//import SvgIcon from '@/components/map/icon/SvgIcon';
//import { NOTIFICATIONS_LAYER } from '@/lib/constants/layers';
import { getIconsTypeIcon } from '@/api/common';
import constants from '@/utils/constants';

export default {
	name: 'tabimage',

	components: {
		//SvgIcon
	},

	props: {
		options: {
			type: Object,
			required: true
		}
	},

	data() {
		return {
			primaryColor: this.$store.state.global.primaryColor + 'd9'
		};
	},

	computed: {
		icon() {
			return this.isActive ? this.tabNotificationSelected : this.tabNotification;
		},

		isActive() {
			return this.$store.getters.activePanel === this.options.panelId;
		},
		tabNotification() {
			return getIconsTypeIcon(constants.NOTIFICATIONS_ICONS_TYPE, constants.NOTIFICATIONS_TAB_ACRONYM, this.$store.getters.getIcons).route;
		},
		tabNotificationSelected() {
			return getIconsTypeIcon(constants.NOTIFICATIONS_ICONS_TYPE, constants.NOTIFICATIONS_SELECTED_ACRONYM, this.$store.getters.getIcons).route;
		}
	},
	mounted() {
		console.log('debug options', this.options);
		console.warn(this.options.data);
		console.warn(this.options.data.station_code);
	},

	methods: {
		openPanel() {
			this.$store.commit('addDynamicPanel', this.options);
			this.$store.commit('setActivePanel', this.options);
		}
	}
};
</script>

<style lang="postcss" scoped>
@import '../../../../../styles/eiffel-variables.pcss';
& .tab {
	width: 40px;
	height: 40px;
	background-color: var(--fondo-1);
	border-top-right-radius: 4px;
	border-bottom-right-radius: 4px;
	border-bottom: 1px solid #bbb;
	color: var(--primary);
	margin-top: 1px;
	margin-bottom: 1px;

	&.tabactive {
		background-color: var(--fondo-1);
		i {
			color: var(--azul-primary-100);
		}
	}
	&:hover {
		cursor: pointer;
	}

	& .v-icon {
		font-size: 1.25rem !important;
		font-weight: 500 !important;
	}
}
</style>
